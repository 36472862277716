const RectangularCheckBox = (props: any) => {
  const { label, checked, onChange, onClick, disabled } = props;
  return (
    <>
      <label className="rectangular-checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="checkbox-input"
          onClick={onClick}
        />
        <span className="checkbox-label">{label}</span>
      </label>
    </>
  );
};

export default RectangularCheckBox;
