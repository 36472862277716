import { createSlice} from "@reduxjs/toolkit";

const initialState: any = {
  ProgramName: "",
  programId:"",
};

const modulesSlice = createSlice({
  name: "moduleSliceReducer",
  initialState,
  reducers: {
    storeProgramName: (state, action) => {
      state.ProgramName = action.payload
    },
    storeProgramId: (state, {payload}) => {
      state.programId = payload
    },
  },
});

export const { storeProgramName,storeProgramId} = modulesSlice.actions;
export default modulesSlice.reducer;
