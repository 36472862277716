import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Buttons from "../../../components/reusables/Buttons";
import {
  useAddProgramsToAllProgramsMutation,
  useGetSuggestedProgramDetailQuery,
} from "../../../redux/api/api";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";
import TagLevel from "../../../components/reusables/TagLevel";
import SkeletonTable from "../../../components/reusables/SkeletonTable";
import { Fragment } from "react";

const SuggestedProgramDrawer = (props: any) => {
  const {
    openProgramDrawer,
    setOpenProgramDrawer,
    programId,
    setSubmitting,
    submitting,
  } = props;
  const [addProgramToAllProgram] = useAddProgramsToAllProgramsMutation();
  const dispatch = useDispatch();

  const reqObject = {
    url: `program-details/${programId}`,
  };
  const { data, isFetching }: any = useGetSuggestedProgramDetailQuery(
    reqObject,
    { skip: !openProgramDrawer }
  );

  const handleCloseIcon = () => {
    setOpenProgramDrawer(false);
  };

  const suggestedProgramDetailReq = async (values: any) => {
    try {
      const requiredObj: any = {
        url: "add-program-to-my-program",
        body: {
          program_id: programId,
        },
      };
      setSubmitting(true);
      const resp: any = await addProgramToAllProgram(requiredObj).unwrap();
      if (resp.code === 3077) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setOpenProgramDrawer(false);
        setSubmitting(false);
      }
      if (resp.code === 3076) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "warning",
          })
        );
        setOpenProgramDrawer(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={openProgramDrawer}
      onClose={handleCloseIcon}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      transitionDuration={400}
    >
      <Box
        sx={{
          width: "31.3rem",
        }}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="end"
          alignItems="baseline"
          sx={{
            backgroundColor: "#000",
            padding: "5.5rem 1.5rem 0rem 1.5rem",
          }}
        >
          <CloseIcon
            fontSize="medium"
            sx={{
              cursor: "pointer",
              color: "var(--InputTextGray)",
              fontWeight: "var(--font300)",
            }}
            onClick={handleCloseIcon}
          />
        </Box>
        {isFetching ? (
          <SkeletonTable
            sx={{ width: "100%", height: "20rem", padding: "1rem 1rem" }}
          />
        ) : (
          <Box
            sx={{
              backgroundColor: "#000",
              padding: "1.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "0.5rem",
              }}
            >
              <TagLevel variant={data?.data?.difficulty} />
            </Box>
            <Typography
              sx={{
                fontFamily: `var(--fontMulish)`,
                fontSize: "18px",
                fontWeight: "var(--font800)",
                color: "#fff",
                marginBottom: "0.5rem",
                textTransform: "capitalize",
                wordWrap: "break-word"
              }}
            >
              {data?.data?.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "var(--fontFamiljen)",
                fontWeight: "var(--font400)",
                fontSize: "12px",
                color: "#fff",
                marginBottom: "12px",
                wordWrap: "break-word"
              }}
            >
              {data?.data?.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                color: "#fff",
                gap: "1rem",
                fontFamily: "var(--fontFamiljen)",
                fontWeight: "var(--font400)",
              }}
            >
              <Typography sx={{ fontSize: "12px" }}>
                {data?.data?.total_modules} Modules
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {data?.data?.duration}h Duration
              </Typography>
            </Box>
          </Box>
        )}

        {isFetching ? (
          <Box component="div" sx={{ padding: "1rem" }}>
            {Array.from({ length: 6 }).map((_, index) => {
              const uniqueKey = `skeleton_${index}`;
              return (
                <Fragment key={uniqueKey}>
                  <SkeletonTable
                    sx={{
                      width: "100%",
                      height: "3rem",
                      padding: "0rem",
                      borderRadius: "1rem",
                      marginBottom: "0.5rem",
                    }}
                  />
                </Fragment>
              );
            })}
          </Box>
        ) : (
          <>
            <Box
              sx={{
                padding: "1.5rem",
                backgroundColor: "#F5F4F4",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "var(--fontFamiljen)",
                  fontWeight: "var(--font500)",
                  fontSize:'18px',
                  wordWrap: "break-word"
                }}
              >
                This Program Includes:
              </Typography>
            </Box>
            <Box sx={{ padding: "1.5rem" }}>
              {data?.data?.modules.map((items: any) => {
                return (
                  <Accordion
                    sx={{ boxShadow: "none",wordWrap: "break-word" }}
                    square={true}
                    key={items.id}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ padding: "0px", borderBottom: "1px solid #ccc",wordWrap: "break-word" }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "var(--fontFamiljen)",
                          fontWeight: "var(--font500)",
                          fontSize:'14px',
                          wordWrap: "break-word"
                        }}
                      >
                        {items.name.length > 50
                        ? items.name.slice(0, 50) + "..."
                        : items.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontFamily: "var(--fontFamiljen)",
                          fontWeight: "var(--font500)",
                          fontSize:'14px',
                          wordWrap: "break-word"
                        }}
                      >
                        {items.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </>
        )}

        <Box sx={{ padding: "1.5rem" }}>
          <Buttons
            disabled={submitting ? true : false}
            name="Add to program list"
            size="large"
            color="primary"
            variant="contained"
            fullWidth={true}
            sx={{
              padding: "0.9rem",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            className="BtnHover"
            onClick={suggestedProgramDetailReq}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SuggestedProgramDrawer;
