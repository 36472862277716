import { createSlice, Slice } from '@reduxjs/toolkit'

import { STATUS, TASK_STATUS } from '../../constants'

const initialState = {
  learners: {
    list: [],
    loader: false,
  },
  programs: {
    miniList: [],
    list: [],
    loader: false,
    currentProgram: null,
    progress: {
      data: {},
      loader: false,
    },
  },
  modules: {
    list: [],
    loader: false,
    currentModule: null,
  },
  tasks: {
    list: [],
    updates: [],
    loader: false,
    currentTask: null,
  },
  editor: {
    code: null,
    output: null,
  },
  snackbar: {
    open: false,
    message: null,
    type: null,
    taskType: null,
  },
  student: null,
}

const LMS: Slice = createSlice({
  name: 'webLMS',
  initialState,
  reducers: {
    toggleLearnerList: state => {
      state.learners.loader = !state.learners.loader
    },
    toggleModuleLoader: state => {
      state.modules.loader = !state.modules.loader
    },
    toggleProgramProgressLoader: state => {
      state.programs.progress.loader = !state.programs.progress.loader
    },
    storeLearnerList: (state, action) => {
      state.learners.list = action.payload
      state.learners.loader = false
    },
    toggleProgramList: state => {
      state.programs.loader = !state.programs.loader
    },
    updateCurrentProgam: (state, { payload }: any) => {
      state.programs.currentProgram = payload
    },
    toggleTaskLoader: state => {
      state.tasks.loader = !state.tasks.loader
    },
    updateTask: (state, { payload }: any) => {
      state.tasks.currentTask = payload
      let tasks: any = state?.modules?.currentModule?.task?.map((t: any) =>
        t?.id === payload?.id
          ? {
              ...t,
              status:
                payload?.status === TASK_STATUS.STARTED ||
                payload?.status === TASK_STATUS.DECLINED ||
                payload?.status === TASK_STATUS.IN_REVIEW ||
                payload?.status === TASK_STATUS.PENDING_ASSESSMENT
                  ? STATUS.IN_PROGRESS
                  : STATUS.COMPLETED,
            }
          : t,
      )

      if (payload?.status === TASK_STATUS.COMPLETED) {
        const currentIndex = state?.tasks?.list?.findIndex((item: any) => item?.id === payload?.id)

        if (currentIndex < state?.tasks?.list?.length - 1 && state?.tasks?.list?.[currentIndex + 1]?.id) {
          state.programs.currentProgram.current_task = state?.tasks?.list?.[currentIndex + 1]?.id ?? null
          state.tasks.currentTask = state?.tasks?.list?.[currentIndex + 1] ?? null

          tasks = tasks?.map((t: any, idx: number) =>
            idx === currentIndex + 1 && payload?.status === TASK_STATUS.COMPLETED
              ? { ...t, current_task: true }
              : { ...t, current_task: false },
          )
        }
      }

      state.modules.currentModule.task = tasks
      state.tasks.list = tasks
    },
    updateCurrentTask: (state, { payload }: any) => {
      if (state?.tasks?.currentTask?.id !== payload?.id) {
        state.tasks.currentTask = payload
      }
    },
    updateEditor: (state, { payload }: any) => {
      state.editor = payload
    },
    updateSnackbar: (state, { payload }: any) => {
      state.snackbar = payload
    },
    updateStudent: (state, { payload }: any) => {
      state.student = payload
    },
    resetLMS: state => {
      state.editor = initialState.editor
      state.learners = initialState.learners
      state.modules = initialState.modules
      state.programs = initialState.programs
      state.snackbar = initialState.snackbar
      state.student = initialState.student
      state.tasks = initialState.tasks
    },
    resetModuleDetails: state => {
      state.editor = initialState.editor
      state.modules = initialState.modules
      state.programs.currentProgram = initialState.programs.currentProgram
      state.snackbar = initialState.snackbar
      state.tasks = initialState.tasks
    },
    resetProgramForLearner: state => {
      state.editor = initialState.editor
      state.modules = initialState.modules
      state.snackbar = initialState.snackbar
      state.tasks = initialState.tasks
    },
    updateCurrentModule: (state, { payload }: any) => {
      state.modules.currentModule = payload
      let tasks = payload?.task
      const currentTask = tasks?.find((t: any) => t.current_task)

      if (!currentTask) {
        tasks = tasks?.map((t: any, idx: number) => (tasks?.length - 1 === idx ? { ...t, current_task: true } : t))
      }

      const taskDetail = currentTask

      state.tasks.list = tasks
      // @ts-ignore
      state.programs.currentProgram.current_task = taskDetail?.id ?? null
      state.tasks.currentTask =
        // @ts-ignore
        state?.tasks?.currentTask?.id === taskDetail?.id
          ? { ...taskDetail, ...(state?.tasks?.currentTask || {}) }
          : taskDetail ?? null
    },
  },
  extraReducers: builder => {
    builder
      .addCase('webLMS.services/executeMutation/fulfilled', (state, { payload, meta }: any) => {
        const { status, data } = payload

        if (meta?.arg?.endpointName === 'learners') {
          if (status) {
            state.learners.list = data
          }

          state.learners.loader = false
          // eslint-disable-next-line
        } else if (meta?.arg?.endpointName === 'updateStartTask') {
          if (status) {
            // @ts-ignore
            state.modules.currentModule.task = (state?.modules?.currentModule?.task ?? [])?.map((t: any) =>
              t.current_task ? { ...t, status: 'in-progress' } : t,
            )
          }
        }
      })
      .addCase('webLMS.services/executeQuery/fulfilled', (state, { payload, meta }: any) => {
        const { status, data } = payload

        if (meta?.arg?.endpointName === 'miniPrograms') {
          if (status) {
            state.programs.miniList = data
          }

          state.programs.loader = false
        } else if (meta?.arg?.endpointName === 'programProgress') {
          if (status) {
            state.programs.progress.data = data
          }

          state.programs.progress.loader = false
        } else if (meta?.arg?.endpointName === 'moduleDetail') {
          if (status) {
            state.modules.currentModule = data
            let tasks = data?.task

            if (!tasks.find((t: any) => t.current_task)) {
              tasks = tasks?.map((t: any, idx: number) =>
                tasks?.length - 1 === idx ? { ...t, current_task: true } : t,
              )
            }

            const taskDetail = tasks?.find((t: any) => t.current_task)

            state.tasks.list = tasks
            // @ts-ignore
            state.programs.currentProgram.current_task = taskDetail?.id ?? null
            state.tasks.currentTask =
              // @ts-ignore
              state?.tasks?.currentTask?.id === taskDetail?.id
                ? { ...taskDetail, ...(state?.tasks?.currentTask ?? {}) }
                : taskDetail ?? null
          }

          state.modules.loader = false
        } else if (meta?.arg?.endpointName === 'taskDetail') {
          if (status) {
            state.tasks.currentTask = data
            // @ts-ignore
            state.editor.code = window.atob(data?.last_code_snip) ?? null
            // @ts-ignore
            state.editor.output = window.atob(data?.last_code_output) ?? null
          }

          state.tasks.loader = false
        } else if (meta?.arg?.endpointName === 'programList') {
          if (status) {
            state.programs.list = data
          }

          state.programs.loader = false
        } /*  else if (meta?.arg?.endpointName === 'updateTask') {
          const payload = meta?.arg?.originalArgs

          if (payload?.type === TASK_ACTION_TYPE.START) {
            // @ts-ignore
            state.tasks.currentTask.status = TASK_STATUS.STARTED
          }

          const tasks = state?.modules?.currentModule?.task
            ?.map((t: any) => t?.id === payload?.id
              ? {
                ...t,
                status: payload?.status === TASK_STATUS.STARTED ||
                  payload?.status === TASK_STATUS.DECLINED ||
                  payload?.status === TASK_STATUS.IN_REVIEW ||
                  payload?.status === TASK_STATUS.PENDING_ASSESSMENT
                  ? STATUS.IN_PROGRESS
                  : STATUS.COMPLETE
              } : t)
          state.modules.currentModule.task = tasks
          state.tasks.list = tasks
          } */
      })
  },
})

const { actions, reducer } = LMS
export const {
  storeLearnerList,
  toggleLearnerList,
  toggleModuleLoader,
  toggleProgramList,
  toggleProgramProgressLoader,
  updateCurrentProgam,
  toggleTaskLoader,
  updateTask,
  updateCurrentTask,
  updateEditor,
  updateSnackbar,
  updateStudent,
  resetLMS,
  resetModuleDetails,
  resetProgramForLearner,
  updateCurrentModule,
} = actions
export default reducer
