import { Box, Pagination } from "@mui/material";
import SuggestProgramCard from "./SuggestProgramCard";
import dummyIcon from "../../../assets/images/dummyIcon.svg";
import { useGetAllSuggestedProgramsQuery } from "../../../redux/api/api";
import { Fragment, useState } from "react";
import LmsHeader from "../../../components/reusables/LmsHeader";
import NoModulesFound from "../modules/NoModulesFound";
import SuggestedProgramDrawer from "./SuggestedProgramDrawer";
import SkeletonTable from "../../../components/reusables/SkeletonTable";
import BreadCrumbs from "../../../components/reusables/BreadCrumbs";
import Programming from "../../../assets/icons/no-program.svg";
import useDebounce from "../../../components/utils/useDebounce";

const Store = () => {
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [programName, setprogramName] = useState("");
  const [openProgramDrawer, setOpenProgramDrawer] = useState(false);
  const [programId, setProgramId] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const debouncedValue: any = useDebounce(programName, 700);
  const limit = 10;
  const { data, isLoading, isFetching } = useGetAllSuggestedProgramsQuery({
    limit: limit,
    offset: offset,
    programName: debouncedValue,
  });

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * limit);
  };

  const showProgramDrawerDetails = (id: any) => {
    setProgramId(id);
    setOpenProgramDrawer(true);
  };

  return (
    <>
      <BreadCrumbs breadCrumbsName="Store" navigateTo="store" />
      <LmsHeader
        name="Choose from Tapp Program Library"
        className="btnHidden"
        onChange={(e: any) => setprogramName(e.target.value)}
      />
      {isLoading ? (
        <Box
          component="div"
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "20px",
            padding: "1.5rem 0rem",
            "@media (max-width: 1566px)": {
              gridTemplateColumns: "repeat(3, 1fr)",
            },
          }}
        >
          {Array.from({ length: 9 }).map((_, index) => {
            const uniqueKey = `skeleton_${index}`;
            return (
              <Fragment key={uniqueKey}>
                <SkeletonTable
                  sx={{
                    width: "100%",
                    height: "11rem",
                    padding: "0rem",
                    borderRadius: "1rem",
                  }}
                />
              </Fragment>
            );
          })}
        </Box>
      ) : (
        <>
          {isFetching ? (
            <Box
              component="div"
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "20px",
                padding: "1.5rem 0rem",
                "@media (max-width: 1566px)": {
                  gridTemplateColumns: "repeat(3, 1fr)",
                },
              }}
            >
              {Array.from({ length: 9 }).map((_, index) => {
                const uniqueKey = `skeleton_${index}`;
                return (
                  <Fragment key={uniqueKey}>
                    <SkeletonTable
                      sx={{
                        width: "100%",
                        height: "11rem",
                        padding: "0rem",
                        borderRadius: "1rem",
                      }}
                    />
                  </Fragment>
                );
              })}
            </Box>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "1rem",
                padding: "1.5rem 0rem",
                "@media (max-width: 1566px)": {
                  gridTemplateColumns: "repeat(3, 1fr)",
                },
              }}
            >
              {data?.data?.programs?.map((item: any) => {
                return (
                  <SuggestProgramCard
                    key={item.id}
                    name={
                      item.name.length > 25
                        ? item.name.slice(0, 25) + "..."
                        : item.name
                    }
                    variant={String(item.difficulty).toLowerCase()}
                    img={dummyIcon}
                    modules={item.modules}
                    duration={item.duration}
                    price={item.price}
                    handleshowProgramDrawer={() =>
                      showProgramDrawerDetails(item.id)
                    }
                  />
                );
              })}
            </Box>
          )}

          {data?.data?.programs.length >= 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0.5rem 0rem",
                backgroundColor: "var(--lightGray)",
              }}
            >
              <Pagination
                count={Math.ceil(data?.data?.total / limit)}
                page={currentPage}
                onChange={(e, pageNumber) => handlePageChange(e, pageNumber)}
                color="standard"
                shape="rounded"
              />
            </Box>
          ) : null}
        </>
      )}
      {data?.data?.programs.length >= 1 || isLoading ? null : (
        <NoModulesFound
          // onClick={handleAddModules}
          heading="There are currently no Programs available."
          hideBtn="hideBtn"
          imgSrc={Programming}
        />
      )}

      <SuggestedProgramDrawer
        openProgramDrawer={openProgramDrawer}
        setOpenProgramDrawer={setOpenProgramDrawer}
        programId={programId}
        setProgramId={setProgramId}
        submitting={submitting}
        setSubmitting={setSubmitting}
      />
    </>
  );
};

export default Store;
