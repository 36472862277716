import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Buttons from "../../components/reusables/Buttons";
import styled from "styled-components";

const AlertTypo = styled.div`
  text-align: center;
  font-family:var(--fontFamiljen);
  font-size: 14px;
  font-weight:var(--font400);
  color: var(--inerTextColor);
  margin-bottom: 1rem;
  `;
const ScheduleCancelAlert = (props: any) => {
  const {
    confirmBox,
    setConfirmBox,
    onClick,
    title,
    message,
    submiting,
    hideBackDrop,
    nameBtn1,
    nameBtn2,
    imgSrc,
  } = props;

  const handleClose = () => {
    setConfirmBox(false);
  };
  return (
    <>
      <Dialog
        open={confirmBox}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        hideBackdrop={hideBackDrop ? true : false}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            fontFamily: "var(--fontFamiljen)",
            fontWeight: "var(--font500)",
            fontSize: "1.125rem",
            color: "var(--black)",
            padding: "0.5rem 0rem !important",
          }}
        >
          <Box
            sx={{
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "16px",
              // backgroundColor: "var(--alertBoxOrange)",
              margin: "0 auto",
              marginBottom: "0.625rem",
            }}
          ><img src={imgSrc} alt="icon" width={40} height={40}/></Box>
          {title}
        </DialogTitle>

        <DialogContent>
          <AlertTypo>{message}</AlertTypo>
        </DialogContent>

        <DialogActions
          sx={{ justifyContent: "center", gap: "0.5rem", display: "flex" }}
        >
          <Buttons
            type="submit"
            name={nameBtn1}
            variant="contained"
            color="inherit"
            size="small"
            fullWidth={false}
            sx={{
              padding: "0.3rem 1rem",
              borderRadius: "4px",
              textTransform: "capitalize",
            }}
            onClick={handleClose}
          />
          <Buttons
            type="submit"
            disabled={submiting ? true : false}
            name={nameBtn2}
            variant="contained"
            color="error"
            size="small"
            fullWidth={false}
            sx={{
              padding: "0.3rem 1rem",
              borderRadius: "4px",
              textTransform: "capitalize",
            }}
            className="BtnHover"
            onClick={onClick}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleCancelAlert;
