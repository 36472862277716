import { createTheme } from "@mui/material/styles";
import { FONT_FAMILY } from "../../../constants";

declare module "@mui/styles" {
  interface Theme {
    status: {
      primary: string;
      secondary: string;
      info: string;
      success:string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      primary?: ["color"];
      secondary?: ["color"];
      info?: ["color"];
      success?: ["color"];
    };
  }
}

export const theme: any = createTheme({
  palette: {
    primary: {
      main: "#EC5F2A",
    },
    secondary: {
      main: "#FDF1EC",
    },
    info: {
      main: "#ffffff",
    },
    success: {
      main: "#23C581",
    },
  },
  typography: {
    fontFamily: `'${FONT_FAMILY.BODY}', sans-serif`
  }
});
