export default function sendNotification(data: any, navigate: any) {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    console.log("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted
    // if so, create a notification
    const n = new Notification(data.title, {
      body: "",
      icon: "/assets/tapp-circle.png",
    });
    n.onclick = function clickNotification() {
      const url = data?.link?.replace(window.origin, "");

      if (url) {
        navigate(url, {
          state: {
            ...(data?.student
              ? { student: { ...data?.student, _id: data?.student?.id } }
              : { module_id: data?.module_id }),
            program: data?.program,
          },
        });
        window.focus();
      }

      n.close();
    };
  } else if (Notification.permission !== "denied") {
    // // We need to ask the user for permission
    // Notification.requestPermission().then((permission) => {
    //   // If the user accepts, let's create a notification
    //   if (permission === 'granted') {
    //     const notification = new Notification('Hi there!')
    //     // …
    //   }
    // })
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}
