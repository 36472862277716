import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Badge, Box } from "@mui/material";

import AlertBox from "./reusables/AlertBox";
import ChatPanel from "./Chat";
import NotificationPanel from "./NotificationPanel";
import flexbox from "../Style/flexbox";
import useUser from "../hooks/useUser";
import { logoutAction } from "../redux/slices/authSlice";
import { resetLMS } from "../redux/slices/LMS.slice";
import { Gray } from "../Style/colors";
import { useChat } from "../context/ChatContext";
import { useFetch } from "../context/NetworkAdapter";
import pusher from "../pusher";

import { ReactComponent as ChatIcon } from "../assets/icons/chat.svg";
import { ReactComponent as ChatBlackIcon } from "../assets/icons/chat-black.svg";
import { ReactComponent as NotificationIcon } from "../assets/icons/notification.svg";
import { ReactComponent as NotificationOrangeIcon } from "../assets/icons/notification-orange.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";
import { ReactComponent as TappLogo } from "../assets/icons/logo.svg";
import Logout from "../assets/icons/logout.svg";
import sendNotification from "../notification";

export interface HeaderProps {
  isLoggedIn?: boolean;
}

const channelName = process.env
  .REACT_APP_PUSHER_APP_NOTIFICATION_CHANNEL as string;

export default function Header(props: HeaderProps): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chatCtx = useChat();
  const loggedInUser = useUser();
  const fetch = useFetch();

  const [confirmBox, setConfirmBox] = useState<boolean>(false);
  const [chatPanel, toggleChatPanel] = useState<boolean>(false);
  const [notificationPanel, toggleNotificationPanel] = useState<boolean>(false);
  const [unreadNotifs, setUnreadNotifs] = useState<any[]>([]);
  const [unreadApiCount, setUnreadApiCount] = useState<number>(0);
  const [userList, setUserList] = useState<any[]>([]);

  // @ts-ignore
  const unreadMsgs = [...new Set(chatCtx?.unreadMsgs?.map((m: any) => m?.sender?.id) as string[])];

  useEffect(() => {
    window.addEventListener("beforeunload", function (e) {
      fetch?.("logout");
    });

    const userNotif = pusher.subscribe(channelName);

    userNotif.bind(loggedInUser?.id, (data: any) => {
      sendNotification(data, navigate);
      setUnreadNotifs((prev: any[]) => [...prev, data]);
    });
    (async () => {
      if (loggedInUser || props?.isLoggedIn) {
        const users = await chatCtx?.users();

        if (users?.length > 0) {
          setUserList(users);
        }
      } else {
        fetch?.("logout");
        userNotif.unbind_all();
        userNotif.unsubscribe();
      }
    })();

    return () => {
      if (!loggedInUser?.id) {
        userNotif.unbind_all();
        userNotif.unsubscribe();
        fetch?.("logout");
      }
    };
  }, [loggedInUser]);

  function tryLogout() {
    chatCtx?.resetContext();
    dispatch(logoutAction(""));
    dispatch(resetLMS(""));
    navigate("/web-login");
    setConfirmBox((prev: boolean) => !prev);
  }

  useEffect(() => {
    (async () => {
      const resp =
        unreadNotifs.length === 0 &&
        (await fetch(`notification-list?offset=0&limit=20`));
      if (resp?.status) {
        setUnreadApiCount(resp.data.unread_notifications_count);
      }
    })();
  }, [unreadNotifs.length === 0]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: "12px",
        px: "24px",
        gap: "48px",
        borderBottom: `1px solid ${Gray["100"]}`,
      }}
    >
      <TappLogo width="27px" height="36px" />

      {props?.isLoggedIn && (
        <Box sx={flexbox("row", null, "center", "26px")}>
          <Box
            sx={{
              width: 32,
              minWidth: 32,
              height: 32,
              minHeight: 32,
              cursor: "pointer",
            }}
            onClick={() => toggleChatPanel((prev: boolean) => !prev)}
          >
            <Badge badgeContent={unreadMsgs?.length ?? 0} color="primary">
              {chatPanel ? <ChatIcon /> : <ChatBlackIcon />}
            </Badge>
          </Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => toggleNotificationPanel((prev: boolean) => !prev)}
          >
            <Badge
              badgeContent={
                unreadApiCount!==0
                  ? unreadApiCount
                  : unreadNotifs?.length ?? 0
              }
              color="primary"
            >
              {notificationPanel ? (
                <NotificationOrangeIcon />
              ) : (
                <NotificationIcon />
              )}
            </Badge>
          </Box>
          <Box
            sx={{
              width: 32,
              minWidth: 32,
              height: 32,
              minHeight: 32,
              cursor: "pointer",
            }}
            onClick={() => setConfirmBox((prev: boolean) => !prev)}
          >
            <ProfileIcon width="32px" height="32px" />
          </Box>
        </Box>
      )}

      <ChatPanel
        open={chatPanel}
        onClosePanel={() => toggleChatPanel((prev: boolean) => !prev)}
        userList={userList ?? []}
      />

      <NotificationPanel
        open={notificationPanel}
        onClosePanel={() => {
          toggleNotificationPanel((prev: boolean) => !prev);
          setUnreadNotifs([]);
          setUnreadApiCount(0);
        }}
        data={{
          get: unreadNotifs,
          set: setUnreadNotifs,
        }}
      />

      <AlertBox
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        onClick={tryLogout}
        title="Logout"
        nameBtn1="No Cancel"
        nameBtn2="Yes, Logout"
        message={<p style={{ margin: 0 }}>Are you sure you want to logout?</p>}
        imgSrc={Logout}
      />
    </Box>
  );
}
