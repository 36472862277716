import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedOption: "",
  programDescription: "",
  childIndex: null,
  scroll: false,
};

const mainSidebarSlice: Slice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    storeProgramDescription: (state, { payload }) => {
      state.programDescription = payload
    },
    storeChildIndex: (state, { payload }) => {
      state.childIndex = payload
    },
    scrollToTopFunction: (state, { payload }) => {
      state.scroll = payload;
    }
  },
});

export const { setSelectedOption, storeProgramDescription, storeChildIndex, scrollToTopFunction } = mainSidebarSlice.actions;
export default mainSidebarSlice.reducer;
