import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isToday from 'dayjs/plugin/isToday'

import flexbox from '../../Style/flexbox'
import useUser from '../../hooks/useUser'
import { baseTextStyle } from '../../Style/base'
import { Gray, Orange, Shades } from '../../Style/colors'
import { FONT_FAMILY, FONT_WEIGHT } from '../../constants'
import { useChat } from '../../context/ChatContext'

dayjs.extend(utc)
dayjs.extend(isToday)

export default function ChatItem(props: any): JSX.Element {
  const loggedInUser = useUser()
  const chatCtx = useChat()

  const onClick = props?.onClick
  const user = props?.user
  const isMentor = chatCtx?.isMentor
  const chatId = isMentor
    ? `${loggedInUser?.chat_uid}:${user?.chat_uid}`
    : `${user?.chat_uid}:${loggedInUser?.chat_uid}`

  const [lastMsg, setLastMsg] = useState<any>(null)
  const [unreadMsgCount, setUnreadMsgCount] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      chatCtx?.getChatCounts?.(chatId, (chats: any) => {
        setUnreadMsgCount(chats?.length ?? 0)
      })
      chatCtx?.getUserLastMessage(chatId, (data: any) => {
        setLastMsg(data?.[data?.length - 1] ?? null)
      })
    })()
  }, [])

  return (
    <Box
      sx={{
        ...flexbox('row', 'space-between', 'flex-start', '0', '16px 24px'),
        width: '100%',
        cursor: 'pointer',
        ':hover': { bgcolor: '#eee' },
      }}
      onClick={() => onClick?.(user)}
    >
      <Box sx={flexbox('row', 'space-between', 'flex-start', '12px')}>
        <Box
          sx={{
            width: 48,
            height: 48,
            display: 'grid',
            placeItems: 'center',
            bgcolor: Orange['100'],
            borderRadius: 1000,
          }}
        >
          <Typography sx={baseTextStyle(FONT_FAMILY.HEADING, FONT_WEIGHT.EXTRA_BOLD, '14px', Orange['500'])}>
            {user?.name
              ?.split?.(' ')
              ?.map((name: string) => name?.substring(0, 1))
              ?.join('')}
          </Typography>
        </Box>

        <Box sx={{ ...flexbox('column', null, 'flex-start', '2px') }}>
          <Typography sx={{ ...baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.MEDIUM, '16px', Shades['101']) }}>
            {user?.name}
          </Typography>
          <Typography
            sx={{
              ...baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.MEDIUM, '16px', Gray['800']),
              ...flexbox('row', 'center', 'center', '18px'),
            }}
          >
            {lastMsg?.content?.length > 20 ? `${lastMsg?.content?.substring(0, 20)}...` : lastMsg?.content}
          </Typography>
        </Box>
      </Box>
      {unreadMsgCount > 0 && (
        <Box sx={{ ...flexbox('column', null, 'flex-start', '2px') }}>
          <Typography
            key={unreadMsgCount}
            sx={{
              ...baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.MEDIUM, '16px', Gray['800']),
              bgcolor: Orange['600'],
              borderRadius: 100,
              color: Shades['0'],
              ...flexbox('row', 'center', 'center', null, unreadMsgCount > 10 ? '0 7px' : '1.1px 8px'),
            }}
          >
            {unreadMsgCount}
          </Typography>
        </Box>
      )}

      {unreadMsgCount === 0 && lastMsg && (
        <Box sx={{ ...flexbox('column', null, 'flex-start', '2px') }}>
          <Typography
            key={unreadMsgCount}
            sx={baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.MEDIUM, '16px', Gray['800'])}
          >
            {dayjs(lastMsg?.created_at).utc().isToday()
              ? dayjs(lastMsg?.created_at).format('hh:mm A')
              : dayjs(lastMsg?.created_at).format('MMM-DD-YYYY')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
