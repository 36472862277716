import { Green, Red, Yellow } from "../Style/colors"

export const ONBOARD_PAGE = {
  WELCOME_STEP: 1,
  MENTOR_STEP: 2,
  MENTOR_BIO_STEP: 3,
  AVAILABILITY_STEP: {
    INITIAL_STEP: 5,
    SYNC_STEP: 6,
    ERROR_STEP: 7,
    SUCCESS_STEP: 8,
  },
}

export const FONT_WEIGHT = {
  THIN: 100,
  EXTRA_LIGHT: 200,
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
  EXTRA_BLACK: 950,
}

export const FONT_FAMILY = {
  HEADING: 'Mulish',
  BLACK_HEADING: 'MulishExtraBold',
  BODY: 'Familjen Grotesk',
}

export const STATUS: any = {
  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
}

export const TASK_STATUS: any = {
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  // UPDATED: 'updated',
  COMPLETED: 'completed',
  DECLINED: 'declined',
  IN_REVIEW: 'in_review',

  PENDING: 'pending',
  IN_PROGRESS: 'in_progress',
  PENDING_ASSESSMENT: 'pending_assessment',
}

export const TASK_TYPE = {
  READING_AND_LEARNING: 'Reading & Learning',
  PRACTICE: 'Practice',
  ASSESSMENT: 'Assessment',
}

export const PANEL = {
  ADD_UPDATE: 'add_update',
  VIEW_UPDATE: 'view_update',
  FINISH_TASK: 'finish_task',
  TASK_DETAILS: 'task_details',
}

export const TASK_ACTION_TYPE = {
  FINISH: 'finish',
  UPDATE: 'update',
  ADD: 'add',
  COMPLETE: 'complete',
  START: 'start',
  APPROVE: 'approve',
  DECLINE: 'decline',
  FINAL: 'final',
}

export const USER_TYPE = {
  LEARNER: 'learner',
  MENTOR: 'mentor',
}

export const LANG_REGEX =
  /^(Typescript|C\s|C\+\+|C#\s|Objective-C\s|Dart\s|Go\s|Java\s|JavaScript\s|Kotlin\s|Lua|Perl\s|PHP\s|Python\s|Ruby\s|Rust\s|Swift\s|TypeScript\s)/

export const UPDATE_TYPE = {
  APPROVE: 'approve',
  DECLINE: 'decline',
  COMPLETE: 'complete',
  FEEDBACK: 'feedback',
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PROTOCOL = '^(https:\\/\\/)?'
export const DOMAIN_NAME = '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
export const IP_ADDRESS = '((\\d{1,3}\\.){3}\\d{1,3}))'
export const PORT_OR_PATH = '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
export const QUERY_STRING = '(\\?[;&a-z\\d%_.~+=-]*)?'
export const FRAGMENT_LOCATOR = '(\\?[;&a-z\\d%_.~+=-]*)?'
export const NOOP = (): void => {
  // this would be an empty handler
}

export const DIFFICULTY = {
  BEGINNER: 'Beginners',
  INTERMEDIATE: 'Intermediate',
  PRO: 'Pro',
}

export const ASSESSMENT_STATUS = {
  IN_REVIEW: 'Open for review',
  NO_UPDATE: '-',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
}

export const SNACKBAR_TYPE = {
  FINAL_TASK_COMPLETE: 'final_task_complete',
  FINAL_TASK_UPDATE: 'final_task_update',
  READING_TASK_COMPLETE: 'reading_task_complete',
  READING_TASK_UPDATE: 'reading_task_update',
  NORMAL: 'normal',
  SUCCESS: 'success',
}

export const TIME_REGEX = /(\d{2}:\d{2}\s[A|P]?M)/g

export function getDifficultyColor(difficulty: string): string {
  if (difficulty === 'intermediate')
    return 'var(--intermediateBackground)'
  
  if (difficulty === 'pro')
    return 'var(--themeOrange)'
  
  return 'var(--topPerformer)'
}

export function getProgressColor(score: any): string {
  if ((score / 10) * 100 <= 30)
    return Red['500']

  if ((score / 10) * 100 >= 31 && (score / 10) * 100 <= 80)
    return Yellow['300']

  return Green['300']
}

export const successResponse = ['You’re on the right track', 'Keep up the same pace', 'Keep up the good effort', 'Keep it up', 'You’re learning fast!']

export const random = (list: string[]) => list[Math.floor((Math.random()*list.length))]
