import styled from "styled-components";
import { makeStyles, createStyles } from "@mui/styles";

// [COMPONENTS] REUSABLES > LOADER

const LoaderContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// [PAGES] FEEDBACK-PARAMETERS > ADD-FEEDBACK
// &&
// [PAGES] LMS > MODULES > ADD-MODULES
const customStyle = {
  width: 500,
  marginTop: "45px",
  height: "100%",
  position: "relative",
  p: 4,
  overFlowY: "scroll",
};

const cardModulesNo = {
  color: "var(--percentageTextColor)",
  fontFamily: "var(--fontFamiljen)",
};

const cardModulesHeading = {
  color: "var(--dashBoardEmptyText)",
  fontWeight: "500",
  fontFamily: "var(--fontFamiljen)",
};

const progressTrackingStyle = {
  fontFamily: `var(--fontFamiljen)`,
  fontSize: "0.750rem",
  fontWeight: "var(--font500)",
  textTransform: "uppercase",
  backgroundColor: "var(--lightGray)",
  padding: "0.4rem 0.5rem",
  borderRadius: "0.375rem",
};

const customStyleTask = {
  width: 500,
  marginTop: "32px",
  height: "100%",
  position: "relative",
  p: 4,
  overFlowY: "scroll",
};

const customStyle_2 = {
  width: 500,
  marginTop: "45px",
  height: "100%",
  position: "relative",
  p: 0,
  overFlowY: "scroll",
};

// [PAGES] LMS > TASKS > ADD-TASK-MODAL
const styleAddTaskModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "550px",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const inputEmail = {
  width: "365px",
  height: "48px",
  fontSize: "14px",
  fontFamily: "var(--fontFamiljen)",
  lineHeight: "18px",
  fontWeight: "400",
  outline: "0",
  border: "none",
  padding: "6px 0px 6px 16px",
  background: "var(--lightGray)",
  borderRadius: "24px",
};

// [PAGES] LOGIN
const ContainerFluid = styled.div`
  min-height: 100vh;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: 55% 45%;
  @media (max-width: 768px) {
    display: block;
  }
`;

const MainBox = styled.div`
  display: grid;
  align-items: start;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 2rem !important;
  }
`;

const FormBox = styled.div`
  width: 29rem;
  @media (max-width: 768px) {
    width: auto;
  }
`;

const InputDiv = styled.div`
  width: 100%;
  display: grid;
`;

const Heading = styled.div`
  text-align: left;
  margin-bottom: 2.25rem;
`;
const Heading2 = styled.div`
  text-align: left;
  margin-bottom: 1rem;
`;

const Footer = styled.div`
  padding: 3.5rem 0rem 1rem 0rem;
  @media (max-width: 768px) {
    margin-bottom: 3rem !important;
  }
`;

const PasswordInputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Span = styled.span`
  position: absolute;
  right: 0.5rem;
  top: 15px;
  cursor: pointer;
`;

// LMS
const StyledSearchInput = styled.input`
  border: 0;
  outline: none;
  background-color: white;
  border-radius: 100px;
  border: 1.5px solid white;
  margin-left: 24px;
  padding: 9px 12px 9px 35px;
  width: 25rem;

  :focus {
    border: 1.5px solid #ec5f2a;
    background-color: #fdf1ec;
  }
`;

const StackDiv = styled.div<any>`
  position: relative;
  background: #fff8eb;
  font-family: var(--fontMulish);
  border-radius: 1rem;
  min-height: 11rem;
  max-width: 100%;
  cursor: pointer;
  h3 {
    margin: 0;
    font-size: 18px;
    font-family: var(--fontFamiljen);
    font-weight: var(--font700);
    line-height: 22.5px;
    text-transform: capitalize;
    margin-bottom: 0.6rem;
    color: #100f0f;
  }

  button {
    all: unset;
    cursor: pointer;
  }
  p {
    font-size: 12px;
    font-family: var(--fontFamiljen);
  }

  > div {
    > button {
      bottom: 16px;
      position: absolute;
      bottom: 1rem;
      right: 1rem;

      img {
        width: 14px;
        border: 1.5px solid #ec5f2a;
        padding: 10px;
        border-radius: 50px;
      }
    }
  }
`;

const StyledAddProgramButton = styled.button`
  border: 0;
  cursor: pointer;
  border-radius: 1rem;
  padding: 2.3rem;

  p:first-child {
    color: #ec5f2a;
    font-size: 2.4rem;
    font-family: auto;
    margin: 0;
  }

  p:nth-child(2) {
    font-family: var(--fontFamiljen);
    font-weight: var(--font400);
    font-size: 1rem;
    margin: 0 auto;
    width: 60%;
  }
`;

export const TaskType = styled.div<any>`
  padding: 2px 15px;
  border: 1px solid #100f0f;
  border-radius: 4px;
  font-size: 12px;
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  text-transform: capitalize;
  background-color: ${({ taskType }) => {
    switch (taskType) {
      case "Reading & Learning":
        return "#06D6A0";
      case "Hands-On Learning":
        return "#FAAD15";
      case "Assessment":
        return "#EC5F2A";
      default:
        break;
    }
  }};
`;

export const ProgramHeading = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0rem 1.25rem 0rem;

  p {
    font-size: 20px;
    font-weight: var(--font800);
    font-family: var(--fontMulish);
  }

  a {
    text-decoration: none;
    color: #ec5f2a;
    font-size: 14px;
    font-weight: var(--font500);
    font-family: var(--fontFamiljen);
  }
`;

// LMS > MODULES > ADD-MODULES
const useStyles: any = makeStyles(() =>
  createStyles({
    imageBox: {
      cursor: "pointer",
      background: "#0b2237",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "200px",
      minWidth: "405px",
      marginRight: "50px",
      marginTop: "10px",
      borderRadius: "12px",
      width: "100%",
    },
    backImage: {
      height: "150px",
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    closeClass: {
      position: "absolute",
      top: "-18px",
      right: "-18px",
      height: "25px",
      width: "25px",
      border: "1px solid black",
      borderRadius: "50%",
      margin: "0",
      cursor: "pointer",
    },
  })
);

// LMS > MODULES > no-modules-found
const NoModulesDiv = styled.div`
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;
  h4 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 700;
    font-family: var(--fontMulish);
  }
  p {
    text-align: center;
    margin-bottom: 1rem;
    font-family: var(--fontFamiljen);
    color: #6b6b6b;
    font-size: 14px;
  }
`;

// LoginOtp
const Image = styled.img`
  margin-bottom: 1.5rem;
`;

const selectProgramChip = {
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
  fontWeight: 500,
  height: "56px",
  fontSize: "14px",
  fontFamily: "var(--fontFamiljen)",
  color: "var(--activityText)",
};

export {
  LoaderContainer,
  customStyle,
  customStyleTask,
  ContainerFluid,
  FormBox,
  InputDiv,
  Heading,
  Footer,
  PasswordInputDiv,
  Span,
  styleAddTaskModal,
  MainBox,
  StyledSearchInput,
  StackDiv,
  StyledAddProgramButton,
  NoModulesDiv,
  Image,
  useStyles,
  customStyle_2,
  Heading2,
  inputEmail,
  selectProgramChip,
  progressTrackingStyle,
  cardModulesNo,
  cardModulesHeading,
};
