import { Box, Typography } from "@mui/material";

import ChatItem from "./ChatItem";
import ChatMessage from "./ChatMessage";
import RightDrawer from "../RightDrawer";
import flexbox from "../../Style/flexbox";
import { baseTextStyle } from "../../Style/base";
import { Gray, Shades } from "../../Style/colors";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { useChat } from "../../context/ChatContext";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

export default function ChatPanel(props: any): JSX.Element {
  const { onClosePanel, userList } = props;

  const chatCtx = useChat();

  return (
    <>
      <RightDrawer
        {...props}
        sx={{
          top: "61px",
          "> .MuiBackdrop-root": { top: "61px", background: Gray["60"] },
          "> .MuiPaper-root": { boxShadow: "none", top: "61px" },
        }}
      >
        <Box sx={{ width: 416, mb: "82px" }}>
          <Box
            sx={{
              ...flexbox("row", "space-between", "center", null, "16px 24px"),
              borderBottom: `1px solid ${Gray["100"]}`,
            }}
          >
            <Typography
              sx={baseTextStyle(
                FONT_FAMILY.HEADING,
                FONT_WEIGHT.EXTRA_BOLD,
                20,
                Shades["100"]
              )}
            >
              Chat
            </Typography>
            <Box
              sx={{
                cursor: "pointer",
                width: 32,
                minWidth: 32,
                height: 32,
                minHeight: 32,
              }}
              onClick={onClosePanel}
            >
              <CloseIcon />
            </Box>
          </Box>

          {userList?.length ? (
            (userList ?? [])?.map((item: any) => (
              <ChatItem
                key={item?.id}
                onClick={(user: any) => chatCtx?.onSelectUser(user)}
                user={item}
              />
            ))
          ) : (
            <Typography
              sx={{
                ...baseTextStyle(
                  FONT_FAMILY.BODY,
                  FONT_WEIGHT.NORMAL,
                  "18px",
                  Shades["101"]
                ),
                textAlign: "center",
                mt: "18px",
              }}
            >
              No learners found.
            </Typography>
          )}
        </Box>
      </RightDrawer>

      <ChatMessage
        onClosePanel={(closeParentPanel: boolean = false) => {
          chatCtx?.onSelectUser(null);
          if (closeParentPanel) onClosePanel?.();
        }}
        user={chatCtx?.selectedUser ?? null}
      />
    </>
  );
}
