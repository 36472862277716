import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { whiteCrossIcon } from "../../../assets/logos/logos";
import DeleteMentorIcon from "../../../assets/icons/deleteMentor.svg";
import Radio from "@mui/material/Radio";
import {
  useDeleteMentorsMutation,
  useGetAllReassignMentorsMutation,
} from "../../../redux/api/api";
import { DeleteMentorDrawerInterface } from "../../../Interface/Interface";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";
import NoModulesFound from "../../LMS/modules/NoModulesFound";
import SkeletonTable from "../../../components/reusables/SkeletonTable";
import { Link } from "@mui/material";
import styles from "../user.module.css";
import assignMentor from "../../../assets/icons/assignAdmin.svg";
import { Orange } from "../../../Style/colors";

const DeleteMentorDrawer = ({
  openDeleteModal,
  updateList,
  setUpdateList,
  onHide,
  deleteMentorId,
}: DeleteMentorDrawerInterface) => {
  const dispatch = useDispatch<any>();
  const [selectedValue, setSelectedValue] = useState("");
  const [deleteMentorData, setDeleteMentorData] = useState<any>([]);
  const [enableBtn, setEnableBtn] = useState(false);

  const [getAllReassignMentors, { isLoading }] =
    useGetAllReassignMentorsMutation();
  const [deleteMentors] = useDeleteMentorsMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    setEnableBtn(true);
  };

  const btnStyle = {
    background: enableBtn ? "var(--themeOrange)" : "var(--assignFadeBtn)",
    cursor: enableBtn ? "pointer" : "not-allowed",
    color: enableBtn ? "#ffffff" : "var(--InputTextGray)",
  };

  const deleteMentorNameText = {
    color: deleteMentorId?.name ? "var(--themeOrange)" : "var(--black)",
  };

  const getDeleteMentorsList = async () => {
    const reqObj = {
      url: "re-assign-mentor-list",
      body: {
        mentor_id: deleteMentorId?.id,
      },
    };
    try {
      const resp = await getAllReassignMentors(reqObj).unwrap();
      if (resp.code === 3025) {
        setDeleteMentorData(resp?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDeleteMentorsList();
  }, []);

  const handleDeleteMentor = async () => {
    const reqObj = {
      url: "mentor-delete",
      body: {
        mentor_id: deleteMentorId?.id,
        assign_mentor_id: selectedValue,
      },
    };
    try {
      const resp = await deleteMentors(reqObj).unwrap();
      if (resp.code === 3031) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setUpdateList(!updateList);
        onHide();
      } else {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "warning",
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={onHide}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modalStyles}>
          <img
            className={styles.crossIconStyle}
            onClick={onHide}
            src={whiteCrossIcon}
            alt="whiteCrossIcon"
          />
          <Box
            padding="24px 20px 0 24px"
            display="flex"
            gap="16px"
            alignItems="start"
          >
            <Box sx={{
              bgcolor: Orange["700"],
              borderRadius: "8px",
              height: "36px",
              width: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <img src={DeleteMentorIcon} alt="DeleteMentorIcon" />
            </Box>
            <Box display="flex" gap="4px" flexDirection="column">
              <Typography className={styles.textHeading}>
                Delete Mentor?
              </Typography>
              {deleteMentorData?.length === 0 ? (
                <Typography className={styles.deleteMentorHeading}>
                  No mentors to assign
                </Typography>
              ) : (
                <Box className={styles.deleteMentorHeading}>
                  To delete{" "}
                  <Link
                    underline="none"
                    href="#"
                    className={styles.deleteMentorText}
                    sx={deleteMentorNameText}
                  >
                    {deleteMentorId?.name}
                  </Link>
                  , re-assign his students to some other mentor in program,
                  re-assign
                </Box>
              )}
            </Box>
          </Box>
          <hr
            style={{
              margin: "20px 0 20px 0",
              borderTop: "1px solid var(--borderInput)",
            }}
          />
          <Typography padding="0 20px 18px 20px" className={styles.textHeading}>
            {deleteMentorData?.length === 0 ? null : "Select Mentors"}
          </Typography>
          <Box height="290px" overflow="auto">
            {isLoading &&
              Array.from({
                length: 2,
              }).map((_, index) => {
                const uniqueKey = `item_${index}`;
                return (
                  <Fragment key={uniqueKey}>
                    <SkeletonTable
                      sx={{
                        width: "100%",
                        height: "72px",
                        marginBottom: "0.5rem",
                      }}
                    />
                  </Fragment>
                );
              })}
            {!deleteMentorData?.length && !isLoading ? (
              <NoModulesFound
                hideBtn="hideBtn"
                heading="Add Another Mentor to reassign"
                imgSrc={assignMentor}
              />
            ) : (
              deleteMentorData?.map((item: any, index: number) => {
                return (
                  <Box
                    key={item?.id}
                    padding="12px 32px"
                    display="flex"
                    gap="16px"
                    alignItems="start"
                    borderBottom="1px solid var(--borderInput)"
                  >
                    <Box
                      width="100%"
                      display="flex"
                      gap="8px"
                      flexDirection="column"
                    >
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          fontSize="14px"
                          fontFamily="var(--fontFamiljen)"
                          fontWeight="400"
                          fontStyle="normal"
                          lineHeight="18px"
                          color="var(--black)"
                        >
                          {item?.name}
                        </Typography>
                        <Radio
                          checked={selectedValue === item?.id}
                          onChange={handleChange}
                          value={item?.id}
                          sx={{ padding: "0" }}
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                      <Box
                        flexWrap="wrap"
                        display="flex"
                        alignItems="center"
                        gap="8px"
                      >
                        {item?.programs?.map((it: any, ind: number) => {
                          return (
                            <Box
                              key={it?._id}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              padding="5px 12px"
                              fontSize="12px"
                              fontFamily="var(--fontFamiljen)"
                              fontWeight="400"
                              fontStyle="normal"
                              lineHeight="15px"
                              width="auto"
                              height={it?.name?.length > 45 ? "auto" : "24px"}
                              bgcolor="var(--themeOrangeLight)"
                              borderRadius="23px"
                            >
                              {it?.name?.length > 45 ? (
                                <Box component="span">
                                  {it?.name?.substring(0, 45)}
                                  <br />
                                  {it?.name?.substring(45, 90)}
                                  <br />
                                  {it?.name?.substring(90, it?.name?.length)}
                                </Box>
                              ) : (
                                it.name
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            )}
          </Box>
          <Box position="absolute" bottom="0" right="0" padding="20px">
            <button
              disabled={!enableBtn}
              type="submit"
              onClick={handleDeleteMentor}
              style={btnStyle}
              className={styles.deleteBtnStyle}
            >
              Delete
            </button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteMentorDrawer;
