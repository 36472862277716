import { combineReducers } from "@reduxjs/toolkit";

import { api } from "../api/api";
import authSlice from "../slices/authSlice";
import MainSidebarSlice from "../slices/MainSidebarSlice";
import progressTrackingSlice from "../slices/progressTrackingSlice";
import snackbarSlice from "../slices/snackbarSlice";
import modulesSlice from "../slices/modulesSlice";
import lms from "../slices/LMS.slice";
import * as webLMSApi from "../api/webLMS.api";
import upcomingClasseSlice from "../slices/upcomingClasseSlice";
import batchesSlice from "../slices/batchesSlice";

const rootReducer = combineReducers({
  authSlice: authSlice,
  batchesSlice:batchesSlice,
  modulesSlice: modulesSlice,
  mainSidebarSlice: MainSidebarSlice,
  progressTrackingSlice: progressTrackingSlice,
  upcomingClassSlice: upcomingClasseSlice,
  webLMS: lms,
  snackbarSlice,
  [api.reducerPath]: api.reducer,
  [webLMSApi.api.reducerPath]: webLMSApi.api.reducer,
});

export default rootReducer;
