export type FlexDirection = 'row' | 'column'

export default function flexbox(
  flexDirection: FlexDirection,
  justifyContent: string | null = null,
  alignItems: string | null = null,
  gap: string | null = null,
  padding: string | null = null,
) {
  return {
    display: 'flex',
    flexDirection,
    ...(justifyContent ? { justifyContent } : {}),
    ...(alignItems ? { alignItems } : {}),
    ...(gap ? { gap } : {}),
    ...(padding ? { padding } : {}),
  }
}
