import { CircularProgress } from "@mui/material";
import { LoaderContainer } from "../../Style/style";

const Loader = ({ height }: any) => {
  return (
    <LoaderContainer style={{ height: `calc(100vh - ${height})` }}>
      <CircularProgress />
    </LoaderContainer>
  );
};

export default Loader;
