import {Stack } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const SkeletonTable = (props:any) => {
  const {sx={}}=props;
  return (
   <Stack sx={{...sx}}>
        <Skeleton animation="wave" width="100%" variant='rectangular' height="100%"/>
   </Stack>
  )
}

export default SkeletonTable;