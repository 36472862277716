import { Box, Typography, Button } from "@mui/material";
import { StyledSearchInput } from "../../Style/style";
import SearchIcon from "@mui/icons-material/Search";
import { ChangeEventHandler } from "react";
import { useSelector } from "react-redux";

interface LMSHeaderType {
  name?: string;
  buttonText?: string;
  functn?: any;
  className?: string;
  BtnText?: string;
  DeleteBtn?: string;
  deleteALLBox?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  endIcon?: any;
  value?: string;
  addRolesCheck?: boolean;
  deleteRolesCheck?: boolean;
  disabled?:boolean;
}

function LmsHeader(props: LMSHeaderType) {
  const {
    name,
    buttonText,
    functn,
    className,
    BtnText,
    DeleteBtn,
    addRolesCheck,
    deleteALLBox,
    deleteRolesCheck,
    onChange,
    endIcon,
    value,
    disabled
  } = props;

  const selector: any = useSelector((store) => store);
  const deleteBtn = selector.snackbarSlice.deleteBtn;

  const handleBtnText = () => {
    if (addRolesCheck === undefined) {
      return false;
    } else {
      return !addRolesCheck;
    }
  };

  const handleDeleteRole = () => {
    if (addRolesCheck === undefined) {
      return false;
    } else {
      return !deleteRolesCheck;
    }
  };
  return (
    <>
      <Typography
        fontSize="1.5rem"
        fontFamily="var(--fontMulish)"
        fontWeight="var(--font800)"
        variant="body1"
        marginBottom="0.5rem"
      >
        {name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F4F4",
          padding: ".625rem 0",
          position: "relative",
          width: "calc(100% + 3rem)",
          marginLeft: "-1.5rem",
        }}
      >
        <Box>
          <SearchIcon
            sx={{
              position: "absolute",
              top: "32%",
              left: "2.1875rem",
              opacity: "0.5",
            }}
          />
          <StyledSearchInput
            placeholder="Search"
            type="search"
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
          }}
          className={className}
        >
          <Button
            variant="text"
            color="primary"
            onClick={deleteALLBox}
            disabled={handleDeleteRole()}
            sx={{
              marginRight: "24px",
              textTransform: "capitalize",
              fontWeight: `var(--font500)`,
              fontFamily: `var(--fontFamiljen)`,
              display: deleteBtn ? "flex" : "none",
              justifySelf: "end",
              color: "red",
            }}
          >
            {DeleteBtn}
          </Button>

          <Button
            variant="text"
            disabled={handleBtnText()}
            endIcon={endIcon}
            color="primary"
            name={`Add ${buttonText}`}
            onClick={functn}
            sx={{
              marginRight: "24px",
              textTransform: "capitalize",
              fontWeight: `var(--font500)`,
              fontFamily: `var(--fontFamiljen)`,
            }}
          >
            {BtnText}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default LmsHeader;
