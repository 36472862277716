import {
  Box,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { FilterCrossIcon, FilterIcon } from "../../assets/logos/logos";
import { useGetAllRolesAndPermissionListQuery } from "../../redux/api/api";

const quantityCircle = {
  height: "20px",
  width: "20px",
  marginLeft: "8px",
  color: "#fff",
  fontSize: "14px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "red",
  borderRadius: "50%",
};
const label = { inputProps: { "aria-label": "Switch demo" } };

const clearAll = {
  width: "auto",
  height: "35px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px 8px",
  borderRadius: "8px",
  fontFamily: "var(--fontsFamily)",
  fontSize: "14px",
  color: "orange",
  "&:hover": {
    backgroundColor: "var(--borderInput)",
    cursor: "pointer",
  },
  "&:focus": {
    backgroundColor: "var(--borderInput)",
    cursor: "pointer",
  },
};

const FilterBox = ({
  filteredPermission,
  membersIdArray,
  programIdArray,
  mentorIdArray,
  alignment,
  data,
  mentorData,
  setMentorIdArray,
  setProgramIdArray,
  setMembersIdArray,
  programsList,
}: any) => {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { data: rolesListData, isError } =
    useGetAllRolesAndPermissionListQuery(null);

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleFilter = () => {
    if (
      ((alignment === "Learner" || alignment === "Mentor") &&
        !programsList?.data?.total) ||
      (alignment === "Learner" && !data?.total) ||
      (alignment === "Mentor" && !mentorData?.total)
    )
      return;
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
    setOpen(true);
  };

  const handleCheckedBox = (item: any) => {
    if (alignment === "Learner") {
      const programIdArr = [...programIdArray];
      let index = null;
      programIdArr.forEach((val: any, i: number) => {
        if (val === item.id) {
          index = i;
        }
      });
      if (index !== null) {
        programIdArr.splice(index, 1);
      } else {
        programIdArr.push(item.id);
      }
      setProgramIdArray(programIdArr);
    } else if (alignment === "Mentor") {
      const mentorIdArr = [...mentorIdArray];
      let index = null;
      mentorIdArr.forEach((val: any, i: number) => {
        if (val === item.id) {
          index = i;
        }
      });
      if (index !== null) {
        mentorIdArr.splice(index, 1);
      } else {
        mentorIdArr.push(item.id);
      }
      setMentorIdArray(mentorIdArr);
    } else {
      const memberIdArr = [...membersIdArray];
      let index = null;
      memberIdArr.forEach((val: any, i: number) => {
        if (val === item.id) {
          index = i;
        }
      });
      if (index !== null) {
        memberIdArr.splice(index, 1);
      } else {
        memberIdArr.push(item.id);
      }
      setMembersIdArray(memberIdArr);
    }
  };

  const filteredQuantity = () => {
    if (programIdArray?.length > 0) {
      return <Box sx={quantityCircle}>{programIdArray.length}</Box>;
    } else if (mentorIdArray?.length > 0) {
      return <Box sx={quantityCircle}>{mentorIdArray.length}</Box>;
    } else if (membersIdArray?.length > 0) {
      return <Box sx={quantityCircle}>{membersIdArray.length}</Box>;
    } else {
      return null;
    }
  };

  const handleCheckedFilter = (item: any) => {
    if (alignment === "Mentor") {
      if (
        mentorIdArray?.findIndex((i: any, ind: number) => i === item.id) === -1
      ) {
        return false;
      } else return true;
    } else if (alignment === "Learner") {
      if (
        programIdArray?.findIndex((i: any, ind: number) => i === item.id) === -1
      ) {
        return false;
      } else return true;
    } else if (alignment === "Other Member") {
      if (
        membersIdArray?.findIndex((i: any, ind: number) => i === item.id) === -1
      ) {
        return false;
      } else return true;
    }
  };

  const handleClearAll = () => {
    if (programIdArray?.length > 0) {
      return (
        <Box onClick={() => setProgramIdArray([])} sx={clearAll}>
          Clear All
        </Box>
      );
    } else if (mentorIdArray?.length > 0) {
      return (
        <Box sx={clearAll} onClick={() => setMentorIdArray([])}>
          Clear All
        </Box>
      );
    } else if (membersIdArray?.length > 0) {
      return (
        <Box sx={clearAll} onClick={() => setMembersIdArray([])}>
          Clear All
        </Box>
      );
    } else return null;
  };

  const getBackColor = (item: any) => {
    if (item.difficulty === "intermediate") {
      return "var(--intermediateBackground)";
    } else if (item.difficulty === "pro") {
      return "var(--themeOrange)";
    } else {
      return "var(--topPerformer)";
    }
  };

  return (
    <>
      {filteredPermission?.isView && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{
              height: "40px",
              position: "relative",
              width: "auto",
              padding: "11px 12px 11px 12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              background: "var(--lightGray)",
              borderRadius: "8px",
              alignItems: "center",
              transition: "all 0.3s",
              "&:hover": {
                backgroundColor: "var(--borderInput)",
                cursor: "pointer",
              },
              "&:focus": {
                backgroundColor: "var(--borderInput)",
                cursor: "pointer",
              },
            }}
          >
            <IconButton
              sx={{
                "&:hover": { backgroundColor: "var(--borderInput)" },
                "&:focus": { backgroundColor: "var(--borderInput)" },
                padding: "0",
              }}
              onClick={() => handleFilter()}
              aria-label="Example"
            >
              <img src={FilterIcon} alt="FilterIcon" />
            </IconButton>
            <Typography
              paddingLeft="8px"
              onClick={() => handleFilter()}
              variant="body1"
            >
              Filter
            </Typography>
            {filteredQuantity()}

            {open && (
              <Box
                sx={{
                  display: showFilter || open ? "flex" : "none",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "24px 16px",
                  gap: "16px",
                  position: "absolute",
                  width: "322px",
                  height: "276px",
                  background: "#FFFFFF",
                  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.13)",
                  borderRadius: "0px 0px 8px 8px",
                  zIndex: 100,
                  top: "60px",
                  left: "0",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontSize="16px"
                    fontWeight="500"
                    fontFamily="var(--fontFamiljen)"
                    variant="body1"
                    fontStyle="normal"
                    color="var(--trainingText)"
                    lineHeight="20px"
                  >
                    {alignment === "Other Member" ? "Roles" : "Trainings"}
                  </Typography>
                  <img
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{ cursor: "pointer" }}
                    src={FilterCrossIcon}
                    alt="FilterCrossIcon"
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    webkitAlignItems: "flex-start",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  {alignment === "Other Member"
                    ? rolesListData?.data?.data?.map(
                        (item: any, index: number) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                                margin: "0",
                              }}
                              key={item.id}
                            >
                              <Checkbox
                                checked={handleCheckedFilter(item)}
                                sx={{
                                  padding: "0",
                                  "&.Mui-checked": {
                                    color: "var(--themeOrange)",
                                  },
                                }}
                                onClick={() => handleCheckedBox(item)}
                                {...label}
                              />
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "var(--fontFamiljen)",
                                  fontStyle: "normal",
                                  color: "var(--legendColor)",
                                }}
                                variant="body1"
                              >
                                {item.name}
                              </Typography>
                            </Box>
                          );
                        }
                      )
                    : programsList?.data?.programs?.map(
                        (item: any, index: number) => {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "8px",
                                alignItems: "start",
                                margin: "0",
                              }}
                              key={item.id}
                            >
                              <Checkbox
                                checked={handleCheckedFilter(item)}
                                sx={{
                                  padding: "0",
                                  "&.Mui-checked": {
                                    color: "var(--themeOrange)",
                                  },
                                }}
                                onClick={() => handleCheckedBox(item)}
                                {...label}
                              />
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="start"
                              >
                                <Box
                                  height="20px"
                                  width="max-content"
                                  padding="4px 10px"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  textTransform="capitalize"
                                  borderRadius="4px"
                                  fontSize="12px"
                                  fontFamily="var(--fontFamiljen)"
                                  fontStyle="normal"
                                  fontWeight="400"
                                  bgcolor={() => getBackColor(item)}
                                  border="1px solid var(--black)"
                                >
                                  {item.difficulty}
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    fontFamily: "var(--fontFamiljen)",
                                    fontStyle: "normal",
                                    color: "var(--legendColor)",
                                  }}
                                  variant="body1"
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                </Box>
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      )}
      {handleClearAll()}
    </>
  );
};

export default FilterBox;
