// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { collection, getFirestore } from 'firebase/firestore'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { webApiRoot } from '../global'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL as string,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
  appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app)

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app)

export const getCMToken = async () => {
  let currentToken: string = ''
  try {
    currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error)
  }

  return currentToken
}

export const onMessageListener = (setNotification: any) =>
  // new Promise(resolve => {
  onMessage(messaging, (payload: any) => {
    if (payload) {
      setNotification(payload)
    }
    // resolve(payload)
  })
// })

export const requestNotification = async (authToken: string) => {
  if (Notification.permission === 'default') {
    Notification.requestPermission().then(async (permission: NotificationPermission) => {
      if (permission === 'granted') {
        location.reload()
        const token = await getCMToken()
        await fetch(`${webApiRoot}update-profile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            AuthorizationToken: authToken,
          },
          body: JSON.stringify({ web_fcm_token: token }),
        })
      }
    })
  } else if (Notification.permission === 'granted') {
    const token = await getCMToken()
    await fetch(`${webApiRoot}update-profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        AuthorizationToken: authToken,
      },
      body: JSON.stringify({ web_fcm_token: token }),
    })
  }
}

export const chatRef = collection(db, 'chats')
export const notificationRef = collection(db, 'notifications')
export default app
