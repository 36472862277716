import { BaseQueryFn, FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { storeAuthToken, storeLoginToken } from './redux/slices/authSlice'

export const apiRoot = process.env.REACT_APP_API_ROOT
export const webApiRoot = process.env.REACT_APP_WEB_API_ROOT
export const websiteApiRoot = process.env.REACT_APP_WEBSITE_API_ROOT
export const pistonApiRoot = process.env.REACT_APP_PISTON_API_ROOT

export function doFetch(url: string, config: any, token: string | null = null) {
  const { body, headers, method } = config ?? {}

  const payload = {
    method: method ?? 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { AuthorizationToken: `${token}` } : {}),
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  }

  return fetch(`${webApiRoot}${url}`, payload) // .then(res => res.json())
}

export function FETCH(
  method: string,
  url: string,
  token: string | null,
  body: any = null,
  headers: any = {},
  isFullUrl: boolean = false,
) {
  return fetch(`${webApiRoot}${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { AuthorizationToken: `${token}` } : {}),
      ...headers,
    },
    ...(body ? { body: JSON.stringify(body) } : {}),
  }).then(res => res.json())
}

export const baseQuery = fetchBaseQuery({
  baseUrl: apiRoot,
  prepareHeaders: (headers, { getState }:any) => {
    const token = getState().authSlice.userOtpToken
    if (token) {
      headers.set('AuthorizationToken', token)
    }
    return headers
  },
})

export const webBaseQuery = fetchBaseQuery({
  baseUrl: webApiRoot,
  prepareHeaders: (headers, { getState }:any) => {
    const token = getState()?.authSlice?.token

    if (token) {
      headers?.set('AuthorizationToken', token)
    }

    return headers
  },
})

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result: any = await webBaseQuery(args, api, extraOptions)
  const { getState }:any = api
  const refreshToken = getState()?.authSlice?.refreshToken

  if (result?.error?.data) {
    const responseCode: any = result.error.data
    if (responseCode.code === 401) {
      let res = await FETCH('POST', 'refresh-token', null, { refresh_token: refreshToken })
      api.dispatch(storeLoginToken(res?.data?.token))
      api.dispatch(storeAuthToken({ ...res?.data }))

      result = await webBaseQuery(args, api, extraOptions)
    }
  }

  return result
}

export const baseQueryWithRetryAndReAuth = retry(baseQueryWithReauth, {
  maxRetries: 0,
})
