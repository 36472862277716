export function baseTextStyle(
  family: string,
  weight: number,
  size: number | string,
  color?: string,
) {
  return {
    fontFamily: family,
    fontWeight: weight,
    fontSize: typeof size === 'number' ? `${size}px` : size,
    ...(color ? { color } : {}),
  }
}
