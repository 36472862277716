import { Box, Typography } from "@mui/material";
import { NoModulesDiv } from "../../../Style/style";
import Buttons from "../../../components/reusables/Buttons";
import AddIcon from "@mui/icons-material/Add";

const NoModulesFound = (props: any) => {
  const { onClick, heading, paragraph, btnName, hideBtn, rolesProp, imgSrc } =
    props;
  return (
    <NoModulesDiv>
      <Box
        sx={{
          width: "6rem",
          height: "6rem",
          borderRadius: "50%",
          backgroundColor: "var(--themeOrangeLight)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "5rem",
            height: "5rem",
            borderRadius: "50%",
            backgroundColor: "var(--themeOrangeLight)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={imgSrc} alt="program" width={40} height={40}/>
        </Box>
      </Box>
      {props.msg ? (
        <Typography
          sx={{
            margin: "1rem",
            display: rolesProp ? "none" : "block",
            fontSize: "1rem !important",
          }}
        >
          {props.description}
        </Typography>
      ) : (
        <Typography
          sx={{
            margin: "1rem",
            display: rolesProp ? "none" : "block",
            fontSize: "1rem !important",
          }}
        >
          {heading}
        </Typography>
      )}

      <Typography marginTop={rolesProp ? "24px" : "0"}>{paragraph}</Typography>
      {hideBtn ? null : (
        <Buttons
          type={btnName}
          name={btnName ? btnName : "Add Modules"}
          endIcon={rolesProp ? <></> : <AddIcon />}
          variant={rolesProp ? "text" : "contained"}
          color="primary"
          size="small"
          fullWidth={false}
          sx={{
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            textTransform: "capitalize",
          }}
          className={rolesProp ? "" : "BtnHover"}
          onClick={onClick}
        />
      )}
    </NoModulesDiv>
  );
};

export default NoModulesFound;
