import { Fragment, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  useCancelScheduleMutation,
  useGetUpcomingClassesQuery,
} from "../../redux/api/webLMS.api";
import ScheduleCancelAlert from "../Schedule/ScheduleCancelAlert";
import LearnerScheduleCard from "./LearnerScheduleCard";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { openAlert } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./learnerSchedule.module.css";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import NoModulesFound from "../LMS/modules/NoModulesFound";
import Delete from "../../assets/icons/delete-program.svg";
import Classess from "../../assets/icons/classess.svg";
import { extractTime } from "../../utils/formatDate";
import PaginationTable from "../../components/reusables/Pagination";
import { limit, meetingType } from "../../components/utils/constants";
import { scrollToTopFunction } from "../../redux/slices/MainSidebarSlice";

const UpcomingSchedule = () => {
  // const { showButton } = props;
  const upcomingClassRef = useRef(null);
  const [confirmBox, setConfirmBox] = useState(false);
  const [meetingId, setMeetingId] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [scheduleCancel] = useCancelScheduleMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reqClassesList = {
    url: `get-scheduled-call?filter=upcoming`,
    limit: limit,
    page: currentPage
    // body: {
    //   limit: 10,
    //   page: 1,
    // },
  };
  const { data, isLoading, isFetching } = useGetUpcomingClassesQuery(reqClassesList);

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const classData = data?.data?.docs;
  const onClickDelete = (items: any) => {
    setMeetingId(items._id);
    setConfirmBox(true);
  };
  const handleJoinClass = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const onClickEdit = (item: any) => {
    navigate("/learner_schedules/schedule_classes", { state: item });
  };

  const handleCancelMeeting = async () => {
    setSubmiting(true);
    const reqObj = {
      body: {
        meeting_id: meetingId,
      },
    };
    try {
      const resp: any = await scheduleCancel(reqObj).unwrap();
      if (resp.code === 4064) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setConfirmBox(false);
        setSubmiting(false);
      } else if (resp.code === 4061 || resp.code === 4072) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "warning",
          })
        );
        setConfirmBox(false);
        setSubmiting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalMeetings = data?.data?.docs?.reduce((acc: any, doc: any) => acc + (doc?.meetings?.length || 0), 0);

  useEffect(() => {
    dispatch(scrollToTopFunction(!triggerScroll));
  }, [currentPage])

  return (
    <Box ref={upcomingClassRef}>
      {isLoading || isFetching
        ? Array.from({ length: 6 }).map((_, index) => {
          const uniqueKey = `skeleton_${index}`;
          return (
            <Fragment key={uniqueKey}>
              <SkeletonTable
                sx={{
                  width: "100%",
                  height: "7rem",
                  margin: "0.5rem 0rem",
                }}
              />
            </Fragment>
          );
        })
        : classData?.map((items: any) => {
          const dateString = items.date;
          const dateObject = parseISO(dateString);
          const formattedDate = format(dateObject, "dd MMM yyyy");
          return (
            <>
              <Typography className={styles.dateSlots}>
                {formattedDate}
              </Typography>
              {items?.meetings?.map((item: any) => {
                return (
                  <>
                    <LearnerScheduleCard
                      key={item?._id}
                      agenda={item?.title}
                      time={`${extractTime(item?.start_date)} - ${extractTime(item?.end_date)}`}
                      mentor="Mentor"
                      name={`${item?.mentor?.first_name} ${item?.mentor?.last_name}`}
                      meetingType={item?.meeting_type === meetingType ? "Live" : "Doubt"}
                      showJoinButton={true}
                      showButton={item?.meeting_type === meetingType ? false : true}
                      onClickDelete={() => onClickDelete(item)}
                      onClickEdit={() => onClickEdit(item)}
                      handleJoinClasses={() =>
                        handleJoinClass(item.meeting_link)
                      }
                      slotData={item}
                    />
                  </>
                );
              })}
            </>
          );
        })}

      {data?.data?.docs.length < 1 ? (
        <NoModulesFound
          hideBtn="hideBtn"
          msg={true}
          description={
            <>
              It appears that you haven't scheduled any classes yet <br />{" "}
              Please take a moment to schedule your class now
            </>
          }
          imgSrc={Classess}
        />
      ) : null}

      {totalMeetings < 10 && currentPage === 1 ? null :
        <PaginationTable
          currentPage={currentPage}
          disableNextBtn={totalMeetings < 10}
          handleNextBtn={() => setCurrentPage(prev => prev + 1)}
          handlePreviousBtn={() => setCurrentPage(prev => prev - 1)}
        />
      }

      <ScheduleCancelAlert
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        nameBtn1="No, Cancel"
        nameBtn2="Yes, Delete"
        title="Delete scheduled class?"
        message="Are you sure you want to delete this class?"
        onClick={handleCancelMeeting}
        submiting={submiting}
        imgSrc={Delete}
      />
    </Box>
  );
};

export default UpcomingSchedule;
