import { Drawer } from '@mui/material'

export default function RightDrawer(props: any): JSX.Element {
  const { children, open } = props

  return (
    <Drawer anchor='right' open={open} sx={props.sx}>
      {children}
    </Drawer>
  )
}
