import { Box, ButtonBase, Typography } from "@mui/material";
import ScheduleCard from "./ScheduleCard";
import { useGetUpcomingClassesQuery } from "../../redux/api/webLMS.api";
import { format, parseISO } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import styles from "./schedule.module.css";
import NoModulesFound from "../LMS/modules/NoModulesFound";
import Hinstory from "../../assets/icons/history.svg"
import { limit, meetingType } from "../../components/utils/constants";
import { Orange } from "../../Style/colors";
import { extractTime } from "../../utils/formatDate";
import PaginationTable from "../../components/reusables/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTopFunction } from "../../redux/slices/MainSidebarSlice";

const History = ({ showButton }: { showButton: boolean }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useDispatch();

  const reqClassesList = {
    url: `get-scheduled-call?filter=history`,
    limit: limit,
    page: currentPage
    // body: {
    //   limit: 10,
    //   page: 1,
    // },
  };
  const { data, isLoading, isFetching } = useGetUpcomingClassesQuery(reqClassesList);

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const totalMeetings = data?.data?.docs?.reduce((acc: any, doc: any) => acc + (doc?.meetings?.length || 0), 0);

  useEffect(() => {
    dispatch(scrollToTopFunction(!triggerScroll));
  }, [currentPage])

  return (
    <>
      <Box marginBottom={4}>
        {isLoading || isFetching
          ? Array.from({ length: 6 }).map((_, index) => {
            const uniqueKey = `skeleton_${index}`;
            return (
              <Fragment key={uniqueKey}>
                <SkeletonTable
                  sx={{
                    width: "100%",
                    height: "7rem",
                    margin: "0.5rem 0rem",
                  }}
                />
              </Fragment>
            );
          })
          : data?.data?.docs?.map((items: any) => {
            const dateString = items.date;
            const dateObject = parseISO(dateString);
            const formattedDate = format(dateObject, "dd MMM yyyy");
            return (
              <>
                <Typography className={styles.mentorDate}>
                  {formattedDate}
                </Typography>
                {items?.meetings?.map((item: any) => {
                  return (
                    <>
                      <ScheduleCard
                        key={item._id}
                        secheduleHeading={
                          item?.title?.length >= 100
                            ? item?.title?.slice(0, 100) + "..."
                            : item?.title
                        }
                        scheduleTime={`${extractTime(item?.start_date)} - ${extractTime(item?.end_date)}`}
                        scheduleStudent="Student"
                        meetingType={item?.meeting_type === meetingType ? "Live" : "Doubt"}
                        scheduleStudentName={`${item?.student?.first_name} ${item?.student?.last_name}`}
                        showButton={showButton}
                        slotData={item}
                      />
                    </>
                  );
                })}
              </>
            );
          })}

        {data?.data?.docs.length < 1 ? (
          <NoModulesFound heading="No data available at the moment" hideBtn="hideBtn" imgSrc={Hinstory} />
        ) : null}

        {!data?.data?.docs.length && currentPage === 1 ? null :
          <PaginationTable
            currentPage={currentPage}
            disableNextBtn={totalMeetings < 10}
            handleNextBtn={() => setCurrentPage(prev => prev + 1)}
            handlePreviousBtn={() => setCurrentPage(prev => prev - 1)}
          />
        }

      </Box>
    </>
  );
};

export default History;
