import { createSlice, Slice } from "@reduxjs/toolkit";

const initialState: any = {
  programDetails: null,
  allBatchesDetails: [],
  programName: null,
  programIdDetails:{}
};

const batchesSlice: Slice = createSlice({
  name: "batchReducer",
  initialState,
  reducers: {
    storeManuallyProgramDetail: (state, action) => {
      state.programDetails = action.payload;
    },
    storeBatchesDetail: (state, action) => {
      state.allBatchesDetails = action.payload;
    },
    storeProgramBatchName: (state, action) => {
      state.programName = action.payload;
    },
    storeProgramIdDetails: (state, action) => {
      state.programIdDetails = action.payload;
    },
  },
});

export const { storeManuallyProgramDetail, storeBatchesDetail,storeProgramBatchName,storeProgramIdDetails } =
  batchesSlice.actions;
export default batchesSlice.reducer;
