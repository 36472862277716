import {
  useState,
  Fragment,
  useEffect,
} from "react";
import Heading from "../Dashboard/Heading";
import RectangularCheckBox from "./RectangularCheckBox";
import Buttons from "../../components/reusables/Buttons";
import styles from "./schedule.module.css";
import {
  useDaySlotsQuery,
  useUpdateMentorAvailableSlotsMutation,
} from "../../redux/api/webLMS.api";
import { useFormik } from "formik";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Breadcrumbs,
} from "@mui/material";
import { openAlert } from "../../redux/slices/snackbarSlice";
import { useDispatch } from "react-redux";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { baseTextStyle } from "../../Style/base";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { Gray, Shades } from "../../Style/colors";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const initialValues = {
  slots: [
    {
      day: "",
      slot: "",
    },
  ],
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0, py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  textTransform: "capitalize",
};

const ScheduleAvailability = () => {
  const [value, setValue] = useState(0);
  const [daysId, setDaysId] = useState<string>("");
  const [slotsId, setSlotsId] = useState<string[]>([]);
  const [allDaysAndSlots, setAllDaysAndSlots] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bookSlots] = useUpdateMentorAvailableSlotsMutation();

  const reqSlotsList = {
    url: "day-slot-list",
  };

  const { data, isLoading, isFetching } = useDaySlotsQuery(reqSlotsList);
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const mondayId = "63ca75d546519cce3ce3455e";
  const onGetDaysID = (dayId: string) => {
    setDaysId(dayId);
  };

  const onGetSlotsId = (event: any, items: any) => {
    const value: string = items?.id;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSlotsId((prev: any[]) => {
        return [
          ...prev,
          {
            day: daysId,
            slot: value,
          },
        ];
      });
    } else {
      setSlotsId((prevValues: any[]) => {
        const filteredValue = prevValues.filter(
          (v: any) => !(v.day === daysId && v.slot === value)
        );
        return filteredValue;
      });
    }
  };

  const handleBookSlots = async () => {
    const reqObject = {
      url: "mentor-slots",
      body: {
        slots: slotsId,
      },
    };
    setIsSubmit(true);
    try {
      const resp: any = await bookSlots(reqObject).unwrap();
      if (resp.code === 4010) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        navigate("/schedules");
        setIsSubmit(false);
      } else if (resp.code === 3001) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleBookSlots,
  });

  const { handleSubmit } = formik;

  useEffect(() => {
    if (!isLoading && Array.isArray(data?.data?.days)) {
      setDaysId(data?.data?.days[0].id);
      setSlotsId(data?.data?.selected_slots);
      setAllDaysAndSlots(createSlotByDay(data?.data?.days, data?.data?.slots));
    }
  }, [isLoading]);

  const createSlotByDay = (days: any[], slots: any[]) => {
    let newDaysSlots = {};
    for (const day of days) {
      newDaysSlots = {
        ...newDaysSlots,
        [day.id]: {
          dayName: day.name,
          dayId: day.id,
          daySlot: slots,
        },
      };
    }
    return newDaysSlots;
  };

  const getChecked = (slotId: string, dayId: string) => {
    const isChecked = slotsId.find(
      (item: any) => item.slot === slotId && item.day === dayId
    );
    return Boolean(isChecked);
  };

  function markSlotsForAllDays(event: any) {
    let selectedSlots = slotsId
      ?.filter((s: any) => daysId === s?.day)
      ?.map((s: any) => s?.slot);
    let tempArr: any = [];
    if (event.target.checked) {
      for (let i = 0; i < data?.data?.days?.length; i++)
        for (let j = 0; j < selectedSlots?.length; j++)
          // @ts-ignore
          tempArr.push({
            day: data?.data?.days[i]?.id,
            slot: selectedSlots[j],
          });
      setSlotsId(tempArr);
    } else {
      const slotsForSelectedDay = slotsId.filter(
        (slot: any) => slot.day === daysId
      );
      setSlotsId(slotsForSelectedDay);
    }
  }

  const handleNavigateBreadCrumbs = () => {
    navigate("/schedules");
  };

  return (
    <>
      <Box sx={{ py: "16px" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography
            sx={{
              ...baseTextStyle(
                FONT_FAMILY.BODY,
                FONT_WEIGHT.MEDIUM,
                "14px",
                Shades["101"]
              ),
              cursor: "pointer",
            }}
            onClick={handleNavigateBreadCrumbs}
          >
            My Classes
          </Typography>
          <Typography
            sx={baseTextStyle(
              FONT_FAMILY.BODY,
              FONT_WEIGHT.MEDIUM,
              "14px",
              Gray["200"]
            )}
          >
            Availabilty Setting
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box className={styles.slotMainBox}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Box className={styles.slotSubBox}>
            {isLoading ? (
              <SkeletonTable
                sx={{ width: "100%", height: "4rem", marginTop: "2rem" }}
              />
            ) : (
              <Box className={styles.slotsHeadingBox}>
                <Heading
                  headingValue="Let us know your availability"
                  className={styles.slotsHeadings}
                />
                <Typography className={styles.subSlotHeading}>
                  Add available time slots for teaching hours
                </Typography>
              </Box>
            )}

            {isFetching ? (
              <SkeletonTable
                sx={{ width: "100%", height: "4rem", marginTop: "1rem" }}
              />
            ) : (
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="upcoming schedules"
                  sx={{
                    ".MuiTabs-flexContainer": {
                      display: "grid",
                      gridTemplateColumns: "repeat(7,1fr)",
                      justifyContent: "space-between",
                      backgroundColor: "#F5F4F4",
                      width: "100%",
                    },
                    ".Mui-selected": {
                      backgroundColor: "#ECEAEA!important",
                      color: "#333!important",
                    },
                  }}
                >
                  {Object.keys(allDaysAndSlots)?.map(
                    (dayId: any, index: any) => {
                      return (
                        <Tab
                          key={dayId}
                          label={allDaysAndSlots[dayId].dayName}
                          {...a11yProps(index)}
                          sx={{ ...tabStyle }}
                          onClick={() => onGetDaysID(dayId)}
                        />
                      );
                    }
                  )}
                </Tabs>
              </Box>
            )}

            {isFetching ? (
              <SkeletonTable
                sx={{ width: "100%", height: "20rem", margin: "1rem 0rem" }}
              />
            ) : (
              Array.from({ length: 7 }).map((_, index) => {
                const uniqueKey = `skeleton_${index}`;
                return (
                  <Fragment key={uniqueKey}>
                    <CustomTabPanel value={value} index={index}>
                      <Box className={styles.slotsTimingList}>
                        {allDaysAndSlots[daysId]?.daySlot?.map((items: any) => {
                          return (
                            <>
                              <RectangularCheckBox
                                label={`${items?.start_time} - ${items?.end_time}`}
                                key={items.id}
                                onChange={(event: any) =>
                                  onGetSlotsId(event, items)
                                }
                                checked={getChecked(items.id, daysId)}
                              />
                            </>
                          );
                        })}
                      </Box>
                    </CustomTabPanel>
                  </Fragment>
                );
              })
            )}

            {isLoading ? (
              <SkeletonTable
                sx={{ width: "100%", height: "3rem", marginTop: "1rem" }}
              />
            ) : (
              <Box className={styles.bookBtnBox}>
                <Buttons
                  name="Save"
                  variant="contained"
                  size="large"
                  className={styles.slotsBookBtn}
                  type="submit"
                  disabled={
                    isSubmit || daysId.length < 1 || slotsId.length < 1
                      ? true
                      : false
                  }
                />
                <FormGroup
                  sx={{ display: daysId === mondayId ? "block" : "none" }}
                >
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    label="Same for all days"
                    className={styles.checkSlots}
                    onChange={markSlotsForAllDays}
                  />
                </FormGroup>
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ScheduleAvailability;
