import { PropsWithChildren, createContext, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { doFetch } from '../global'
import { logoutAction, storeAuthToken, storeLoginToken } from '../redux/slices/authSlice'
import { resetLMS } from '../redux/slices/LMS.slice'
import { getEpoch } from '../utils/formatDate'

const NetworkContext = createContext<any>(null)

export default function NetworkProvider(props: PropsWithChildren): JSX.Element {
  const dispatch = useDispatch()
  const authToken = useSelector((state: any) => state?.authSlice?.token)
  const refreshToken = useSelector((state: any) => state?.authSlice?.refreshToken)
  const navigate = useNavigate()

  const query = useCallback(
    (url: string, options: any) =>
      (async () => {
        let res: any = await doFetch(url, options, authToken)

        if (res?.status === 401) {
          res = await doFetch('refresh-token', { method: 'POST', body: { refresh_token: refreshToken } }, null)
          res = await res?.json()

          if (res?.status) {
            dispatch(storeLoginToken(res?.data?.token));
            dispatch(storeAuthToken({ ...res?.data, iua: getEpoch() }));

            res = await doFetch(url, options, res?.data?.token)
            return await res?.json()
          } else {
            // NOTE: (tanmay-mazumdar) handle the failed login api, instead of recall the api
            navigate('/web-login')
            dispatch(logoutAction(''))
            dispatch(resetLMS(''))
            return
          }
        } else {
          return await res?.json()
        }
      })(),
    [authToken, refreshToken],
  )

  return <NetworkContext.Provider {...props} value={query} />
}

export function useFetch() {
  return useContext(NetworkContext)
}
