import { Box, Typography } from "@mui/material";
import styles from "./schedule.module.css";
import Buttons from "../../components/reusables/Buttons";
import { Orange } from "../../Style/colors";

interface ScheduleInterface {
  secheduleHeading: string;
  scheduleTime: string;
  scheduleStudent: string;
  scheduleStudentName: string;
  onJoinClass?: any;
  onCancelClass?: any;
  date?: string;
  showButton: boolean;
  slotData: any;
  meetingType: string;
}
const cancelBtnstyle = {
  backgroundColor: "#ffffff",
};
const ScheduleCard = (props: ScheduleInterface) => {
  const {
    secheduleHeading,
    scheduleTime,
    scheduleStudent,
    scheduleStudentName,
    onJoinClass,
    onCancelClass,
    showButton,
    slotData,
    meetingType
  } = props;

  return (
    <Box>
      <Box className={styles.scheduleMainBox}>
        <Box
          height="24px"
          padding="3px 8px 5px 8px"
          borderRadius="24px"
          border="1px solid var(--comparisionColor)"
          bgcolor="var(--themeOrangeLight)"
          fontSize="12px"
          fontWeight="400"
          fontFamily="var(--fontFamiljen)"
          width="fit-content"
          marginBottom="12px"
          color={Orange['600']}
          display="flex"
          alignItems="center"
        >
          {meetingType === "Doubt" ? "Doubt Session" :
            <Box display="flex" alignItems="center" gap="3px">
              <Box sx={{ bgcolor: Orange["600"], height: "5px", width: "5px", borderRadius: "50%" }} />
              <span>  Live Class</span>
            </Box>
          }
        </Box>
        <Typography
          className={styles.scheduleHeading}
          sx={{ wordBreak: "break-word" }}
        >
          Agenda : {secheduleHeading}
        </Typography>
        <Typography className={styles.scheduleTime}>{scheduleTime}</Typography>
        <Box className={styles.scheduleSubBox}>
          <Box>
            {meetingType === "Doubt" &&
              <>
                <Typography className={styles.scheduleStudent}>
                  {scheduleStudent}
                </Typography>
                <Typography className={styles.scheduleStudentName}>
                  {scheduleStudentName}
                </Typography>
              </>
            }
          </Box>
          {showButton ? (
            <Box className={styles.scheduleBtnBox}>
              <Buttons
                name="cancel"
                size="small"
                variant="contained"
                color="secondary"
                className={styles.scheduleBtnStyle}
                onClick={onCancelClass}
                sx={{ ...cancelBtnstyle }}
              />
              <Buttons
                name="join"
                size="small"
                variant="contained"
                color="primary"
                className={styles.scheduleBtnStyle}
                onClick={onJoinClass}
              />
            </Box>
          ) : (
            <Box className={styles.scheduleBtnBox}>
              {slotData?.is_cancelled ? (
                <Typography className={styles.scheduleBtnStyle}>
                  Cancelled
                </Typography>
              ) : (
                <Typography className={styles.cancelStatus}>
                  {slotData?.duration || ""}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleCard;
