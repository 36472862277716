import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  ProgramName:{},
};

const upcomingClassSlice = createSlice({
  name: "moduleSliceReducer",
  initialState,
  reducers: {
    storeClassScheduleValues: (state, action) => {
      state.ProgramName = action.payload;
    },
  },
});

export const { storeClassScheduleValues} = upcomingClassSlice.actions;
export default upcomingClassSlice.reducer;