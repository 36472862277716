import { useEffect, useState } from "react";
import { OtherMemberInterface } from "../../../Interface/Interface";
import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { customStyle } from "../../../Style/style";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage, FastField, Form, Formik } from "formik";
import { validationOtherMemberSchema } from "../../../Schema/Schema";
import InputCustom from "../../../components/reusables/InputCustom";
import ErrorText from "../../../components/reusables/ErrorText";
import Buttons from "../../../components/reusables/Buttons";
import {
  useAssignRolesMutation,
  useGetAllRolesAndPermissionListQuery,
} from "../../../redux/api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Theme, useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 212,
    },
  },
};

const selectStyleBox = {
  height: "56px",
  backgroundColor: "var(--lightGray)",
  border: "1px solid var(--borderInput)",
  fontFamily: "var(--fontFamiljen)",
  lineHeight: "20px",
  color: "var(--InputTextGray)",
  padding: "14px 6px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
};

const labelText = {
  fontFamily: "Familjen Grotesk",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#544F4F",
  marginBottom: "8px",
};

function getStyles(name: string, theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    height: "37px",
    fontSize: "14px",
    fontFamily: "var(--fontFamiljen)",
    color: "var(--activityText)",
  };
}

const OtherMember = ({
  onHide,
  updateList,
  setUpdateList,
  filteredSingleMember,
}: OtherMemberInterface) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [assignRole, setAssignRole] = useState<any>({
    id: "",
    name: filteredSingleMember?.role || "",
  });
  const [validateRole, setValidateRole] = useState<boolean>(false);

  const { data } = useGetAllRolesAndPermissionListQuery(null);
  const [assignRoles, { isLoading }] = useAssignRolesMutation();

  const initialValues = {
    name: filteredSingleMember?.name || "",
    email: filteredSingleMember?.email || "",
  };

  useEffect(() => {
    const filteredRole = data?.data?.data?.filter(
      (item: any) => item.name === filteredSingleMember?.role
    )[0];    
    if (filteredRole) {
      setAssignRole({
        id: filteredRole.id,
        name: filteredRole.name,
      });
    }
  }, [filteredSingleMember?.id]);  
  
  const handleSubmitForm = async (values: any) => {
    if (validateRole && assignRole?.name?.length === 0) {
      return;
    }
    if (filteredSingleMember?.id) {
      try {
        const reqObj: any = {
          url: "org-member",
          body: {
            name: values?.name,
            email: values?.email,
            member_id: filteredSingleMember?.id,
            role: assignRole?.id,
            new_member: false,
          },
        };

        const resp = await assignRoles(reqObj).unwrap();
        if (resp?.code === 3064) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setUpdateList(!updateList);
          onHide();
        }
        if (resp?.code === 3088) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "warning",
            })
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      try {
        const reqObj: any = {
          url: "org-member",
          body: {
            name: values?.name,
            email: values?.email,
            role: assignRole?.id,
            new_member: true,
          },
        };
        const resp = await assignRoles(reqObj).unwrap();
        if (resp?.code === 3074) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setUpdateList(!updateList);
          onHide();
        } else if (resp?.code === 3088) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "warning",
            })
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <Box sx={customStyle}>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography
          sx={{
            fontFamily: `var(--fontMulish)`,
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "800",
            color: "var(-black)",
            marginBottom: "1.5rem",
            lineHeight: "normal",
          }}
        >
          {filteredSingleMember?.id ? "Edit" : "Add"} Member
        </Typography>
        <CloseIcon
          onClick={() => {
            onHide();
          }}
          fontSize="medium"
          sx={{
            color: "var(--emailTextForAssign)",
            cursor: "pointer",
          }}
        />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationOtherMemberSchema}
        onSubmit={handleSubmitForm}
      >
        <Form>
          <Box
            display="flex"
            flexDirection="column"
            component="div"
            sx={{ my: 2 }}
          >
            <Box sx={{ marginBottom: "32px" }}>
              <FastField
                style={{
                  border: "1px solid var(--borderInput)",
                  background: "var(--lightGray)",
                  fontWeight: "400",
                }}
                placeholder="Enter name"
                type="text"
                emailAddress="Name"
                name="name"
                as={InputCustom}
              />
              <ErrorMessage name="name" component={ErrorText} />
            </Box>
            <Box sx={{ marginBottom: "32px" }}>
              <FastField
                style={{
                  border: "1px solid var(--borderInput)",
                  background: "var(--lightGray)",
                  fontWeight: "400",
                }}
                placeholder="Enter email address"
                type="email"
                disabled={filteredSingleMember?.email}
                emailAddress="Email address"
                name="email"
                as={InputCustom}
              />
              <ErrorMessage name="email" component={ErrorText} />
            </Box>
            <FormControl
              sx={{
                height: "40px",
                width: "100%",
                marginBottom: "103px",
              }}
            >
              <Typography sx={labelText}>Role</Typography>
              <Select
                IconComponent={ExpandMoreIcon}
                sx={selectStyleBox}
                displayEmpty
                id="programTypeName"
                value={assignRole}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected?.name === "") {
                    return <Box>Select Role</Box>;
                  }

                  return selected?.name;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem sx={{ display: "none" }} value=""></MenuItem>
                {data?.data?.data?.map((item: any, index: number) => (
                  <MenuItem
                    key={item.id}
                    onClick={() =>
                      setAssignRole({ id: item.id, name: item.name })
                    }
                    value={item.name}
                    style={getStyles(item.name, theme)}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {validateRole && assignRole?.name?.length === 0 && (
                <Box marginTop="3px" className="errorHandleMessage">
                  Role is required
                </Box>
              )}
            </FormControl>
            <Box
              component="div"
              gap="10px"
              display="flex"
              justifyContent="center"
              alignItems="end"
              sx={{
                marginBottom: "144px",
                width: "100%",
              }}
            >
              <Buttons
                type="button"
                onClick={() => onHide()}
                name="Cancel"
                variant="contained"
                size="large"
                fullWidth={true}
                sx={{
                  padding: "0.8rem",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  height: "56px",
                  backgroundColor: "var(--borderInput)",
                  boxShadow: "none",
                  color: "var(--legendColor)",
                }}
                className="BtnHoverDisable"
              />
              <Buttons
                type="submit"
                name={filteredSingleMember?.id ? "Save Changes" : "Add Member"}
                onClick={() => setValidateRole(true)}
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}
                fullWidth={true}
                sx={{
                  padding: "0.8rem",
                  borderRadius: "8px",
                  height: "56px",
                  fontWeight: "400",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                className="BtnHover"
              />
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default OtherMember;
