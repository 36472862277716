import { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";

import RightDrawer from "../RightDrawer";
import SendMessage from "./SendMessage";
import flexbox from "../../Style/flexbox";
import useUser from "../../hooks/useUser";
import { chatRef } from "../../config/firebase";
import { baseTextStyle } from "../../Style/base";
import { Gray, Orange, Shades } from "../../Style/colors";
import { FONT_FAMILY, FONT_WEIGHT, USER_TYPE } from "../../constants";

import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left-dark.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import styled from "@emotion/styled";

dayjs.extend(utc);
dayjs.extend(isToday);

const urlRegex =
  /((https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$)/gi;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--black)",
    fontSize: "12px",
    fontFamily: "var(--fontFamiljen)",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
}));

export default function ChatMessage(props: any) {
  const { onClosePanel, user } = props;  
  
  const loggedInUser = useUser();
  const scroll = useRef();
  const navigate = useNavigate();

  const [msgs, setMsgs] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const isMentor = loggedInUser?.user_type === USER_TYPE.MENTOR;
  const chatId = isMentor
    ? `${loggedInUser?.chat_uid}:${user?.chat_uid}`
    : `${user?.chat_uid}:${loggedInUser?.chat_uid}`;

  // @ts-ignore
  useEffect(() => {
    if (user) {
      const q = query(
        chatRef,
        orderBy("created_at", "desc"),
        where("chat_id", "==", chatId)
      );

      const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const fetchedMessages: any = [];
        QuerySnapshot.forEach((doc) => {
          fetchedMessages.push({ ...doc.data(), id: doc.id });
        });
        const sortedMessages = fetchedMessages.sort(
          (a: any, b: any) => a.created_at - b.created_at
        );
        setMsgs(sortedMessages);
        const gps = sortedMessages.reduce((a: any, c: any) => {
          const date = dayjs(c?.created_at).utc(true).format("MMM DD YYYY");
          if (!a[date]) {
            a[date] = [];
          }

          a[date].push(c);
          return a;
        }, {});
        setGroups(gps);
      });

      return () => unsubscribe;
    }
  }, [user]);

  useEffect(() => {
    // @ts-ignore
    scroll?.current?.scrollTo?.({ top: scroll?.current?.scrollHeight });
  }, [scroll, msgs]);  

  return (
    <RightDrawer
      {...props}
      open={Boolean(user)}
      sx={{
        top: "61px",
        "> .MuiBackdrop-root": { top: "61px", background: Gray["60"] },
        "> .MuiPaper-root": { boxShadow: "none", top: "61px" },
      }}
    >
      <Box sx={{ width: 416, mb: "61px", height: "calc(100% - 61px)" }}>
        <Box
          sx={{
            ...flexbox("row", "space-between", "start", null, "16px 24px"),
            borderBottom: `1px solid ${Gray["100"]}`,
          }}
        >
          <Box sx={{ ...flexbox("row", null, "center", "14px") }}>
            <Box sx={{ cursor: "pointer" }} onClick={() => onClosePanel?.()}>
              <ArrowLeftIcon />
            </Box>
            <Box sx={flexbox("row", "flex-start", "flex-start", "12px")}>
              <Box
                sx={{
                  minWidth: 56,
                  height: 56,
                  display: "grid",
                  placeItems: "center",
                  bgcolor: Orange["100"],
                  borderRadius: 1000,
                }}
              >
                <Typography
                  sx={baseTextStyle(
                    FONT_FAMILY.HEADING,
                    FONT_WEIGHT.EXTRA_BOLD,
                    "14px",
                    Orange["500"]
                  )}
                >
                  {user?.name
                    ?.split?.(" ")
                    ?.map((name: string) => name?.substring(0, 1))
                    ?.join("")}
                </Typography>
              </Box>

              <Box
                sx={{
                  ...flexbox("column", null, "flex-start", "2px"),
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    ...baseTextStyle(
                      FONT_FAMILY.BODY,
                      FONT_WEIGHT.MEDIUM,
                      "16px",
                      Shades["101"]
                    ),
                  }}
                >
                  {user?.name}
                </Typography>
                <Typography
                  sx={{
                    ...baseTextStyle(
                      FONT_FAMILY.BODY,
                      FONT_WEIGHT.MEDIUM,
                      "16px",
                      Gray["800"]
                    ),
                    ...flexbox("row", "center", "center", "18px"),
                  }}
                >
                  {user?.program?.length === 1 && user?.program?.[0]?.name}
                  {user?.program?.length > 1 && (
                    <BootstrapTooltip
                      title={user?.program
                        ?.map((p: any) => p?.name)
                        ?.join(", ")}
                      placement="top"
                    >
                      <Typography
                        sx={baseTextStyle(
                          FONT_FAMILY.BODY,
                          FONT_WEIGHT.NORMAL,
                          "14px",
                          Orange["600"]
                        )}
                      >
                        + {user?.program?.length - 1}{" "}
                        {user?.program?.length - 1 > 1
                          ? " programs"
                          : " program"}
                      </Typography>
                    </BootstrapTooltip>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          {!isMentor && (
            <Box
              sx={{
                cursor: "pointer",
                width: 32,
                minWidth: 32,
                height: 32,
                minHeight: 32,
              }}
              onClick={() => {
                navigate(isMentor ? "/schedules" : "/learner_schedules");
                onClosePanel?.(true);
              }}
            >
              <CalendarIcon />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            ...flexbox("column", null, "flex-start", "20px", "24px"),
            width: "100%",
            overflow: "auto",
            height: "calc(100% - 190px)",
          }}
          key={msgs?.length}
          ref={scroll}
        >
          {Object.keys(groups)?.map((group: any) => (
            <Fragment key={group}>
              <Divider
                key={group}
                sx={{
                  ...baseTextStyle(
                    FONT_FAMILY.BODY,
                    FONT_WEIGHT.NORMAL,
                    "12px",
                    "12px"
                  ),
                  color: "#928B8B",
                  width: "100%",
                }}
              >
                {dayjs(group).isToday() ? "Today" : group}
              </Divider>

              {groups[group]?.map((item: any) => (
                <Box
                  key={item?.created_at}
                  sx={{
                    ...flexbox(
                      "column",
                      "flex-start",
                      item?.sender?.id === loggedInUser?.id
                        ? "flex-end"
                        : "flex-start",
                      "2px"
                    ),
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "4px",
                      bgcolor:
                        item?.sender?.id === loggedInUser?.id
                          ? "#FDF1EC"
                          : "#F5F4F4",
                      p: "16px",
                      width: 346,
                    }}
                  >
                    <Typography
                      sx={{
                        ...baseTextStyle(
                          FONT_FAMILY.BODY,
                          FONT_WEIGHT.NORMAL,
                          "16px",
                          Shades["101"]
                        ),
                        wordBreak: "break-word",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.content.replace(
                          urlRegex,
                          '<a href="$1" target="_blank" rel="noopener">$1</a>'
                        ),
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      ml: "auto",
                      ...(item?.sender?.id === loggedInUser?.id
                        ? {}
                        : { mr: "23px" }),
                      fontSize: "12px",
                    }}
                  >
                    {dayjs(item?.created_at).utc(true).format("LT")}
                  </Box>
                </Box>
              ))}
            </Fragment>
          ))}
        </Box>

        <SendMessage
          chatId={chatId}
          isMentor={isMentor}
          loggedInUser={loggedInUser}
          scroll={scroll}
          user={user}
        />
      </Box>
    </RightDrawer>
  );
}
