import { FETCH } from "../../global";

export default {
  onboardUser: (token: string) => FETCH("GET", "onboarding", token),

  loginUser: (token: string) => FETCH("GET", "login", token),

  renewToken: (refreshToken: string) =>
    FETCH("POST", "refresh-token", null, { refresh_token: refreshToken }),

  getDaySlots: (token: string) => FETCH("GET", "day-slot-list", token),

  updateMentorProfile: (token: string, payload: any) =>
    FETCH("POST", "update-profile", token, payload),

  updateMentorAvailableSlots: (token: string, payload: any) =>
    FETCH("PUT", "mentor-slots", token, payload),

  confirmOnboard: (token: string) => FETCH("POST", "complete-onboarding", token),
};
