import { customStyle } from "../../../Style/style";
import {
  AllowedPermissionsInterface,
  LearnerDetailDrawerInterface,
} from "../../../Interface/Interface";
import { Fragment } from "react";
import { Box, Drawer, Button, Typography } from "@mui/material";
import { EditIconLearner } from "../../../assets/logos/logos";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import SkeletonTable from "../../../components/reusables/SkeletonTable";

const assignBtn = {
  fontFamily: `var(--fontFamiljen)`,
  fontStyle: "normal",
  border: "1px solid var(--themeOrange)",
  fontSize: "14px",
  lineHeight: "18px",
  height: "48px",
  textTransform: "capitalize",
  width: "114px",
  color: "var(--black)",
  fontWeight: "400",
  "&:hover": {
    backgroundColor: "var(--themeOrange)",
    color: "#fff",
  },
  "&:focus": {
    backgroundColor: "var(--themeOrange)",
    color: "#fff",
  },
};

const LearnerDetailDrawer = ({
  learnerDetailDrawer,
  onHide,
  setOpenLearnerDrawer,
  singleLearnerDetail,
  singleLearnerLoading,
}: LearnerDetailDrawerInterface) => {
  const allowedPermissions = useSelector(
    (state: any) => state?.authSlice?.permissions
  );

  const filteredPermission = allowedPermissions?.filter(
    (item: AllowedPermissionsInterface) => item?.name === "User Management"
  )[0];

  const handleEditIconClick = () => {
    if (!filteredPermission?.isEdit) {
      setOpenLearnerDrawer(false);
    } else {
      setOpenLearnerDrawer(true);
      onHide();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={learnerDetailDrawer}
        onClose={() => onHide()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={{ padding: "0", width: "512px" }} sx={customStyle}>
          {singleLearnerLoading ? (
            Array.from({ length: 8 }).map((_, index) => {
              const uniqueKey = `skeleton_${index}`;
              return (
                <Fragment key={uniqueKey}>
                  <SkeletonTable
                    sx={{
                      width: "98%",
                      height: "5rem",
                      margin: "0.5rem auto",
                    }}
                  />
                </Fragment>
              );
            })
          ) : (
            <>
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                padding="2rem"
                paddingBottom="0"
              >
                <Typography
                  sx={{
                    fontFamily: `var(--fontMulish)`,
                    fontSize: "24px",
                    fontWeight: "var(--font800)",
                  }}
                >
                  Learner detail
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap="30px"
                >
                  <img
                    onClick={() => handleEditIconClick()}
                    style={{
                      cursor: !filteredPermission?.isEdit
                        ? "not-allowed"
                        : "pointer",
                    }}
                    src={EditIconLearner}
                    alt="EditIconLearner"
                  />
                  <CloseIcon
                    onClick={() => {
                      onHide();
                    }}
                    fontSize="medium"
                    sx={{
                      color: "var(--emailTextForAssign)",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
              <Box marginTop="41px" padding="0 2rem">
                <Typography
                  fontFamily="var(--fontFamiljen)"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="20px"
                  color="var(--trainingText)"
                >
                  Name
                </Typography>
                <Typography
                  fontFamily="var(--fontFamiljen)"
                  fontStyle="normal"
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="20px"
                  color="var(--black)"
                  marginTop="8px"
                >
                  {singleLearnerDetail?.first_name +
                    " " +
                    singleLearnerDetail?.last_name}
                </Typography>
                <Typography
                  fontFamily="var(--fontFamiljen)"
                  fontStyle="normal"
                  fontWeight="400"
                  fontSize="12px"
                  lineHeight="15px"
                  color="var(--emailTextColor)"
                  marginTop="4px"
                >
                  {singleLearnerDetail?.email}
                </Typography>
              </Box>
              <Box marginTop="34px" marginBottom="24px" padding="0 2rem">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    fontFamily="var(--fontFamiljen)"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20px"
                    color="var(--legendColor)"
                    margin="0"
                  >
                    Assigned programs
                  </Typography>
                </Box>
              </Box>
              {singleLearnerDetail?.programs?.map(
                (item: any, index: number) => {
                  return (
                    <>
                      <Box
                        key={item}
                        padding="0 2rem"
                        marginBottom={item?.program?.length > 60 ? "20px" : "0"}
                      >
                        <Box
                          height="98px"
                          display="flex"
                          justifyContent="space-between"
                          gap="20px"
                          alignItems="center"
                        >
                          <Box display="flex" flexDirection="column" gap="12px">
                            <Box display="flex" gap="8px" alignItems="start">
                              <Box
                                height="24px"
                                width="fit-content"
                                padding="0 10px"
                                fontFamily="var(--fontFamiljen)"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="12px"
                                border="1px solid var(--black)"
                                borderRadius="4px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bgcolor="var(--intermediateBackground)"
                                lineHeight="15px"
                                color="var(--percentageTextColor)"
                              >
                                {item?.difficulty}
                              </Box>
                              <Box
                                minHeight="24px"
                                height="auto"
                                width="fit-content"
                                padding="3px 8px"
                                fontFamily="var(--fontFamiljen)"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="12px"
                                borderRadius="4px"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bgcolor="var(--themeOrangeLight)"
                                lineHeight="15px"
                                color="var(--percentageTextColor)"
                              >
                                {item?.batch?.name}
                              </Box>
                            </Box>
                            <Typography
                              fontFamily="var(--fontFamiljen)"
                              fontStyle="normal"
                              fontWeight="400"
                              width="210px"
                              fontSize="14px"
                              lineHeight="18px"
                              color="var(--black)"
                            >
                              {item?.program?.length > 30 ? (
                                <Box component="span">
                                  {item?.program?.substring(0, 30)}
                                  <br />
                                  {item?.program?.substring(30, 60)}
                                  <br />
                                  {item?.program?.substring(
                                    60,
                                    item?.program?.length
                                  )}
                                </Box>
                              ) : (
                                item?.program
                              )}
                            </Typography>
                          </Box>
                          <Box
                            marginTop="23px"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography
                              fontFamily="var(--fontFamiljen)"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="12px"
                              lineHeight="15px"
                              color="var(--emailTextForAssign)"
                            >
                              Mentor
                            </Typography>
                            <Typography
                              fontFamily="var(--fontFamiljen)"
                              fontStyle="normal"
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="18px"
                              color="var(--black)"
                            >
                              {item?.mentor}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <hr
                        style={{ borderTop: "1px solid var(--borderInput)" }}
                      />
                    </>
                  );
                }
              )}
              <Box
                padding="0 2rem"
                paddingBottom="50px"
                marginTop="15px"
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  onClick={() => {
                    setOpenLearnerDrawer(true);
                    onHide();
                  }}
                  sx={assignBtn}
                  disabled={!filteredPermission?.isEdit ? true : false}
                  variant="outlined"
                >
                  Assign
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default LearnerDetailDrawer;
