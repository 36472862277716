import { useState } from 'react'
import { Box, ButtonBase, Typography } from '@mui/material'
import { addDoc } from 'firebase/firestore'

import flexbox from '../../Style/flexbox'
import { chatRef } from '../../config/firebase'
import { baseTextStyle } from '../../Style/base'
import { Orange, Shades } from '../../Style/colors'
import { FONT_FAMILY, FONT_WEIGHT } from '../../constants'

export default function SendMessage(props: any) {
  const { isMentor, loggedInUser, user } = props
  const [message, setMessage] = useState('')
  const [shift, setShiftKey] = useState<boolean>(false)

  async function sendMessage(event: any) {
    event.preventDefault()
    if (message.trim() === '') {
      return
    }

    const chatId = isMentor
      ? `${loggedInUser?.chat_uid}:${user?.chat_uid}`
      : `${user?.chat_uid}:${loggedInUser?.chat_uid}`

    await addDoc(chatRef, {
      chat_id: chatId,
      content: message?.trim(),
      sender: {
        id: loggedInUser?.id,
        name: `${loggedInUser?.first_name} ${loggedInUser?.last_name}`,
      },
      receiver: {
        id: user?.chat_uid,
        name: user?.name,
      },
      attachment: [],
      created_at: new Date().getTime(),
      is_read: false,
      receiver_id: user?.chat_uid,
    })
    setMessage('')
    // scroll?.current?.scrollTo?.(0, scroll?.current?.scrollHeight)
  }

  return (
    <Box sx={{ position: 'absolute', bottom: 61, width: '100%' }}>
      <Box
        sx={{
          m: '0 24px 31px',
          borderRadius: '8px',
          minHeight: 56,
          bgcolor: '#F5F4F4',
          ...flexbox('row', null, 'center', '4px', '4px 4px 4px 8px'),
        }}
      >
        <textarea
          style={{
            background: 'transparent',
            border: 'none',
            outline: 'none',
            width: '100%',
            wordBreak: 'break-word',
            resize: 'none',
            height: '100%',
            maxHeight: 100,
            ...baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.NORMAL, '14px'),
          }}
          value={message}
          placeholder='Type here a new update'
          onChange={(e: any) => setMessage(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.keyCode === 16) {
              setShiftKey(true)
            }
          }}
          onKeyUp={(e: any) => {
            if (e.keyCode === 13 && !shift) {
              sendMessage(e)
            }

            if (e.keyCode === 13 && shift) {
              setShiftKey(false)
            }
          }}
        />
        <ButtonBase
          sx={{
            bgcolor: Shades['0'],
            height: '100%',
            width: 69,
            p: '10px',
            borderRadius: '7px',
          }}
          onClick={sendMessage}
        >
          <Typography sx={baseTextStyle(FONT_FAMILY.BODY, FONT_WEIGHT.NORMAL, '14px', Orange['600'])}>Send</Typography>
        </ButtonBase>
      </Box>
    </Box>
  )
}
