import { createContext, useContext, useMemo, useState, PropsWithChildren, useEffect } from 'react'
import { addDoc, doc, getDoc, limit, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore'

import useUser from '../hooks/useUser'
import { USER_TYPE } from '../constants'
import { chatRef } from '../config/firebase'
import { useFetch } from './NetworkAdapter'

const ChatContext = createContext<any>(null)

export default function ChatProvider(props: PropsWithChildren): JSX.Element {
  const [users, setUsers] = useState<any>([])
  const [chats, setChats] = useState<any>({})
  const [unreadMsgs, setUnreadMsgs] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<any>(null)

  const loggedInUser = useUser()
  const fetch = useFetch()
  const isMentor = loggedInUser?.user_type === USER_TYPE.MENTOR

  /**
   * Fetch the list of users.
   * @returns {any} users - The list of users.
   */
  async function fetchUserList(stale: boolean = false): Promise<any[]> {
    let _users = users ?? []

    if (stale || _users?.length === 0) {
      const res = await fetch('get-chats-list')

      if (res?.status && Array.isArray(res?.data)) {
        _users = res?.data ?? []
        setUsers(_users)
      }
    }

    return _users
  }

  /**
   * Select the user to show the chats.
   * @param {any} user The user to show the chat messages.
   */
  function onSelectUser(user: any) {
    setSelectedUser(user)
  }

  /**
   * Send a message to user through firebase.
   * @param {any} payload The request body to send a message.
   */
  async function onMessageSend(payload: any) {
    await addDoc(chatRef, payload)
  }

  /**
   * Find the total number of unread messages.
   */
  function getChatCounts(chatId: string | null, cb: any) {
    getUnreadChats(chatId, cb)
  }

  function getUnreadChats(chatId: string | null, cb: any) {
    if ((chatId || cb) && loggedInUser?.chat_uid) {
      const q = chatId
        ? query(
            chatRef,
            orderBy('created_at', 'desc'),
            where('chat_id', '==', chatId),
            where('is_read', '==', false),
            where('receiver_id', '==', loggedInUser?.chat_uid),
          )
        : query(
            chatRef,
            orderBy('created_at', 'desc'),
            where('is_read', '==', false),
            where('receiver_id', '==', loggedInUser?.chat_uid),
          )

      onSnapshot(q, QuerySnapshot => {
        const fetchedMessages: any = []
        QuerySnapshot.forEach(doc => {
          fetchedMessages.push({ ...doc.data(), id: doc.id })
        })
        cb?.(fetchedMessages)
      })
    }
  }

  /**
   * Reset the context's state.
   */
  function resetContext() {
    setUsers([])
    setChats({})
    setSelectedUser(null)
  }

  /**
   * Fetch the last send message for the given chatId
   * @param {string} chatId The id of the one-on-one chat.
   * @returns The last chat message.
   */
  function getUserLastMessage(chatId: string, cb: any) {
    const q = query(chatRef, orderBy('created_at', 'desc'), where('chat_id', '==', chatId), limit(1))

    onSnapshot(q, QuerySnapshot => {
      const fetchedMessages: any = []
      QuerySnapshot.forEach(doc => {
        fetchedMessages.push({ ...doc.data(), id: doc.id })
      })
      cb?.(fetchedMessages)
    })
  }

  useEffect(() => {
    try {
      const q = query(
        chatRef,
        orderBy('created_at', 'desc'),
        where('is_read', '==', false),
        where('receiver_id', '==', loggedInUser?.chat_uid),
      )
  
      onSnapshot(q, QuerySnapshot => {
        const fetchedMessages: any = []
        QuerySnapshot.forEach(doc => {
          fetchedMessages.push({ ...doc.data(), id: doc.id })
        })
  
        setUnreadMsgs((prev: any) => {
          prev = [...prev, ...fetchedMessages]?.filter(
            (value: any, index: number, self: any) =>
              index === self.findIndex((t: any) => t.created_at === value.created_at),
          )
          return prev
        })
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const _chats = (unreadMsgs ?? [])?.filter((msg: any) => msg?.sender?.id === selectedUser?.id)

      if (_chats?.length > 0) {
        for (let i = 0; i < _chats?.length; i++) {
          const msgDoc = doc(chatRef, _chats[i]?.id)
          const docSnap = await getDoc(msgDoc)

          if (docSnap?.exists()) {
            await updateDoc(msgDoc, { is_read: true })
            setUnreadMsgs((prev: any) => prev?.filter((msg: any) => msg?.sender?.id !== selectedUser?.id))
          }
        }
      }
    })()
  }, [selectedUser, unreadMsgs])

  const context = useMemo(
    () => ({
      chats,
      getChatCounts,
      getUserLastMessage,
      isMentor,
      loggedInUser,
      onMessageSend,
      onSelectUser,
      selectedUser,
      resetContext,
      users: fetchUserList,
      unreadMsgs,
      updateUnreadMsgs: setUnreadMsgs,
    }),
    [chats, loggedInUser, selectedUser, unreadMsgs, users],
  )

  return <ChatContext.Provider {...props} value={context} />
}

export function useChat() {
  return useContext(ChatContext)
}
