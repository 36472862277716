import { Box, Typography, IconButton } from "@mui/material";
import Buttons from "../../components/reusables/Buttons";
import styles from "./learnerSchedule.module.css";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Orange } from "../../Style/colors";

const LearnerScheduleCard = (props: any) => {
  const {
    agenda,
    time,
    mentor,
    name,
    onClickDelete,
    onClickEdit,
    handleJoinClasses,
    showButton,
    slotData,
    meetingType,
    showJoinButton
  } = props;
  return (
    <Box className={styles.learnerScheduleMainBox}>
      <Box className={styles.scheduleMainBox}>
        <Box className={styles.learnerScheduleCardBox}>
          <Box className={styles.agendaBox}>
            <Box
              height="24px"
              padding="3px 8px 5px 8px"
              borderRadius="24px"
              border="1px solid var(--comparisionColor)"
              bgcolor="var(--themeOrangeLight)"
              fontSize="12px"
              fontWeight="400"
              fontFamily="var(--fontFamiljen)"
              width="fit-content"
              marginBottom="12px"
              color={Orange['600']}
              display="flex"
              alignItems="center"
            >
              {meetingType === "Doubt" ? "Doubt Session" :
                <Box display="flex" alignItems="center" gap="3px">
                  <Box sx={{ bgcolor: Orange["600"], height: "5px", width: "5px", borderRadius: "50%" }} />
                  <span> Live Class</span>
                </Box>
              }
            </Box>
            <Typography
              className={styles.agenda}
              sx={{ wordBreak: "break-word" }}
            >
              {meetingType === "Doubt" ? `Agenda: ${agenda}` : agenda}
            </Typography>
            <Typography className={styles.time}>{time}</Typography>
          </Box>
          {showButton ? (
            <Box className={styles.scheduleBtnBox}>
              <IconButton onClick={onClickDelete}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={onClickEdit}>
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Box className={styles.scheduleBtnBox}>
              {slotData?.is_cancelled ? (
                <Box className={styles.cancelStatus}>Cancelled</Box>
              ) : (
                <Box className={styles.cancelDuration}>
                  {slotData?.duration || ""}
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box className={styles.learnerScheduleCardBox2}>
          <Box>
            <Typography className={styles.time}>{mentor}</Typography>
            <Typography className={styles.mentor}>{name}</Typography>
          </Box>
          {showJoinButton && (
            <Box className={styles.scheduleBtnBox}>
              <Buttons
                name="join"
                variant="contained"
                size="small"
                className={styles.scheduleClassesBtn}
                type="submit"
                onClick={handleJoinClasses}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LearnerScheduleCard;
