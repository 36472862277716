import * as yup from "yup";

// [PAGES] FEEDBACK-PARAMETERS > ADD-FEEDBACK
const feedbackSchema = yup.object().shape({
  name: yup.string().required("Please enter the Feedback parameter name"),
});

// [PAGES] LMS > ADD-PROGRAME-MODAL
const lmsProgramSchema = yup.object().shape({
  name: yup.string().required("Please enter the programe name"),
});

// [PAGES] LMS > MODULES > ADD-MODULES
const modulSchema = yup.object().shape({
  name: yup.string().required("Please enter the module name"),
  description: yup.string().required("Please enter the module desciption"),
});

// [PAGES] LMS > TASKS > ADD-TASK-MODAL
const taskSchema = yup.object().shape({
  title: yup.string().required("Please enter title"),
  desc_title: yup.string().required("Please enter description title"),
  number_of_days: yup
    .number()
    .positive("Must be more than 0")
    .required("Please enter number of days"),
});

// [PAGES] LOGIN > LOGIN
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered email address is invalid, try again")
    .required("Email is required"),
});

const validationSchemaOtp = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .min(6, "Please enter 6 digit")
    .max(6, ""),
});
// [PAGES] USERE > MENTOR > ORGANIZATION-MODAL
const organizationSchema = yup.object().shape({
  name: yup.string().required("Please enter Your Organization Name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  contact_number: yup
    .string()
    .max(10)
    .required("Please enter your phone number"),
  address: yup.string().required("Please enter Your Address"),
});

// [PAGES] > USERS > USERMODAL
const schema = yup.object().shape({
  first_name: yup.string().required("Please enter Your First Name"),
  last_name: yup.string().required("Please enter Your Last Name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const validationLearnerSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the Learner first name"),
  last_name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the Learner last name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const validationOtherMemberSchema = yup.object().shape({
  name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the member name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const validationMentorSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the Mentor first name"),
  last_name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the Mentor last name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const createRoleSchema = yup.object().shape({
  role_name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the role name"),
  role_desc: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the role description"),
});

const rolesAssigneeSchema = yup.object().shape({
  name: yup
    .string()
    .max(25, "Too Long!")
    .required("Please enter the role name"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export {
  schema,
  feedbackSchema,
  lmsProgramSchema,
  modulSchema,
  taskSchema,
  validationSchema,
  organizationSchema,
  validationSchemaOtp,
  validationLearnerSchema,
  validationMentorSchema,
  createRoleSchema,
  validationOtherMemberSchema,
  rolesAssigneeSchema,
};
