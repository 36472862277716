import React from "react";
import { Box, ButtonBase, Divider } from "@mui/material";
import { Orange } from "../../Style/colors";

const PaginationTable = (props: any) => {
    const { currentPage, disableNextBtn, handleNextBtn, handlePreviousBtn } = props;

    const disablePrevBtn = currentPage === 1;


    return (
        <>
            <Divider />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                padding="10px 0"
                paddingLeft="15px"
            >
                <Box>
                    {!disablePrevBtn &&
                        <ButtonBase
                            sx={{
                                padding: "10px 12px",
                                border: `1px solid ${Orange["600"]}`,
                                borderRadius: "8px",
                                cursor: "pointer",
                                color: Orange["600"],
                            }}
                            disabled={disablePrevBtn}
                            onClick={handlePreviousBtn}
                        >
                            Prev
                        </ButtonBase>}
                </Box>

                {!disableNextBtn &&
                    <ButtonBase
                        sx={{
                            padding: "10px 12px",
                            border: `1px solid ${Orange["600"]}`,
                            borderRadius: "8px",
                            cursor: "pointer",
                            color: Orange["600"],
                        }}
                        disabled={disableNextBtn}
                        onClick={handleNextBtn}
                    >
                        Next
                    </ButtonBase>
                }

            </Box>
        </>
    );
};

export default PaginationTable;