import { Drawer } from "@mui/material";
import { UserManagementDrawerInterface } from "../../Interface/Interface";

const UserManagementDrawer = ({
  openLearnerDrawer,
  onHide,
  children,
}: UserManagementDrawerInterface) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={openLearnerDrawer}
        onClose={() => onHide()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        transitionDuration={400}
      >
        {children}
      </Drawer>
    </>
  );
};

export default UserManagementDrawer;
