interface HeadingTyp {
  headingValue?: string;
  component?:any;
  className?:string
}

const paragraphStyle = {
  margin:"0",
  marginBottom:"0.5rem"
}

const Heading = (props: HeadingTyp) => {
  const { headingValue,className } = props;
  return (
    <p className={className} style={{...paragraphStyle}}>
      {headingValue}
    </p>
  );
};

export default Heading;
