import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useQuery() {
  const { search } = useLocation()
  
  return useMemo(() => {
    // @ts-ignore
    const queryString = [...new URLSearchParams(search)]

    let queryParams: any = {}

    for (let [key, value] of queryString) {
      queryParams[key] = value
    }

    if (queryParams.state) {
      try {
        queryParams.state = JSON.parse(window.atob(queryParams.state))

        if (queryParams.state.student?.id) {
          queryParams = {
            ...queryParams,
            state: {
              ...queryParams.state,
              student: {
                ...queryParams.state.student,
                _id: queryParams.state.student.id
              }
            }
          }
        }
      } catch (error: any) {
        console.log(error.message)
      }
    }

    return queryParams

  }, [search])
}
