import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  styled as muiStyled,
  useTheme,
  Theme,
  CSSObject,
  Box,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Menu,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { logoutAction, mainCompanyAdmin, storeOrganizationName } from "../redux/slices/authSlice";
import tapp_logo from "../assets/logos/tapp_logo.svg";
import logoutImage from "../assets/images/logout.svg";
import {
  setSelectedOption,
  storeChildIndex,
} from "../redux/slices/MainSidebarSlice";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import AlertBox from "../components/reusables/AlertBox";
import {
  AccountIcon,
  AccountIconActive,
  FeedbackIcon,
  FeedbackIconActive,
  LearnerDashboardIcon,
  LearnerDashboardIconActive,
  LearnerLms,
  LearnerLmsActive,
  LearnerProgressTracking,
  LearnerProgressTrackingActive,
  LearnerSettings,
  PaymentIcon,
  PaymentIconActive,
  RolesIcon,
  RolesIconActive,
  StoreIcon,
  StoreIconActive,
  UserManagementIcon,
  UserManagementIconActive,
} from "../assets/logos/logos";
import Logout from "../assets/icons/logout.svg";
import { storeBatchesDetail, storeManuallyProgramDetail, storeProgramBatchName, storeProgramIdDetails } from "../redux/slices/batchesSlice";
import { api } from "../redux/api/api";

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: "#100F0F",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#100F0F",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = muiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "250px",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = muiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = muiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "#1976d2",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    backgroundColor: "#1976d2",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainSidebarHeader = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [confirmBox, setConfirmBox] = useState<boolean>(false);
  const opened = Boolean(anchorEl);
  const handleClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenConfirmBox = () => {
    setConfirmBox(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(api.util.resetApiState());
    dispatch(logoutAction(""));
    dispatch(setSelectedOption(""));
    dispatch(storeManuallyProgramDetail(null));
    dispatch(storeOrganizationName(null));
    dispatch(storeChildIndex(null));
    dispatch(storeProgramBatchName(null));
    dispatch(storeBatchesDetail([]));
    dispatch(storeProgramIdDetails({}))
    dispatch(mainCompanyAdmin(false));
    setAnchorEl(null);
    navigate("/admin");
  };

  const { children } = props;

  const [open] = useState(true);

  const [openList, setOpenList] = useState(false);

  const handleClick = () => {
    setOpenList(!openList);
  };

  const theme = useTheme();
  const refer: any = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const home = location.pathname.split("/")[2]?.toLowerCase() === "home";

  const handleOptionsClick = (text: string) => {
    if (home) dispatch(setSelectedOption("Dashboard"));
    dispatch(setSelectedOption(text));
    dispatch(storeChildIndex(null));
    text === "dashboard" ? navigate("/admin") : navigate(`/admin/${text}`);
  };

  const childRoutesIndex = useSelector(
    (state: any) => state.mainSidebarSlice.childIndex
  );

  const sideNavLinkArray = [
    {
      icon:
        location.pathname.split("/")[2]?.toLowerCase() === "home"
          ? LearnerDashboardIconActive
          : LearnerDashboardIcon,
      name: "home",
    },
    {
      icon: location.pathname.split("/").includes("lms")
        ? LearnerLmsActive
        : LearnerLms,
      name: "lms",
    },
    {
      icon: location.pathname.split("/").includes("user-management")
        ? UserManagementIconActive
        : UserManagementIcon,
      name: "user-management",
    },
    {
      icon: location.pathname.split("/").includes("progress-tracking")
        ? LearnerProgressTrackingActive
        : LearnerProgressTracking,
      name: "progress-tracking",
    },
    {
      icon: location.pathname.split("/").includes("store")
        ? StoreIconActive
        : StoreIcon,
      name: "store",
    },
    {
      icon: location.pathname.split("/").includes("contact")
        ? StoreIconActive
        : StoreIcon,
      name: "contact"
    }
  ];

  const array = [
    {
      icon: location.pathname.split("/").includes("account")
        ? AccountIconActive
        : AccountIcon,
      name: "Account",
    },
    {
      icon: location.pathname.split("/").includes("roles")
        ? RolesIconActive
        : RolesIcon,
      name: "Roles and Permissions",
    },
    {
      icon: location.pathname.split("/").includes("feedback")
        ? FeedbackIconActive
        : FeedbackIcon,
      name: "Feedback Settings",
    },
    {
      icon: location.pathname.split("/").includes("payment")
        ? PaymentIconActive
        : PaymentIcon,
      name: "Payment & Billing",
    },
  ];
  const handleChildNavigation = (item: any, index: number) => {
    const navigateTo = item.name.split(" ")[0].toLowerCase();
    navigate(`admin/settings/${navigateTo}`);
    dispatch(storeChildIndex(index));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
          width: "100%",
        }}
      >
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            component="div"
          >
            <Box
              onClick={() => {
                navigate("/admin/home");
                dispatch(setSelectedOption("Dashboard"));
              }}
              component="div"
              sx={{ cursor: "pointer", display: "flex" }}
            >
              <img src={tapp_logo} />
            </Box>

            <Box>
              <IconButton
                id="basic-IconButton"
                aria-controls={opened ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={opened ? "true" : undefined}
                onClick={handleClicked}
              >
                <img src={logoutImage} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={opened}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenConfirmBox}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Divider />
        <List sx={{ marginTop: "5rem" }}>
          {sideNavLinkArray.map((item: any) => {
            return (
              <ListItem
                id={item.name}
                className={`${location.pathname.split("/")[2]?.toLowerCase() === item.name
                  ? "linkactive"
                  : ""
                  }`}
                key={item.name}
                onClick={() => handleOptionsClick(item.name)}
                disablePadding
                ref={refer}
                sx={{
                  display: "block",
                  color: theme.palette.info.main,
                  margin: "10px 0",
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 0,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  className="cdd"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                      color: theme.palette.info.main,
                    }}
                  >
                    <img src={item.icon} alt="item.icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      item.name === "home"
                        ? "dashboard"
                        : item.name.split("-").join(" ")
                    }
                    sx={{
                      opacity: open ? 1 : 0,
                      textTransform: "capitalize",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}

          <ListItem
            disablePadding
            ref={refer}
            sx={{
              display: "block",
              color: theme.palette.info.main,
              margin: "10px 0",
            }}
          >
            <ListItemButton
              onClick={handleClick}
              sx={{
                minHeight: 0,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              className="cdd"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                  color: theme.palette.info.main,
                }}
              >
                <img src={LearnerSettings} alt="LearnerSettings" />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                sx={{
                  opacity: open ? 1 : 0,
                  textTransform: "capitalize",
                }}
              />
              {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {array?.map((item: any, index: number) => {
                  return (
                    <ListItemButton
                      key={item.name}
                      sx={{ pl: 4 }}
                      onClick={() => handleChildNavigation(item, index)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img src={item.icon} alt="itemIcon" />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                            childRoutesIndex === index
                              ? "var(--themeOrange)"
                              : theme.palette.info.main,
                        }}
                        primary={item.name}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>

      <AlertBox
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        onClick={handleLogout}
        title="Logout"
        nameBtn1="No Cancel"
        nameBtn2="Yes, Logout"
        message={
          <p style={{ margin: "0" }}>Are you sure you want to logout?</p>
        }
        imgSrc={Logout}
      />
    </Box>
  );
};

export default MainSidebarHeader;
