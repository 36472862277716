import { Box, Divider, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import tappIcon from "../../../assets/icons/tapp-logo.svg"

const SuggestCard = styled.div`
  width: 100%;
  border: 1px solid var(--lightGrayBorder);
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: var(--themeOrangeLight);
  position: relative;
  min-height: 11rem;
  cursor: pointer;
  h5,
  h6 {
    font-size: 1.125rem;
    font-family: var(--fontFamiljen);
    font-weight: var(--font600);
  }
  h6 {
    font-size: 0.75rem;
    font-weight: var(--font400);
  }
`;

const ProgramLevel = styled.div<any>`
  padding: 2px 14px;
  border: 1.5px solid #100f0f;
  border-radius: 4px;
  font-size: 12px;
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  text-transform: capitalize;
  background-color: ${({ variant }) => {
    switch (variant) {
      case "beginner":
        return "#06D6A0";
      case "intermediate":
        return "#FAAD15";
      case "pro":
        return "#EC5F2A";
      default:
        break;
    }
  }};
`;
const CreateName = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    background-color: #FCE3D9;
    padding: 0.5rem;
    text-align: right;
    font-family: var(--fontFamiljen);
    font-weight: var(--font500);
    font-size:0.75rem;
    color:#4A4545;
`;
const SuggestProgramCard = (props: any) => {
  const {
    name,
    img,
    modules,
    variant,
    duration,
    className,
    handleshowProgramDrawer,
  } = props;
  return (
    <SuggestCard className={className} onClick={handleshowProgramDrawer}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          marginBottom: "0.5rem",
        }}
      >
        <img src={tappIcon} width={25} height={25}/>
        <ProgramLevel variant={variant}>{variant}</ProgramLevel>
      </Box>
      <Typography variant="h5" marginBottom={1} textTransform="capitalize" style={{ wordWrap: "break-word"}}>
        {name}
      </Typography>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={1}
      >
        <Typography variant="h6">{modules} Modules </Typography>
        <Typography variant="h6">{duration}h Duration </Typography>
      </Stack>
      <CreateName>Created by Tapp</CreateName>
    </SuggestCard>
  );
};

export default SuggestProgramCard;
