import { Box } from "@mui/material";
import styled from "styled-components";

interface CustomInputProps {
  placeholder?: string;
  id?: string;
  name?: string;
  onChange?: any;
  value?: string;
  emailAddress?: string;
  className?: string;
  errorText?: any;
  style?: object;
  onBlur?:any;
  maxLength?:any;
}

const TeaxtArea = styled.textarea`
  padding: 1rem;
  outline: none;
  border: 1px solid var(--borderInput);
  display: block;
  background-color: #f5f4f4;
  font-family: var(--fontsFamily);
  font-size: 16px;
  resize: none;
  border-radius: 8px;
  height: 100px;
  width: 100%;
  ::placeholder {
    color: var(--InputTextGray);
    font-size: 16px;
    font-weight: var(--font400);
    font-family: var(--fontFamiljen);
  }
  :focus {
    background-color: var(--themeOrangeLight);
    border: 1px solid var(--themeOrange);
  }
`;
const Legend = styled.legend`
  display: block;
  width: 100%;
  margin: 0;
  font-family: var(--fontsFamily);
  color: var(--legendColor);
  margin-bottom: 0.2rem;
  font-size: 14px;
`;
const Errors = styled.p`
  color: var(--errorsColor);
  font-size: 12px;
  margin: 4px;
`;
const CustomTextArea = (props: CustomInputProps) => {
  const {
    placeholder,
    id,
    name,
    onChange,
    value,
    emailAddress,
    style,
    className,
    errorText,
    onBlur,
    maxLength,
  } = props;

  return (
    <Box style={style}>
      <Legend>{emailAddress}</Legend>
      <TeaxtArea
        placeholder={placeholder}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        className={className}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      <Errors>{errorText}</Errors>
    </Box>
  );
};

export default CustomTextArea;
