import { useState } from "react";
import { Box, Typography, Tabs, Tab, Breadcrumbs } from "@mui/material";
import { baseTextStyle } from "../../Style/base";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { Shades } from "../../Style/colors";
import Buttons from "../../components/reusables/Buttons";
import styles from "./schedule.module.css";
import Upcoming from "./Upcoming";
import History from "./History";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0, py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  textTransform: "capitalize",
};

const btnStyle = {
  padding: "0.7rem 1rem",
  borderRadius: "0rem",
  boxShadow: "none",
  textTransform: "capitalize",
  backgroundColor: "#f2f2f2",
};

const Schedules = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleScheduleAvailability = () => {
    navigate("/schedules/scheduleavailability");
  };
  return (
    <Box>
      <Box sx={{ py: "16px" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography
            sx={baseTextStyle(
              FONT_FAMILY.BODY,
              FONT_WEIGHT.MEDIUM,
              "14px",
              Shades["101"]
            )}
          >
            My Classes
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box className={styles.reScheduleBtn}>
        <Buttons
          name="Availability Setting"
          variant="contained"
          color="inherit"
          sx={{ ...btnStyle }}
          onClick={handleScheduleAvailability}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="upcoming schedules"
          >
            <Tab label="Upcoming" {...a11yProps(0)} sx={{ ...tabStyle }} />
            <Tab label="History" {...a11yProps(1)} sx={{ ...tabStyle }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Upcoming showButton={true} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <History showButton={false} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default Schedules;
