import { createSlice, Slice } from "@reduxjs/toolkit";
import { AuthState } from "../../Interface/Interface";

const initialState: AuthState = {
  userToken: null,
  isMainCompanyAdmin: false,
  userOtpToken: null,
  organizationName: null,
  token: null,
  user: null,
  refreshToken: null,
  iua: null,
  permissions: [],
};

const authSlice: Slice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    logoutAction: (): AuthState => initialState,
    storeLoginToken: (state, action) => {
      if (action.payload) {
        state.userToken = action.payload;
      }
    },
    storeOtpToken: (state, action) => {
      state.userOtpToken = action.payload;
    },
    mainCompanyAdmin: (state, action) => {
      state.isMainCompanyAdmin = action.payload;
    },
    storeAuthToken: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.refreshToken = action.payload.refresh_token;
      state.iua = action.payload.iua;
    },
    storePermissions: (state, action) => {
      state.permissions = action.payload;
    },
    storeOrganizationName: (state, action) => {
      state.organizationName = action.payload;
    },
  },
});

export const {
  storeAuthToken,
  storeLoginToken,
  logoutAction,
  storeOtpToken,
  mainCompanyAdmin,
  storePermissions,
  storeOrganizationName,
} = authSlice.actions;
export default authSlice.reducer;
