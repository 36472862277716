import {
  Box,
  MenuItem,
  OutlinedInput,
  Typography,
  Alert,
  Breadcrumbs,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "@mui/material/Select";
import styling from "../Dashboard/webDashboardStyle.module.css";
import { Fragment, useState } from "react";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { Gray, Orange, Shades, Yellow } from "../../Style/colors";
import { baseTextStyle } from "../../Style/base";
import styles from "./learnerSchedule.module.css";
import {
  useDaySlotsQuery,
  useUpdateScheduleCallMutation,
  useGetAllSlotsListQuery,
  useUpdateLearnerScheduleCallMutation,
  useProgramListForLearnerQuery,
} from "../../redux/api/webLMS.api";
import RectangularCheckBox from "../Schedule/RectangularCheckBox";
import Heading from "../Dashboard/Heading";
import CustomTextArea from "../../components/reusables/CustomTextArea";
import Buttons from "../../components/reusables/Buttons";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { useDispatch } from "react-redux";
import { openAlert } from "../../redux/slices/snackbarSlice";
import { useFormik } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate, useLocation } from "react-router-dom";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { BootstrapTooltip } from "../Users/UserManagement";
import {
  schedulewholeText,
  updateSlotEditCode1,
  updateSlotEditCode2,
  updateSlotErrCode1,
  updateSlotErrCode2,
  updateSlotErrCode3,
  updateSlotErrCode4,
  updateSlotErrCode5,
  updateSlotErrCode6,
  updateSlotErrCode7,
  updateSlotSuccessCode,
} from "../../components/utils/constants";
import { ClockIcon } from "../../assets/logos/logos";

dayjs?.extend(utc);

const doubtSession = "1 doubt session of 15 minutes will be allotted every week for a program."
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 192,
      border: "none",
    },
  },
};

export const scheduleCallValues = {
  slotId: "",
  start_date: "",
  end_date: "",
  subject: "",
  mentor_id: "",
};

const ScheduleClasses = () => {
  const location = useLocation();
  const [mentor, setMentor] = useState("");
  const [openStartDate, setOpenStartDate] = useState(false);
  const [mentorID, setMentorID] = useState({
    id: "",
    mentorId: "",
    mentor: "",
    slots: 0,
  });
  const meetingId = location?.state ?? null;
  const newDATA = meetingId?.start_date;
  const [date, setDate] = useState<Dayjs | null>(dayjs(newDATA));
  const [isDateSelected, setIsDateSelected] = useState<any>("");
  const [subject, setSubject] = useState(meetingId?.title || "");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [updateSlots] = useUpdateScheduleCallMutation();
  const [payload, setPayload] = useState<any>({});
  const [getSlotsID, setGetSlotsID] = useState<any>(
    meetingId?.slot?._id ? [meetingId?.slot?._id] : []
  );
  const navigate = useNavigate();
  const [updateLearnerScheduleData] = useUpdateLearnerScheduleCallMutation();
  const handleProgramsChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setMentor(value);
    setIsDateSelected("");
  };

  const requestObj = {
    url: "get-programs",
  };

  const { data, isLoading: mentorLoader } =
    useProgramListForLearnerQuery(requestObj);
  const programsArr = data?.data;

  const handleMentorId = (items: any) => {
    setMentorID({
      id: items.id,
      mentorId: items.mentor._id,
      mentor: items.mentor.first_name + " " + items.mentor.last_name,
      slots: items?.available_doubt_sessions,
    });
  };
  const slotsRequestObj = {
    url: "day-slot-list",
  };

  const { data: slotsData } = useDaySlotsQuery(slotsRequestObj);

  const handleChangeDate = (value: any) => {
    setDate(value);
    setIsDateSelected(value);
    setGetSlotsID("");
  };

  const getAllSlotsObj = {
    body: {
      date: date?.format("YYYY-MM-DD"),
      mentor_id: meetingId?.mentor?._id || mentorID.mentorId,
    },
  };

  const { data: allSlotsData, isFetching }: any =
    useGetAllSlotsListQuery<any>(getAllSlotsObj);

  const onGetSlotsId = (items: any) => {
    if (date) {
      const startTimeString = items?.start_time;
      const currentDate = new Date(dayjs(date)?.format("YYYY-MM-DD"));
      const fullDateTimeString = `${currentDate.toDateString()} ${startTimeString}`;
      const parsedDate = new Date(fullDateTimeString);
      const startDateTime = parsedDate.getTime();
      const endTimeString = items?.end_time;
      const fullDateTimeString2 = `${currentDate.toDateString()} ${endTimeString}`;
      const fullParsedDate = new Date(fullDateTimeString2);
      const endDateTime =
        items?.end_time === "12:00 AM"
          ? fullParsedDate.getTime() + 86400000
          : fullParsedDate.getTime();
      setGetSlotsID(items?._id);
      setPayload((prev: any) => ({
        ...prev,
        start_date: startDateTime,
        end_date: endDateTime,
      }));
    } else {
      console.error("date is undefined");
    }
  };

  const handleChangeSubject = (event: any) => {
    setSubject(event.target.value);
    setPayload((prev: any) => ({
      ...prev,
      subject: event.target.value,
    }));
  };

  const handleScheduleClasses = async () => {
    if (location?.state?._id) {
      const requestForEditObj = {
        body: {
          ...payload,
          ...(location?.state?._id ? { meeting_id: location?.state?._id } : {}),
          start_date: new Date(
            payload?.start_date || location?.state?.start_date
          ).getTime(),
          end_date: new Date(
            payload?.end_date || location?.state?.end_date
          ).getTime(),
          slotId:
            (Array.isArray(getSlotsID) ? getSlotsID[0] : getSlotsID) ||
            location?.state?.slot?._id,
          mentor_id: mentorID.mentorId,
          program_id: mentorID.id,
        },
      };
      setIsSubmit(true);
      try {
        const resp: any = await updateLearnerScheduleData(
          requestForEditObj
        ).unwrap();
        if (resp.code === 4065) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setMentorID({
            id: "",
            mentorId: "",
            mentor: "",
            slots: 0
          });
          setIsSubmit(false);
          navigate("/learner_schedules");
        } else if (
          resp.code === updateSlotEditCode1 ||
          resp.code === updateSlotEditCode2
        ) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "error",
            })
          );
          setIsSubmit(false);
        }
      } catch (error) {
        setIsSubmit(false);
        console.log(error);
      }
    } else {
      const reqObject = {
        body: {
          ...payload,
          mentor_id: mentorID.mentorId,
          program_id: mentorID.id,
          slotId: getSlotsID,
        },
      };
      setIsSubmit(true);
      try {
        const resp: any = await updateSlots(reqObject).unwrap();
        if (resp.code === updateSlotSuccessCode) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setIsSubmit(false);
          navigate("/learner_schedules");
        } else
        //  if (
        //   resp.code === updateSlotErrCode1 ||
        //   resp.code === updateSlotErrCode2 ||
        //   resp.code === updateSlotErrCode3 ||
        //   resp.code === updateSlotErrCode4 ||
        //   resp.code === updateSlotErrCode5 ||
        //   resp.code === updateSlotErrCode6 ||
        //   resp.code === updateSlotErrCode7
        // )
        {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "error",
            })
          );
          setIsSubmit(false);
        }
      } catch (error) {
        console.log(error);
        setIsSubmit(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: scheduleCallValues,
    onSubmit: handleScheduleClasses,
    enableReinitialize: true,
  });

  const { handleSubmit } = formik;

  const tomorrow = dayjs().add(2, "week");

  const availableTimeSlots = allSlotsData?.data?.length

  const handleNavigateBreadCrumbs = () => {
    navigate("/learner_schedules");
  };

  return (
    <>
      <Box sx={{ py: "16px" }} className={styles.breadBox}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Typography
            sx={{
              ...baseTextStyle(
                FONT_FAMILY.BODY,
                FONT_WEIGHT.MEDIUM,
                "14px",
                Shades["101"]
              ),
              cursor: "pointer",
            }}
            onClick={handleNavigateBreadCrumbs}
          >
            My Classes
          </Typography>
          <Typography
            sx={baseTextStyle(
              FONT_FAMILY.BODY,
              FONT_WEIGHT.MEDIUM,
              "14px",
              Gray["200"]
            )}
          >
            Schedule Classes
          </Typography>
        </Breadcrumbs>

        {mentorID.id &&
          <Box
            sx={{
              padding: "8px",
              borderRadius: "8px",
              border: `1px solid ${Orange["300"]}`,
              backgroundColor: Orange["100"],
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxHeight: "32px"
            }}>
            <BootstrapTooltip title={doubtSession} placement="left-start">
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500"
                }}
              >
                {mentorID?.slots} {mentorID.slots > 1 ? "Doubts" : "Doubt"} Session
              </Typography>
            </BootstrapTooltip>
            <img src={ClockIcon} alt="ClockIcon" />
          </Box>
        }
      </Box>
      <form onSubmit={handleSubmit}>
        <Box className={styles.datePickerBox}>
          {mentorLoader ? (
            <SkeletonTable
              sx={{
                width: "440px",
                height: "2.5rem",
                margin: "3.2rem 0rem 0rem 0rem",
              }}
            />
          ) : (
            <Box className={styles.selectProgramBoxLearner}>
              <Typography className={styling.selectedProgramHeading}>
                Select Program
              </Typography>
              <Select
                sx={{ width: "440px" }}
                IconComponent={ExpandMoreIcon}
                className={styling.selectBox}
                displayEmpty
                value={
                  (mentor ||
                    (meetingId?.mentor &&
                      `${meetingId?.mentor?.first_name} ${meetingId?.mentor?.last_name}`)) ?? (
                    <>Select</>
                  )
                }
                onChange={handleProgramsChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  const wholeText =
                    selected + " " + "-" + " " + mentorID.mentor;
                  if (selected?.key === null) {
                    return <>Select Program</>;
                  } else {
                    return wholeText?.length > schedulewholeText ? (
                      <BootstrapTooltip title={wholeText} placement="top">
                        <Typography color="var(--mentorTextColor)">
                          {wholeText.substring(0, schedulewholeText) + "..."}
                        </Typography>
                      </BootstrapTooltip>
                    ) : (
                      <Box display="flex" alignItems="center" gap="3px">
                        <Typography>{selected + " " + "-" + " "}</Typography>
                        <Typography color="var(--mentorTextColor)">
                          {mentorID.mentor}
                        </Typography>
                      </Box>
                    );
                  }
                }}
                MenuProps={MenuProps}
                disabled={location?.state?.mentor?._id ? true : false}
              >
                <MenuItem sx={{ display: "none" }} value=""></MenuItem>
                {programsArr?.map((items: any) => (
                  <MenuItem
                    key={items.id}
                    value={items.name}
                    sx={{ width: "100%" }}
                    className={styling.selectedProgramStyles}
                    onClick={() => handleMentorId(items)}
                  >
                    <Box>
                      <Box sx={{ width: "fit", display: "flex", flexDirection: "row", gap: "4px" }}>
                        <Typography
                          sx={{
                            width: "fit",
                            padding: "5px",
                            fontSize: "12px",
                            borderRadius: "4px",
                            border: "1px solid black",
                            backgroundColor: Yellow["100"],
                          }}
                        >
                          {items?.difficulty}
                        </Typography>
                        <Typography
                          sx={{
                            width: "fit",
                            padding: "5px",
                            fontSize: "12px",
                            borderRadius: "4px",
                            border: "1px solid black",
                            textTransform: "capitalize",
                            backgroundColor: Orange["50"],
                          }}
                        >
                          {items?.batch?.name?.toLowerCase()}</Typography>
                      </Box>
                      <Typography sx={{ fontSize: "14px" }}>
                        {items?.name?.length > schedulewholeText ? (
                          <BootstrapTooltip title={items?.name} placement="top">
                            <Typography color="var(--mentorTextColor)">
                              {items?.name.substring(0, schedulewholeText) + "..."}
                            </Typography>
                          </BootstrapTooltip>
                        ) : (
                          items.name
                        )}
                      </Typography>
                    </Box>


                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {mentorLoader ? (
            <SkeletonTable
              sx={{
                width: "2.5rem",
                height: "2.5rem",
                margin: "3.2rem 0rem 0rem 0rem",
              }}
            />
          ) : meetingId?.mentor?._id || mentorID ? (
            <Box className={styles.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={date || newDATA}
                    onChange={(value: any) => handleChangeDate(value)}
                    disablePast={true}
                    maxDate={tomorrow}
                    disabled={!mentorID.slots}
                    open={openStartDate}
                    onClose={() => setOpenStartDate(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setOpenStartDate(true),
                      },
                      field: {
                        readOnly: true
                      }
                    }}
                  // shouldDisableDate={isWeekend}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          ) : null}
        </Box>

        {mentorID.id && mentorID.slots === 0 &&
          <Box
            sx={{
              padding: "8px",
              borderRadius: "8px",
              border: `1px solid ${Orange["300"]}`,
              backgroundColor: Orange["100"],
            }}
          >
            <Typography>You have 0 doubt session.</Typography>
          </Box>
        }

        {isDateSelected || newDATA ? (
          <>
            <Box className={styles.daysBox}>
              <Box className={styles.selectedDateBox}>
                <Typography>{dayjs(date).format("MMM D, YYYY")}</Typography>
              </Box>
            </Box>
            <Heading headingValue="Select your slots" />
            {isFetching ? (
              <Box className={styles.skeletonBox}>
                {Array.from({ length: 24 }).map((_, index) => {
                  const uniqueKey = `skeleton_${index}`;
                  return (
                    <Fragment key={uniqueKey}>
                      <SkeletonTable
                        sx={{
                          width: "100%",
                          height: "2.5rem",
                        }}
                      />
                    </Fragment>
                  );
                })}
              </Box>
            ) : (
              <>
                <Box className={styles.learnerSlotsBox}>
                  {allSlotsData !== undefined &&
                    allSlotsData?.data?.map((items: any) => {
                      return (
                        <RectangularCheckBox
                          label={`${items?.start_time} - ${items?.end_time}`}
                          key={items._id}
                          onChange={() => onGetSlotsId(items)}
                          checked={
                            getSlotsID?.includes(items?._id) ? true : false
                          }
                        />
                      );
                    })

                  }
                </Box>


                {availableTimeSlots ?
                  <Box>
                    <Box className={styles.agendaInputBox}>
                      <Heading headingValue="Meeting Agenda" />
                      <CustomTextArea
                        maxLength={500}
                        placeholder="Agenda..."
                        onChange={(event: any) => handleChangeSubject(event)}
                        value={subject}
                        className={styles.agendaTextArea}
                      />
                    </Box>
                    <Box className={styles.submitScheduleBox}>
                      <Buttons
                        name="Schedule Class"
                        variant="contained"
                        size="large"
                        className={styles.scheduleClassesBtn}
                        type="submit"
                        disabled={
                          (!subject && !meetingId?.title) || isSubmit
                          // ? true
                          // : false
                        }
                      />
                    </Box>
                  </Box> :
                  <Box
                    sx={{
                      padding: "8px",
                      borderRadius: "8px",
                      border: `1px solid ${Orange["300"]}`,
                      backgroundColor: Orange["100"],
                    }}
                  >
                    <Typography>No slots available for the selected date</Typography>
                  </Box>
                }
              </>
            )}
          </>
        ) : null}
      </form>
    </>
  );
};

export default ScheduleClasses;
