import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { customStyle } from "../../../Style/style";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage, FastField, Form, Formik } from "formik";
import { validationLearnerSchema } from "../../../Schema/Schema";
import InputCustom from "../../../components/reusables/InputCustom";
import AddIcon from "@mui/icons-material/Add";
import Buttons from "../../../components/reusables/Buttons";
import {
  LearnerDrawerInterface,
  LearnerRequiredObj,
} from "../../../Interface/Interface";
import ErrorText from "../../../components/reusables/ErrorText";
import {
  useAddLearnersMutation,
  useGetAllLearnerBatchesQuery,
  useGetMentorsListQuery,
  useGetProgramsListQuery,
} from "../../../redux/api/api";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";
import { SELECT_PROGRAM } from "../../../components/utils/learnerDrawerConstant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Theme, useTheme } from "@mui/material/styles";
import { notSyncedWithOutlook } from "../../../components/utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 212,
    },
  },
};

const selectStyleBox = {
  height: "56px",
  backgroundColor: "var(--lightGray)",
  border: "1px solid var(--borderInput)",
  fontFamily: "var(--fontFamiljen)",
  lineHeight: "20px",
  color: "var(--InputTextGray)",
  padding: "14px 6px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
};

const labelText = {
  fontFamily: "Familjen Grotesk",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#544F4F",
  marginBottom: "8px",
};

function getStyles(name: string, theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    height: "37px",
    fontSize: "14px",
    fontFamily: "var(--fontFamiljen)",
    color: "var(--activityText)",
  };
}

const LearnerDrawer = ({
  onHide,
  singleLearnerDetail,
}: LearnerDrawerInterface) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [validateLearner, setValidateLearner] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryTypeId, setPrimaryTypeId] = useState<any>("");
  const [alreadySelectedProgram, setAlreadySelectedProgram] = useState<any>("");

  const [addLearners, { isLoading }] = useAddLearnersMutation();

  const organizationId = useSelector(
    (state: any) => state?.authSlice?.userToken
  );

  const allMentorsReq = {
    url: "mentor-list",
    org_id: organizationId,
  };

  const reqObjectMentors = {
    url: "mentor-list",
    org_id: organizationId,
    tech_ids: primaryTypeId,
  };

  const {
    data: mentorData,
    isLoading: mentorIsLoading,
    isFetching: mentorIsFetching,
  } = useGetMentorsListQuery(reqObjectMentors, {
    skip: primaryTypeId === SELECT_PROGRAM || primaryTypeId === "",
  });

  const { data: techStackData } = useGetProgramsListQuery({
    limit: 1000,
    isPublished: true,
  });

  const programAndMentorIds = singleLearnerDetail?.programs?.map(
    (item: any) => {
      return {
        student_program_id: item?.student_program_id,
        program_id: item?.program_id,
        mentor_id: item?.mentor_id,
        batch_id: {
          id: item?.batch?._id,
          name: item?.batch?.name,
        },
      };
    }
  );

  const [inputFields, setInputFields] = useState(
    programAndMentorIds || [
      {
        student_program_id: "",
        program_id: "",
        mentor_id: "",
        batch_id: {
          id: "",
          name: "",
        },
      },
    ]
  );

  const batchesPayload = {
    url: "program-batches",
    program_id: primaryTypeId,
    mentor_id: inputFields.filter(
      (item: any) => item.program_id === primaryTypeId
    )[0]?.mentor_id,
  };

  const { data: allMentorsData } = useGetMentorsListQuery(allMentorsReq);

  const {
    data: allBatches,
    isLoading: batchIsLoading,
    isFetching: batchIsFetching,
  } = useGetAllLearnerBatchesQuery(batchesPayload, {
    skip: primaryTypeId === SELECT_PROGRAM || primaryTypeId === "",
  });

  const handleAddFields = () => {
    setInputFields((prev: any) => {
      return [
        ...prev,
        {
          student_program_id: "",
          program_id: "",
          mentor_id: "",
          batch_id: {
            id: "",
            name: "",
          },
        },
      ];
    });
    setValidateLearner(false);
    setAlreadySelectedProgram("");
  };

  const handleRemoveFields = (index: any) => {
    setInputFields((prevInputFields: any) => {
      const values = [...prevInputFields];
      values.splice(index, 1);
      return values;
    });
    setAlreadySelectedProgram("");
  };

  const handleInputChange = (index: any, event: any) => {
    const values: any = [...inputFields];

    if (event.target.name === "program_id") {
      values[index].program_id = event.target.value;
      values[index].batch_id.id = '';
      values[index].batch_id.name = '';
      values[index].mentor_id = '';
    } else if (event.target.name === "batch_id") {
      values[index].batch_id.id = event.target.value._id;
      values[index].batch_id.name = event.target.value.name;
    } else {
      values[index].mentor_id = event.target.value;
      values[index].batch_id.id = '';
      values[index].batch_id.name = '';
    }
    setInputFields(values);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const initialValues = {
    first_name: singleLearnerDetail?.first_name || "",
    last_name: singleLearnerDetail?.last_name || "",
    email: singleLearnerDetail?.email || "",
  };

  const handleSubmitForm = async (values: any) => {
    if (
      validateLearner &&
      (inputFields[inputFields.length - 1]?.mentor_id?.length === 0 ||
        inputFields[inputFields.length - 1]?.program_id?.length === 0 ||
        inputFields[inputFields.length - 1]?.batch_id?.id?.length === 0)
    ) {
      return;
    }

    const repeats = inputFields?.map((it: any) => it.program_id);
    const repeatArrState = repeats.some(
      (item: any, index: number) => repeats.indexOf(item) !== index
    );
    if (repeatArrState) {
      dispatch(
        openAlert({
          message: "Same program cannot be selected again.",
          severity: "warning",
        })
      );
      return;
    }

    const onlyIds = inputFields?.filter((ite: any) => !ite?.student_program_id)?.map((ite: any) => ({
      mentor_id: ite?.mentor_id,
      program_id: ite?.program_id,
      batch_id: ite?.batch_id?.id,
    }));

    try {
      if (singleLearnerDetail?.id) {
        const requiredObj: LearnerRequiredObj = {
          url: "add-edit-learner",
          body: {
            first_name: values.first_name,
            learner_id: singleLearnerDetail?.id,
            email: values.email,
            last_name: values.last_name,
            programs_data: { add: onlyIds, student_program_ids_to_delete: [] },
          },
        };
        setIsSubmitting(true);
        const resp: any = await addLearners(requiredObj).unwrap();
        if (resp.status) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          onHide();
          setIsSubmitting(false);
        } else {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "error",
            })
          );
          // onHide();
          setIsSubmitting(false);
        }
      } else {
        const requiredObj: LearnerRequiredObj = {
          url: "add-edit-learner",
          body: {
            first_name: values.first_name,
            // id: 0,
            email: values.email,
            last_name: values.last_name,
            programs_data: { add: onlyIds, student_program_ids_to_delete: [] },
          },
        };

        setIsSubmitting(true);
        const resp: any = await addLearners(requiredObj).unwrap();
        if (resp.code === 3026) {
          onHide();
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setIsSubmitting(false);
        }
        if (resp.code === 3028) {
          dispatch(
            openAlert({
              message: "Participant with  this Email already exists",
              severity: "warning",
            })
          );
          setIsSubmitting(false);
        } else if (resp.code === notSyncedWithOutlook) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "warning",
            })
          );
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProgramSelected = (id: string) => {
    setPrimaryTypeId(id);
    const selectedItem = inputFields.filter(
      (item: any) => item.program_id === id
    );
    setAlreadySelectedProgram(selectedItem);
    if (inputFields.length === 1) {
      return;
    } else if (selectedItem.length > 0) {
      dispatch(
        openAlert({
          message: "This program already has been selected !",
          severity: "warning",
        })
      );
    }
  };

  const disableAddIcon = () => {
    if (
      inputFields.length === 1 &&
      (!inputFields[0].program_id ||
        !inputFields[0].mentor_id ||
        !inputFields[0].batch_id?.id)
    ) {
      return true;
    } else if (
      inputFields[inputFields.length - 1]?.program_id === "" ||
      inputFields[inputFields.length - 1]?.program_id === undefined ||
      inputFields[inputFields.length - 1]?.mentor_id === "" ||
      inputFields[inputFields.length - 1]?.mentor_id === undefined ||
      inputFields[inputFields.length - 1]?.batch_id?.id === "" ||
      inputFields[inputFields.length - 1]?.batch_id?.id === undefined ||
      alreadySelectedProgram?.length > 0
    )
      return true;
    else return false;
  };

  const buttonText = () => {
    if (singleLearnerDetail?.id && isLoading) {
      return "Saving...";
    } else if (singleLearnerDetail?.id) {
      return "Save Changes";
    } else if (isSubmitting) {
      return "Adding Learner...";
    } else {
      return "Add Learner";
    }
  };

  const handleMentorClick = async (item: any) => {
    setPrimaryTypeId(item);
  };

  const getBackColor = (item: any) => {
    if (item.difficulty === "intermediate") {
      return "var(--intermediateBackground)";
    } else if (item.difficulty === "pro") {
      return "var(--themeOrange)";
    } else {
      return "var(--topPerformer)";
    }
  };

  const handleSubmitClick = () => {
    setValidateLearner(true);
    // setValidateBatch(true);
  };

  return (
    <>
      <Box sx={customStyle}>
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              fontFamily: `var(--fontMulish)`,
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "800",
              color: "var(-black)",
              marginBottom: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {singleLearnerDetail?.id ? "Edit" : "Add"} Learner
          </Typography>
          <CloseIcon
            onClick={() => {
              onHide();
            }}
            fontSize="medium"
            sx={{
              color: "var(--emailTextForAssign)",
              cursor: "pointer",
            }}
          />
        </Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationLearnerSchema}
          onSubmit={handleSubmitForm}
        >
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              component="div"
              sx={{ my: 2 }}
            >
              <Box sx={{ marginBottom: "32px" }}>
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                  }}
                  placeholder="Enter Learner first name"
                  type="text"
                  id="learner_first_name"
                  emailAddress="Learner first name"
                  name="first_name"
                  as={InputCustom}
                />
                <ErrorMessage name="first_name" component={ErrorText} />
              </Box>
              <Box sx={{ marginBottom: "32px" }}>
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                  }}
                  placeholder="Enter Learner last name"
                  type="text"
                  id="learner_last_name"
                  emailAddress="Learner last name"
                  name="last_name"
                  as={InputCustom}
                />
                <ErrorMessage name="last_name" component={ErrorText} />
              </Box>
              <Box marginBottom="32px">
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                  }}
                  placeholder="Enter email address"
                  type="email"
                  id="learner_email"
                  emailAddress="Learner email address"
                  name="email"
                  as={InputCustom}
                  disabled={!!singleLearnerDetail?.id}
                />
                <ErrorMessage name="email" component={ErrorText} />
              </Box>

              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  {inputFields.map((inputField: any, index: any) => {
                    const disableContent =
                      !inputFields[index].program_id || inputField?.student_program_id;

                    const disableBatchContent =
                      !inputFields[index].program_id ||
                        !inputFields[index].mentor_id || inputField?.student_program_id
                        ? true
                        : false;
                    return (
                      <Box
                        display="flex"
                        flexDirection="column"
                        key={inputField}
                      >
                        <Box display="flex" flexDirection="column" gap="50px">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                            gap="15px"
                          >
                            <FormControl
                              sx={{
                                marginBottom: "24px",
                                height: "40px",
                                width: "100%",
                              }}
                            >
                              <Typography sx={labelText}>
                                Program Type
                              </Typography>
                              <Select
                                IconComponent={ExpandMoreIcon}
                                disabled={!!inputField?.student_program_id}
                                sx={selectStyleBox}
                                displayEmpty
                                id="program_id"
                                name="program_id"
                                value={inputField.program_id}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  const filteredPrograms: any =
                                    techStackData?.data?.programs?.filter(
                                      (item: any) => item?.id === selected
                                    )[0];
                                  if (
                                    selected?.length === 0 ||
                                    filteredPrograms === undefined
                                  ) {
                                    return <Box>Select Program</Box>;
                                  }
                                  return filteredPrograms?.name;
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem
                                  sx={{ display: "none" }}
                                  value=""
                                ></MenuItem>
                                {techStackData?.data?.programs?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        onClick={() =>
                                          handleProgramSelected(item.id)
                                        }
                                        key={item.id}
                                        value={item.id}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "baseline",
                                          fontWeight: 500,
                                          height: "56px",
                                          fontSize: "14px",
                                          fontFamily: "var(--fontFamiljen)",
                                          color: "var(--activityText)",
                                        }}
                                      >
                                        <Tooltip
                                          title={
                                            item.name.length > 28
                                              ? item.name
                                              : null
                                          }
                                          defaultValue={item.id}
                                          arrow
                                          placement="top"
                                        >
                                          <Box>
                                            <Box
                                              height="20px"
                                              width="max-content"
                                              marginTop="4px"
                                              padding="4px 10px"
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="center"
                                              textTransform="capitalize"
                                              borderRadius="4px"
                                              fontSize="12px"
                                              fontFamily="var(--fontFamiljen)"
                                              fontStyle="normal"
                                              fontWeight="400"
                                              bgcolor={() => getBackColor(item)}
                                              border="1px solid var(--black)"
                                            >
                                              {item.difficulty}
                                            </Box>

                                            <Typography
                                              fontSize="14px"
                                              fontWeight="400"
                                              fontFamily="var(--fontFamiljen)"
                                              color="var(--activityText)"
                                            >
                                              {item.name.length > 28
                                                ? item.name.substring(0, 28) +
                                                "..."
                                                : item.name}
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              {validateLearner &&
                                inputField?.program_id?.length === 0 && (
                                  <Box
                                    marginTop="3px"
                                    className="errorHandleMessage"
                                  >
                                    Please select a program
                                  </Box>
                                )}
                            </FormControl>
                            <FormControl
                              sx={{
                                marginBottom: "24px",
                                height: "40px",
                                width: "100%",
                              }}
                            >
                              <Typography sx={labelText}>Mentor</Typography>
                              <Select
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                  ...selectStyleBox,
                                  cursor: disableContent
                                    ? "not-allowed"
                                    : "pointer",
                                  backgroundColor:
                                    !mentorData?.data?.mentors?.length &&
                                      !inputField.program_id
                                      ? "#fafafa"
                                      : "#f5f4f4",
                                }}
                                onOpen={() =>
                                  handleMentorClick(inputField.program_id)
                                }
                                displayEmpty
                                disabled={disableContent}
                                name="mentor_id"
                                value={inputField.mentor_id}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  const filteredMentor: any =
                                    allMentorsData?.data?.mentors?.filter(
                                      (item: any) => item?.id === selected
                                    )[0];
                                  if (
                                    selected?.length === 0 ||
                                    filteredMentor === undefined
                                  ) {
                                    return <Box>Select Mentor</Box>;
                                  }
                                  return (
                                    filteredMentor?.first_name +
                                    " " +
                                    filteredMentor?.last_name
                                  );
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem
                                  sx={{ display: "none" }}
                                  value=""
                                ></MenuItem>
                                {mentorIsLoading || mentorIsFetching ? (
                                  <MenuItem disabled={true}>
                                    Loading mentors
                                  </MenuItem>
                                ) : (
                                  mentorData?.data?.mentors?.map(
                                    (item: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={item.id}
                                          value={item.id}
                                          style={getStyles(
                                            item.first_name,
                                            theme
                                          )}
                                        >
                                          {item.first_name +
                                            " " +
                                            item.last_name}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                )}

                                {!mentorData?.data?.mentors?.length &&
                                  !mentorIsLoading && (
                                    <MenuItem disabled={true}>
                                      No mentors to show
                                    </MenuItem>
                                  )}
                              </Select>
                              {validateLearner &&
                                inputField?.mentor_id?.length === 0 && (
                                  <Box
                                    marginTop="3px"
                                    className="errorHandleMessage"
                                  >
                                    Please select a mentor
                                  </Box>
                                )}
                            </FormControl>
                          </Box>
                          <FormControl
                            sx={{
                              height: "40px",
                              width: "100%",
                              marginBottom: "32px",
                            }}
                          >
                            <Typography sx={labelText}>Assign Batch</Typography>
                            <Select
                              IconComponent={ExpandMoreIcon}
                              sx={{
                                ...selectStyleBox,
                                cursor: disableBatchContent
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              displayEmpty
                              id="batch_id"
                              name="batch_id"
                              disabled={disableBatchContent}
                              value={inputField.batch_id}
                              onOpen={() =>
                                handleMentorClick(inputField.program_id)
                              }
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              input={<OutlinedInput />}
                              renderValue={(selected) => {
                                if (selected?.id?.length === 0) {
                                  return <Box>Select Batch</Box>;
                                }
                                return selected?.name;
                              }}
                              MenuProps={MenuProps}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem
                                sx={{ display: "none" }}
                                value=""
                              ></MenuItem>
                              {batchIsLoading || batchIsFetching ? (
                                <MenuItem disabled={true}>
                                  Loading batches
                                </MenuItem>
                              ) : !allBatches?.data?.length ? (
                                <MenuItem disabled={true}>
                                  No Batches to show
                                </MenuItem>
                              ) : (
                                allBatches?.data?.map(
                                  (item: any, index: number) => (
                                    <MenuItem
                                      // onClick={() => handleProgramSelected(item.id)}
                                      key={item?._id}
                                      value={item}
                                      style={getStyles(item.name, theme)}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "baseline",
                                        fontWeight: 500,
                                        height: "56px",
                                        fontSize: "14px",
                                        fontFamily: "var(--fontFamiljen)",
                                        color: "var(--activityText)",
                                      }}
                                    >
                                      <Typography
                                        fontSize="14px"
                                        fontWeight="400"
                                        fontFamily="var(--fontFamiljen)"
                                        color="var(--activityText)"
                                      >
                                        {item?.name}
                                      </Typography>
                                    </MenuItem>
                                  )
                                )
                              )}
                            </Select>
                            {validateLearner &&
                              inputField?.batch_id?.id?.length === 0 && (
                                <Box
                                  marginTop="3px"
                                  className="errorHandleMessage"
                                >
                                  Batch is required
                                </Box>
                              )}
                          </FormControl>
                        </Box>
                        <Box
                          marginTop="25px"
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                          flexDirection="column"
                          alignItems="end"
                          className="form-group col-sm-2"
                        >
                          <Box display="flex" alignItems="center" gap="10px">
                            <Button>
                              <Typography
                                fontSize="12px"
                                fontWeight="400"
                                fontFamily="var(--fontFamiljen)"
                                fontStyle="normal"
                                color="var(--themeOrange)"
                                lineHeight="15px"
                                display={
                                  inputFields.length === 1 || inputField?.student_program_id ? "none" : "flex"
                                }
                                onClick={() => handleRemoveFields(index)}
                              >
                                Delete
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </div>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="5px"
                  margin="10px 0 50px 0"
                >
                  <Box
                    sx={{
                      cursor: disableAddIcon() ? "not-allowed" : "pointer",
                    }}
                  >
                    <Button
                      onClick={() => handleAddFields()}
                      disabled={disableAddIcon()}
                    >
                      <AddIcon
                        sx={{ color: "var(--themeOrange)" }}
                        fontSize="small"
                      />
                      <Typography
                        fontSize="12px"
                        textTransform="capitalize"
                        fontWeight="400"
                        fontFamily="var(--fontFamiljen)"
                        fontStyle="normal"
                        color="var(--themeOrange)"
                        lineHeight="15px"
                      >
                        Add Program
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </form>

              <Box
                component="div"
                gap="16px"
                marginTop="0px"
                display="flex"
                justifyContent="center"
                alignItems="end"
                sx={{
                  marginBottom: "144px",
                  width: "100%",
                }}
              >
                <Buttons
                  type="button"
                  name="Cancel"
                  variant="contained"
                  size="large"
                  onClick={() => onHide()}
                  fullWidth={true}
                  sx={{
                    padding: "0.8rem",
                    height: "56px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    backgroundColor: "var(--borderInput)",
                    boxShadow: "none",
                    color: "var(--legendColor)",
                  }}
                  className="BtnHoverDisable"
                />
                <Buttons
                  type="submit"
                  name={buttonText()}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting ? true : false}
                  onClick={handleSubmitClick}
                  size="large"
                  fullWidth={true}
                  sx={{
                    padding: "0.8rem",
                    height: "56px",
                    fontWeight: "400",
                    fontSize: "16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                  }}
                  className="BtnHover"
                />
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </>
  );
};

export default LearnerDrawer;
