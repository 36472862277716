import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import breadcrumbs_arrow from "../../assets/images/breadcrumbs_arrow.svg";

interface SearchInputType {
  breadCrumbsName: string;
  navigateTo?: string;
  settingProp?: string;
  sx?: SxProps;
}

function BreadCrumbs({
  breadCrumbsName,
  navigateTo,
  sx,
  settingProp,
}: SearchInputType) {
  const params: any = useParams();
  const urlLocation = useLocation();
  const navigate = useNavigate();

  const isAllProgramms = urlLocation.pathname === "/admin/lms/myprograms";
  const isAllSuggestedPrograms =
    urlLocation.pathname === "/admin/lms/suggestedprograms";

  const textStyle = {
    fontSize: settingProp ? "16px" : "13px",
    fontFamily: "var(--fontFamiljen)",
    fontWeight: "var(--font500)",
    letterSpacing: "1px",
    display: "flex",
    padding: "1rem 0",
  };

  const textStyleTooltip = {
    fontSize: settingProp ? "16px" : "13px",
    fontFamily: "var(--fontFamiljen)",
    fontWeight: "var(--font500)",
    letterSpacing: "1px",
    display: "flex",
    marginBottom: "1rem 0",
  };

  const handlePrograms = (): string => {
    let textToShow;
    if (isAllProgramms) {
      textToShow = "My Programms";
    } else if (params.stack?.length > 25) {
      textToShow = params.stack.slice(0, 25) + "...";
    } else if (isAllSuggestedPrograms) {
      textToShow = "Suggested Programs";
    } else {
      textToShow = params.stack;
    }
    return textToShow;
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography
        style={
          params.stack || isAllProgramms || isAllSuggestedPrograms
            ? {
                color: params.stack
                  ? `var(--InputMessage)`
                  : `var(--InputMessage)`,
                cursor: "pointer",
              }
            : { cursor: "default" }
        }
        sx={textStyle}
        onClick={() => navigate(`/admin/${navigateTo}`)}
      >
        {breadCrumbsName}
      </Typography>

      {params.stack || isAllProgramms || isAllSuggestedPrograms ? (
        <img
          style={{ margin: "0 4px", width: "16px" }}
          src={breadcrumbs_arrow}
        />
      ) : null}

      {params.stack?.length > 25 ? (
        <Tooltip
          title={params.stack}
          defaultValue={params.stack}
          arrow
          placement="bottom"
        >
          <Typography
            onClick={() => (params.task ? navigate(-1) : null)}
            style={
              params.task
                ? {
                    color: params.task
                      ? "var(--InputMessage)"
                      : "var(--breadCrumbBlack)",
                    cursor: "pointer",
                  }
                : { cursor: "default" }
            }
            sx={textStyleTooltip}
          >
            {isAllProgramms ? "My Programms" : null}
            {isAllSuggestedPrograms ? isAllSuggestedPrograms : null}
            {params.stack?.length > 25
              ? params.stack.slice(0, 25) + "..."
              : params.stack}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          onClick={() => (params.task ? navigate(-1) : null)}
          style={
            params.task
              ? {
                  color: params.task
                    ? "var(--InputMessage)"
                    : "var(--breadCrumbBlack)",
                  cursor: "pointer",
                }
              : { cursor: "default" }
          }
          sx={textStyle}
        >
          {isAllProgramms ? "My Programms" : null}
          {isAllSuggestedPrograms ? isAllSuggestedPrograms : null}
          {params.stack?.length > 25
            ? params.stack.slice(0, 25) + "..."
            : params.stack}
        </Typography>
      )}
      {params.task ? (
        <img
          style={{ margin: "0 10px", width: "20px" }}
          src={breadcrumbs_arrow}
        />
      ) : null}
      <p style={{ cursor: "default" }}>{params.task}</p>
    </Box>
  );
}

export default BreadCrumbs;
