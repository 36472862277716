import { useGetUpcomingClassesQuery } from "../../redux/api/webLMS.api";
import LearnerScheduleCard from "./LearnerScheduleCard";
import { format, parseISO } from "date-fns";
import styles from "./learnerSchedule.module.css";
import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import NoModulesFound from "../LMS/modules/NoModulesFound";
import Hinstory from "../../assets/icons/history.svg"
import { limit, meetingType } from "../../components/utils/constants";
import PaginationTable from "../../components/reusables/Pagination";
import { extractTime } from "../../utils/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTopFunction } from "../../redux/slices/MainSidebarSlice";

const SchedulesHistory = () => {
  // const { showButton } = props;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const reqClassesList = {
    url: `get-scheduled-call?filter=history`,
    limit: limit,
    page: currentPage
    // body: {
    //   limit: 10,
    //   page: 1,
    // },
  };

  const { data, isLoading, isFetching } = useGetUpcomingClassesQuery(reqClassesList);
  const historyData = data?.data?.docs;

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const totalMeetings = data?.data?.docs?.reduce((acc: any, doc: any) => acc + (doc?.meetings?.length || 0), 0);

  useEffect(() => {
    dispatch(scrollToTopFunction(!triggerScroll));
  }, [currentPage])
  return (
    <>
      {isLoading || isFetching
        ? Array.from({ length: 6 }).map((_, index) => {
          const uniqueKey = `skeleton_${index}`;
          return (
            <Fragment key={uniqueKey}>
              <SkeletonTable
                sx={{
                  width: "100%",
                  height: "7rem",
                  margin: "0.5rem 0rem",
                }}
              />
            </Fragment>
          );
        })
        : historyData?.map((items: any) => {
          const dateString = items?.date;
          const dateObject = parseISO(dateString);
          const formattedDate = format(dateObject, "dd MMM yyyy");
          return (
            <>
              <Typography className={styles.dateSlots}>
                {formattedDate}
              </Typography>
              {items?.meetings?.map((item: any) => {
                return (
                  <>
                    <LearnerScheduleCard
                      key={item?._id}
                      agenda={item?.title}
                      time={`${extractTime(item?.start_date)} - ${extractTime(item?.end_date)}`}
                      mentor="Mentor"
                      meetingType={item?.meeting_type === meetingType ? "Live" : "Doubt"}
                      name={`${item?.mentor?.first_name} ${item?.mentor?.last_name}`}
                      showButton={item?.meeting_type === meetingType ? false : true}
                      slotData={item}
                      showJoinButton={false}
                    />
                  </>
                );
              })}
            </>
          );
        })}

      {data?.data?.docs.length < 1 ? (
        <NoModulesFound
          heading="No data available at the moment"
          hideBtn="hideBtn"
          imgSrc={Hinstory}
        />
      ) : null}

      {totalMeetings < 10 && currentPage === 1 ? null :
        <PaginationTable
          currentPage={currentPage}
          disableNextBtn={totalMeetings < 10}
          handleNextBtn={() => setCurrentPage(prev => prev + 1)}
          handlePreviousBtn={() => setCurrentPage(prev => prev - 1)}
        />
      }
    </>
  );
};

export default SchedulesHistory;
