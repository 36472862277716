export default function debounce(func: any, timeout: number = 300) {
  let timer: any

  return (...args: any[]) => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      func(...args)
    }, timeout)
  }
}
