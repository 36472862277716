import styled from "styled-components";

const LevelTag = styled.div<any>`
  padding: 2px 14px;
  border: .5px solid #100f0f;
  border-radius: 4px;
  font-size: 12px;
  font-family: var(--fontFamiljen);
  font-weight: var(--font400);
  text-transform: capitalize;
  width: auto;
  /* margin-bottom: 0.5rem; */
  background-color: ${({ variant }) => {
    switch (variant) {
      case "beginner":
        return "#06D6A0";
      case "intermediate":
        return "#FAAD15";
      case "pro":
        return "#EC5F2A";
      default:
        break;
    }
  }};
`;

const TagLevel = (props: any) => {
  const { variant } = props;
  return (
    <>
      <LevelTag variant={variant}>{variant}</LevelTag>
    </>
  );
};

export default TagLevel;
