import Pusher from 'pusher-js'

const appKey: string = process.env.REACT_APP_PUSHER_APP_KEY as string
const appCluster: string = process.env.REACT_APP_PUSHER_APP_CLUSTER as string

const pusher = new Pusher(appKey, {
  cluster: appCluster,
})

export default pusher
