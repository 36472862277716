import { Fragment, useEffect, useRef, useState } from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import ScheduleCard from "./ScheduleCard";
import ScheduleCancelAlert from "./ScheduleCancelAlert";
import {
  useCancelScheduleMutation,
  useGetUpcomingClassesQuery,
} from "../../redux/api/webLMS.api";
import { format, parseISO } from "date-fns";
import { openAlert } from "../../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import styles from "./schedule.module.css";
import NoModulesFound from "../LMS/modules/NoModulesFound";
import Classess from "../../assets/icons/classess.svg";
import Delete from "../../assets/icons/delete-program.svg";
import { extractTime } from "../../utils/formatDate";
import PaginationTable from "../../components/reusables/Pagination";
import { limit, meetingType } from "../../components/utils/constants";
import { Orange } from "../../Style/colors";
import { scrollToTopFunction } from "../../redux/slices/MainSidebarSlice";

const Upcoming = ({ showButton }: { showButton: boolean }) => {
  const upcomingClassRef = useRef();
  const [confirmBox, setConfirmBox] = useState(false);
  const [meetingId, setMeetingId] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [submiting, setSubmiting] = useState(false);
  const [scheduleCancel] = useCancelScheduleMutation();
  const dispatch = useDispatch();
  const reqClassesList = {
    url: `get-scheduled-call?filter=upcoming`,
    limit: limit,
    page: currentPage
    // body: {
    //   limit: 10,
    //   page: 1,
    //   filter: "upcoming"
    // },
  };

  const { data, isLoading, isFetching } = useGetUpcomingClassesQuery(reqClassesList);

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const handleJoinClass = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const handleCancelClass = (items: any) => {
    setConfirmBox(true);
    setMeetingId(items?._id);
  };
  const handleCancelMeeting = async () => {
    setSubmiting(true);
    const reqObj = {
      body: {
        meeting_id: meetingId,
      },
    };
    try {
      const resp: any = await scheduleCancel(reqObj).unwrap();
      if (resp.code === 4064) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setConfirmBox(false);
        setSubmiting(false);
      } else if (resp.code === 4061 || resp.code === 4072) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "warning",
          })
        );
        setConfirmBox(false);
        setSubmiting(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalMeetings = data?.data?.docs?.reduce((acc: any, doc: any) => acc + (doc?.meetings?.length || 0), 0);

  useEffect(() => {
    dispatch(scrollToTopFunction(!triggerScroll));
  }, [currentPage])

  return (
    <>
      <Box ref={upcomingClassRef} className="thisUpcoming">
        {isLoading || isFetching
          ? Array.from({ length: 6 }).map((_, index) => {
            const uniqueKey = `skeleton_${index}`;
            return (
              <Fragment key={uniqueKey}>
                <SkeletonTable
                  sx={{
                    width: "100%",
                    height: "7rem",
                    margin: "0.5rem 0rem",
                  }}
                />
              </Fragment>
            );
          })
          : data?.data?.docs?.map((items: any) => {
            const dateString = items.date;
            const dateObject = parseISO(dateString);
            const formattedDate = format(dateObject, "dd MMM yyyy");
            return (
              <>
                <Typography className={styles.mentorDate}>
                  {formattedDate}
                </Typography>
                {items?.meetings?.map((item: any) => {
                  return (
                    <>
                      <ScheduleCard
                        key={item._id}
                        secheduleHeading={
                          item?.title?.length >= 100
                            ? item?.title?.slice(0, 100) + "..."
                            : item?.title
                        }
                        scheduleTime={`${extractTime(item?.start_date)} - ${extractTime(item?.end_date)}`}
                        scheduleStudent="Student"
                        meetingType={item?.meeting_type === meetingType ? "Live" : "Doubt"}
                        scheduleStudentName={`${item?.student?.first_name} ${item?.student?.last_name}`}
                        onJoinClass={() => handleJoinClass(item.meeting_link)}
                        onCancelClass={() => handleCancelClass(item)}
                        showButton={showButton}
                        slotData={item}
                      />
                    </>
                  );
                })}
              </>
            );
          })}

        {!data?.data?.docs.length ? (
          <NoModulesFound
            hideBtn="hideBtn"
            msg={true}
            description="It appears that you haven't scheduled any classes yet."
            imgSrc={Classess}
          />
        ) : null}

        {!data?.data?.docs.length && currentPage === 1 ? null :
          <PaginationTable
            currentPage={currentPage}
            disableNextBtn={totalMeetings < 10}
            handleNextBtn={() => setCurrentPage(prev => prev + 1)}
            handlePreviousBtn={() => setCurrentPage(prev => prev - 1)}
          />
        }


      </Box>
      <ScheduleCancelAlert
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        nameBtn1="No"
        nameBtn2="Yes"
        title="Cancel scheduled class?"
        message="Are you sure you want to Cancel this class?"
        onClick={handleCancelMeeting}
        submiting={submiting}
        imgSrc={Delete}
      />
    </>
  );
};
export default Upcoming;
