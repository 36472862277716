import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { TASK_STATUS, USER_TYPE } from "../constants";
import useUser from "../hooks/useUser";
import debounce from "../utils/debounce";
import { useFetch } from "./NetworkAdapter";
import useQuery from "../hooks/useQuery";

const LMSContext = createContext<any>(null);

export default function LMSProvider(props: any): JSX.Element {
  let { pathname, state } = useLocation();
  const user = useUser();
  const fetch = useFetch();
  const navigate = useNavigate();
  const query = useQuery();

  if (query.state) {
    state = {
      ...state,
      ...query.state,
    };
  }

  const [programs, setPrograms] = useState<any>([]);
  const [learnerList, setLearnerList] = useState<any>([]);
  const [miniPrograms, setMiniPrograms] = useState<any>([]);
  const [currentProgram, setCurrentProgram] = useState<any>(null);
  const [progress, setProgress] = useState<any>(null);
  const [currentModule, setCurrentModule] = useState<any>(null);
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [taskDetailsInList, setTaskDetailsInList] = useState<any>({});
  const [editor, setEditor] = useState<any>({
    code: null,
    output: null,
  });
  const [snackbar, toggleSnackbar] = useState<any>({
    open: false,
    type: null,
  });

  const [programLoader, toggleProgramLoader] = useState<boolean>(false);
  const [moduleLoader, toggleModuleLoader] = useState<boolean>(false);
  const [taskLoader, toggleTaskLoader] = useState<boolean>(false);

  const isMentor = user?.user_type === USER_TYPE.MENTOR;

  const getAllCode = () => JSON.parse(localStorage.getItem("edoc") ?? "{}");
  const getCodePayload = (id: string) => window.atob(getAllCode()[id] || "");

  function onLocalSave(id: string, val: string) {
    localStorage.setItem(
      "edoc",
      JSON.stringify({ ...getAllCode(), [id]: window.btoa(val) })
    );
  }

  const saveDraft = debounce(onLocalSave, 2000);

  function onCodeChange(id: string, val: string) {
    setEditor({ ...editor, code: val });
    saveDraft(id, val);
  }

  const onChangeEditorCode = useCallback(
    (id: string, value: any) => {
      onCodeChange(id, value);
    },
    [editor?.code]
  );

  function getCode() {
    if (editor?.code) {
      return editor?.code;
    }

    if (currentTask?.last_code_snip) {
      return window.atob(currentTask?.last_code_snip);
    }

    return getCodePayload(currentTask?.id) || "";
  }

  function getCurrentTaskIndex() {
    const index = currentModule?.task.findIndex(
      (item: any) => item?.id === currentTask?.id
    );
    const _currentTask = currentModule?.task.find(
      (item: any) => item?.id === currentTask?.id
    );
    return { index, task: _currentTask || {} };
  }

  function updateLearnerList(list: any[]) {
    setLearnerList(list);
  }

  async function fetchMiniPrograms(stale: boolean = false) {
    let pgms = miniPrograms ?? [];

    if (stale || pgms?.length === 0) {
      toggleProgramLoader(() => true);

      const res = await fetch("get-programs-list", { method: "GET" });

      if (res?.status) {
        pgms = res?.data;
        setMiniPrograms(pgms);
        toggleProgramLoader(() => false);
      } else {
        toggleProgramLoader(() => false);
      }
    }

    return pgms;
  }

  async function fetchProgramProgress(
    programId: string,
    stale: boolean = false
  ) {
    let pgs = progress ?? null;

    if ((stale || !pgs) && programId) {
      toggleProgramLoader(() => true);
      const learnerId = state?.student?._id;
      const res = await fetch(
        `get-program-progress/${programId}${learnerId ? "?learner_id=" + learnerId : ""
        }`,
        {
          method: "GET",
        }
      );

      if (res?.status) {
        pgs = res?.data;
        setProgress(res?.data);
        toggleProgramLoader(() => false);
      } else {
        toggleProgramLoader(() => false);
      }
    }

    return pgs;
  }

  async function fetchModuleDetails(
    moduleId: string,
    learnerId: string | undefined = undefined,
    stale: boolean = false
  ) {
    let _module = currentModule ?? null;

    if (
      // state?.student?._id ||
      (stale || !_module || moduleId !== _module?.id) &&
      moduleId &&
      moduleId !== state?.student?._id
    ) {
      toggleModuleLoader(() => true);

      learnerId = isMentor && (learnerId || state?.student?._id || undefined);
      const res = await fetch(
        `get-module-details/${moduleId}${learnerId ? "?learner_id=" + learnerId : ""
        }`
      );
      if (res?.status) {
        _module = res?.data;
        // When fetching completed module, then show the last task of that module.
        if (!_module?.task?.find((task: any) => task?.current_task)) {
          _module = {
            ..._module,
            task: _module?.task?.map((task: any, idx: number) =>
              idx === _module?.task?.length - 1
                ? { ...task, current_task: true }
                : task
            ),
          };
        }

        setCurrentModule(res?.data);
        toggleModuleLoader(() => false);
      } else {
        toggleModuleLoader(() => false);
      }
    } else {
      toggleModuleLoader(() => false);
    }

    return _module;
  }

  async function fetchTaskDetails(
    taskId: string,
    learnerId: string | undefined = undefined,
    stale: boolean = false
  ) {
    let _task = taskDetailsInList[taskId] || currentTask || null;
    setEditor({ code: null, output: null });
    if (
      state?.module_id ||
      (state?.student?._id && _task?.type === "Assessment") ||
      ((stale || !_task || taskId !== _task?.id) && taskId)
    ) {
      toggleTaskLoader(() => true);
      learnerId = isMentor && (learnerId || state?.student?._id || undefined);
      const res = await fetch(
        `get-task-details/${taskId}${learnerId ? "?learner_id=" + learnerId : ""
        }`
      );
      if (res?.status) {
        _task = res?.data;
        setCurrentTask(_task);
        setTaskDetailsInList({
          ...taskDetailsInList,
          [taskId]: _task,
        });
        toggleTaskLoader(() => false);
      } else {
        toggleTaskLoader(() => false);
      }
    } else {
      setCurrentTask(_task);
      toggleTaskLoader(() => false);
    }

    (_task?.last_code_snip || _task?.last_code_output) &&
      setEditor({
        code: window.atob(_task?.last_code_snip ?? "") ?? "",
        output: window.atob(_task?.last_code_output ?? "") ?? "",
      });

    return _task;
  }

  async function onTaskUpdate(payload: object) {
    const response = await fetch(`task-daily-update`, {
      method: "POST",
      body: payload,
    });
    return response;
  }

  function updateTask(details: any) {
    setCurrentTask((prev: any) => ({ ...prev, ...details }));
    let program = currentProgram;

    setTaskDetailsInList((prev: any) => {
      prev[details?.id] = details;
      return prev;
    });

    let task = (currentModule?.task || [])?.map((t: any) =>
      t?.id === details?.id ? { ...t, status: details?.status } : t
    );

    if (details?.status === TASK_STATUS.COMPLETED) {
      const currentTaskIndex = getCurrentTaskIndex()?.index;

      if (
        currentTaskIndex < currentModule?.task.length - 1 &&
        currentModule?.task[currentTaskIndex + 1]?.id
      ) {
        const nextTaskId = currentModule?.task[currentTaskIndex + 1]?.id;
        program = { ...currentProgram, current_task: nextTaskId };
        setCurrentProgram(program);

        task = (task ?? [])?.map((item: any) =>
          item?.id === nextTaskId
            ? { ...item, current_task: true }
            : { ...item, current_task: false }
        );
      } else if (currentTaskIndex === currentModule?.task.length - 1) {
        // Fetch next module, and set the task
        // Step 1. Get the module list or call the program list to get the updated current_module and current_task
        // Step 2. Fetch the module details and task
        // OR
        // In most cases the last task would be an Assessment type task, which will be in_review status when the
        // student submit the task. In this case student cannot access the next module and task.
      }
    }

    setCurrentModule({
      ...currentModule,
      task,
    });

    if (
      pathname?.endsWith("/code") &&
      details?.status === TASK_STATUS.COMPLETED
    ) {
      if (editor?.code || editor?.output) {
        setEditor({ code: null, output: null });
      }
      navigate(pathname.replace("/code", ""), {
        state: { ...state, program },
        replace: true,
      });
    }
  }

  async function onProgramSelect(pgm: any, fetchModule: boolean = false) {
    if (JSON.stringify(currentProgram) !== JSON.stringify(pgm)) {
      setCurrentProgram(pgm);
    }
    if (state?.module_id) {
      const module = await fetchModuleDetails(state?.module_id);
      const tk =
        module?.task?.find((t: any) => t?.current_task === true) ||
        module?.task[module?.task?.length - 1];
      setCurrentProgram({ ...state.program });
      await fetchTaskDetails(state?.program?.current_task ?? tk?.id);
    } else if (fetchModule && pgm?.current_module) {
      const module = await fetchModuleDetails(pgm?.current_module);
      const tk =
        module?.task?.find((t: any) => t?.current_task === true) ||
        module?.task[module?.task?.length - 1];
      setCurrentProgram({ ...pgm, current_task: state?.taskId ?? tk?.id });
      await fetchTaskDetails(state?.taskId ?? tk?.id);
    }
  }

  async function fetchProgramList(stale: boolean = false) {
    let pgms = programs || [];

    if (stale || pgms?.length === 0) {
      toggleProgramLoader(true);

      const res = await fetch("get-programs");

      if (res?.status) {
        pgms = res?.data;
        setPrograms(res?.data);
        toggleProgramLoader(false);
      } else {
        toggleProgramLoader(false);
      }
    }

    return pgms;
  }

  useEffect(() => {
    (async () => {
      if (pathname === "/learner") {
        setProgress(null);
        setPrograms([]);
        setCurrentProgram(null);
        setCurrentModule(null);
        setCurrentTask(null);
        setEditor({ code: null, output: null });
      } else if (pathname === `/learner/${state?.student?._id}`) {
        const program = currentProgram || {
          ...state?.program?.[0],
          id: state?.program?.[0]?._id,
        };
        if (!currentProgram?.id) {
          onProgramSelect(program);
        }

        await fetchProgramProgress(program?.id);
      } else if (
        pathname ===
        `/learner/${state?.student?._id}/${state?.program?.current_module}` ||
        pathname ===
        `/learner/${state?.student?._id}/${state?.program?.current_module}/code` ||
        pathname === `/programs/${state?.program?.current_module}/code`
      ) {
        const program = { ...state?.program, id: state?.program?.id };
        onProgramSelect(program, true);
      } else if (pathname === `/programs/${state?.program?.current_module}`) {
        onProgramSelect(state?.program, true);
      } else if (pathname === `/programs/${state?.module_id}`) {
        onProgramSelect(state?.module_id, true);
      } else if (pathname === `/programs/${state?.module_id}/code`) {
        onProgramSelect(state?.module_id, true);
      } else if (pathname === "/progress") {
        setPrograms([]);
        setCurrentProgram(null);
        setCurrentModule(null);
        setCurrentTask(null);
        setEditor({ code: null, output: null });
      }
    })();
  }, [pathname]);

  useEffect(() => {
    if (pathname === `/learner/${state?.student?._id}`) {
      fetchProgramProgress(currentProgram?.id, true);
    }
  }, [currentProgram]);

  const context = useMemo(
    () => ({
      programs: {
        getProgram: fetchProgramList,
        fetchList: fetchMiniPrograms,
        fetch: fetchProgramList,
        loader: programLoader,
        selectProgram: onProgramSelect,
        currentProgram,
        progress,
      },
      module: {
        fetchDetails: fetchModuleDetails,
        loader: moduleLoader,
        get: currentModule,
      },
      task: {
        getCurrentTask: () =>
          fetchTaskDetails(currentProgram?.current_task, undefined, false),
        loader: taskLoader,
        get: currentTask,
        getTask: (id: string, learnerId: string | undefined = undefined) =>
          fetchTaskDetails(id, learnerId, false),
        isCurrentTask: (taskId: string) =>
          currentModule?.task.find((item: any) => item?.id === taskId)
            ?.current_task,
        getTaskIndex: getCurrentTaskIndex,
        updateTaskService: onTaskUpdate,
        updateTask,
      },
      editor: {
        code: getCode(),
        setCode: onChangeEditorCode,
        output: editor?.output,
        setOutput: (val: any) => setEditor({ ...editor, output: val }),
      },
      snackbar: { ...snackbar, toggle: toggleSnackbar },
      isMentor,
      learners: {
        updateList: updateLearnerList,
        list: learnerList,
        get: state?.student,
      },
    }),
    [
      currentModule,
      currentProgram,
      currentTask,
      editor.code,
      editor.output,
      isMentor,
      learnerList,
      moduleLoader,
      programs,
      programLoader,
      progress,
      snackbar,
      taskLoader,
    ]
  );

  return <LMSContext.Provider {...props} value={context} />;
}

export function useLMS() {
  return useContext(LMSContext);
}
