import { Suspense, useEffect } from 'react'
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'

import AlertMessage from './components/reusables/AlertMessage'
import ChatProvider from './context/ChatContext'
import MainSidebarHeader from './components/MainSidebarHeader'
import Layout from './components/Layout'
import Loader from './components/reusables/Loader'
import LMSProvider from './context/LMSContext'
import NetworkProvider from './context/NetworkAdapter'
import PublicRoutes from './routes/PublicRoutes'
import RoutesComponent from './routes/RoutesComponent'
import { onMessageListener, requestNotification } from './config/firebase'
import { theme } from './components/reusables/themes/themes'
import { USER_TYPE } from './constants'

import './App.css'

function RenderWebApp({ authToken, user }: any) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    let unsubscribe: any
    if ((user?.user_type === USER_TYPE.LEARNER || user?.user_type === USER_TYPE.MENTOR) && user?.onboarding) {
      requestNotification(authToken)

      unsubscribe = onMessageListener((payload: any) => {
        // @ts-ignore
        window.updateNotification()((prev: any) => ({
          notifications: [...prev.notifications, payload],
          total: prev.total + 1
        }))
      })
    }

    return unsubscribe
  }, [user?.user_type])

  // if the already onboarded user navigate from magic link.
  if (pathname === '/onboard' && user?.onboarding) {
    const url = user?.user_type === USER_TYPE.LEARNER ? '/my_dashboard' : '/dashboard'
    setTimeout(() => navigate(url), 50)
  }

  if ((user?.user_type === USER_TYPE.LEARNER || user?.user_type === USER_TYPE.MENTOR) && user?.onboarding) {
    return (
      <NetworkProvider>
        <ChatProvider>
          <LMSProvider>
            <Layout>
              <Suspense fallback={<Loader height='65px' />}>
                <RoutesComponent />
              </Suspense>
            </Layout>
          </LMSProvider>
        </ChatProvider>
      </NetworkProvider>
    )
  }

  return null
}

function RenderAdminPanel({ token, user }: any) {
  if (user?.user_type !== USER_TYPE.LEARNER && user?.user_type !== USER_TYPE.MENTOR && token) {
    return (
      <MainSidebarHeader>
        <Suspense fallback={<Loader />}>
          <RoutesComponent />
        </Suspense>
      </MainSidebarHeader>
    )
  } else if (user?.user_type !== USER_TYPE.LEARNER && user?.user_type !== USER_TYPE.MENTOR) {
    return (
      <Suspense fallback={<Loader />}>
        <PublicRoutes />
      </Suspense>
    )
  }

  return null
}

export default function App(): JSX.Element {
  const authToken: any = useSelector((state: any) => state?.authSlice?.token)
  const snackbarState: any = useSelector((state: any) => state?.snackbarSlice)
  const storedToken: any = useSelector((state: any) => state?.authSlice?.userOtpToken)
  const user: any = useSelector((state: any) => state?.authSlice?.user)

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <RenderWebApp authToken={authToken} user={user} />
        <RenderAdminPanel token={storedToken} user={user} />
      </Router>

      {snackbarState.showAlert && (
        <AlertMessage
          open={snackbarState.showAlert}
          autoHideDuration={3000}
          severity={snackbarState.severity}
          message={snackbarState.message}
        />
      )}
    </ThemeProvider>
  )
}
